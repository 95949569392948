/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): popover.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

@each $color, $value in $theme-colors {
	.popover.#{$color} {
		@include popover-variant($value, theme-color-level($color, $alert-border-level), $value);
	 }
}
.popover {
	.popover-body{
		margin-left: -0.5rem;
	}
   .popover-arrow{
	   height: 1rem;
   }
   &.bs-popover-start{
		margin-right: 8px;
   }
}