// Propeller Loader
.pmd-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5%;
    display: flex;
    background-color: pmd-theme-color(secondary, light);
    z-index: 1060;
    img {
        height: 40px;
        border-radius: 50px;
        background-color: pmd-theme-color(primary, light);
        padding: $space4;
        width: 40px;
        border: solid $border-width $border-color;
    }
}
// Loader
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    width: 80px;
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
}
// Container Loader
.container-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5%;
    min-height: 60vh;
    opacity: 0.94;
    z-index: 10;
    overflow: hidden;
    align-items: flex-start;
    .loader {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin-top: 60px;
    }
}
// Card loader
.pmd-loader-card {
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5%;
    min-height: calc(60vh - 30px);
    opacity: 0.94;
    z-index: 10;
    align-items: flex-start;
    border-radius: $border-radius;
    background-color: pmd-theme-color(secondary, light);
    img {
        height: 40px;
        border-radius: 50px;
        background-color: pmd-theme-color(primary, light);
        padding: $space4;
        width: 40px;
        border: solid $border-width $border-color;
    }
    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0px auto;
        width: 80px;
        overflow: hidden;
        min-height: calc(60vh - 30px);
        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }
        .circular {
            -webkit-animation: rotate 2s linear infinite;
            animation: rotate 2s linear infinite;
            height: 100%;
            transform-origin: center center;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            .path {
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
                -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
                animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
                stroke-linecap: round;
            }
        }
    }
}
// Circular Loader for Table
.pmd-circle-loader {
    margin: 0 auto 2rem;
    width: 40px;
    height: 40px;
    position: relative;
    z-index: -1;
}
// SVG Circular
.circular {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        stroke-linecap: round;
    }
}
@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}
@-webkit-keyframes color {
    100%,
    0% {
        stroke: theme-color("primary");
    }
    40% {
        stroke: theme-color("secondary");
    }
    66% {
        stroke: theme-color("primary");
    }
    80%,
    90% {
        stroke: theme-color("secondary");
    }
}
@keyframes color {
    100%,
    0% {
        stroke: theme-color("primary");
    }
    40% {
        stroke: theme-color("secondary");
    }
    66% {
        stroke: theme-color("primary");
    }
    80%,
    90% {
        stroke: theme-color("secondary");
    }
}