.khr-upload-table-block {
  border-top: 1px solid #d2d6db;
  padding: 20px 0;

  @media (min-width: 768px) {
    border-left: 1px solid #d2d6db;
    border-top: 0;

    // padding-right: 0;
  }

  @media (min-width: 992px) {
    padding: 48px;
  }

  @media (max-width: 992px) {
    padding-left: 20px;
  }

  @media (max-width: 767px) {
    padding-left: 0;
  }


  .col-form-label {
    font-weight: 600;
  }

  @media (min-width: 1200px) {
    height: 680px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
  }

  .img-uploaded-block {
    margin-top: 1rem;
    border: 1px solid #d2d6db;
    border-radius: 8px;

    @media (max-width: 1200px) {
      min-height: 400px;
      margin-top: -20px;
    }

    @media (max-width: 767px) {
      margin-top: 0px;
    }

    img {
      height: 300px;
      width: 100%;
      object-fit: contain;
    }
  }
}

.khr-upload-no-data {
  @media (max-width: 768px) {

    .text-desc,
    .text-danger {
      font-size: 12px !important;
    }

  }
}

.upload-block {
  @media (max-width: 768px) {

    .text-desc,
    .text-danger {
      font-size: 12px !important;
    }

  }
}