/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): table.css
 * Copyright 2016-2022 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */
// Table Captions
.pmd-card {
	.pmd-table {
		caption {
			padding: $table-cell-padding;
		}
	}

	.table {
		margin-bottom: 0;

		th,
		td {
			padding: $table-cell-padding;
		}
	}
}

.pmd-table {
	&.table {
		margin-bottom: 0;
		background-color: transparent; // Reset for nesting within parents with `background-color`.

		th,
		td {
			padding: $table-cell-padding;
			line-height: $table-cell-line-height;
			vertical-align: $table-cell-vertical-align;
			border-top: $table-border-width solid $table-border-color;
			border-bottom: none;
			text-align: left;
			@include transition($transition-base);
		}

		thead th {
			font-weight: $table-head-font-weight;
			color: $table-head-color;
			border-top: none;
			font-size: $table-head-font-size;
			border-bottom: $table-border-width solid $table-border-color;
		}

		tbody+tbody {
			border-top: (2 * $table-border-width) solid $table-border-color;
		}

		.table {
			margin-bottom: 0;
		}

		// Table Reflow
		&.table-reflow {
			display: block;
			overflow-x: scroll;

			thead {
				position: absolute;
			}

			tbody {
				margin-left: 150px;
			}

			tr {
				display: table-cell;
				vertical-align: top;
				float: none;
				width: 150px;
				background-color: $table-bg;
			}

			th {
				font-size: $font-size-base;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				width: 151px;
			}

			tbody {
				display: block;
				white-space: nowrap;
			}

			th,
			td {
				border-right: $table-border-width solid $table-border-color;
				display: block !important;

				&:last-child {
					border-right: $table-border-width solid $table-border-color;
				}
			}

			td {
				border: none;
				border-left: $table-border-width solid $table-border-color;
				border-bottom: $table-border-width solid $table-border-color;
			}
		}

		.thead-dark {
			th {
				color: $table-dark-head-color;
				background-color: $table-dark-head-bg;
			}
		}
	}

	// Table Striped
	&.table-striped {
		tbody tr:nth-of-type(odd) {
			background-color: $table-accent-bg;
		}
	}

	// Table Small
	&.table-sm {

		th,
		td {
			padding: $table-cell-padding-sm;
		}
	}

	// Table Bordered
	&.table-bordered {
		border: $table-border-width solid $table-border-color;

		th,
		td {
			border: $table-border-width solid $table-border-color;
		}

		thead {
			border-color: transparent;

			th,
			td {
				border-bottom-width: $table-border-width;
			}
		}
	}

	// Table Hover
	&.table-hover {
		&>tbody>tr {
			background-color: $table-bg;

			&:hover>* {
				background-color: $table-hover-bg;
				--bs-table-accent-bg: inherit;
			}
		}

		tbody tr {
			@include hover {
				color: $table-hover-bg;
				background-color: $table-hover-bg;
			}
		}
	}

	// Border less
	&.table-borderless {

		th,
		td {
			border: 0;
		}

		thead th {
			border: 0;
		}
	}

	// Border Dark
	&.table-dark {
		color: $table-dark-color;
		background-color: $table-dark-bg;

		th,
		td,
		thead th {
			border-color: $table-dark-border-color;
		}

		thead th {
			color: $table-dark-head-color;
		}

		&.table-bordered {
			border: 0;
		}

		&.table-striped {
			tbody tr:nth-of-type(odd) {
				background-color: $table-dark-accent-bg;
			}
		}

		&.table-hover {
			tbody tr {
				@include hover {
					background-color: $table-dark-hover-bg;
				}
			}
		}
	}

	// Child Table
	.child-table {
		background-color: $child-table-bg;

		>td {
			padding: 0 !important;
			border-top: none;

			.table {
				>thead>tr {
					background-color: $table-bg;
				}
			}
		}

		.table-sm {

			th,
			td {
				padding: $table-cell-padding-sm 0.75rem;
			}
		}

		@include media-breakpoint-down(sm) {
			margin-top: -20px;
		}
	}
}

@each $color, $value in $theme-colors {
	@include table-row-variant($color, theme-color-level($color, -10));
}

@include table-row-variant(active, $table-active-bg);


// Table in card 
.table-heading {
	min-height: 64px;
	border-bottom: 1px solid #ddd;
	padding: 4px 24px 4px 24px;
}

.table-footer {
	padding: 8px 24px 8px 24px;
	border-top: 1px solid #ddd;
	display: inline-block;
	width: 100%;
}

.shoarting {
	margin-left: 6px;
}

//  Table Card
.pmd-table {
	&.pmd-table-card {
		@include border-radius($card-border-radius);
		@include pmd-box-shadow($table-card-box-shadow);
		background-color: $table-bg;
	}
}

// @include media-breakpoint-down(sm) {
// 	.pmd-table-card {
// 		&.pmd-table {
// 			background-color: transparent;
// 			box-shadow:none;
// 			.table {
// 				margin-bottom:0;
// 			}
// 		}
// 		&.pmd-card-main {
// 			background-color: transparent;
// 			box-shadow:none;
// 		}
// 		&.table {
// 			&.pmd-table {
// 				thead,
// 				tfoot {
// 					display: none;
// 				}
// 				tbody {
// 					display: block;
// 				}
// 				tr { 
// 					display: block; 
// 					margin-bottom:1.25rem; 
// 					@include border-radius($card-border-radius);
// 					@include pmd-box-shadow($table-card-box-shadow);
// 					background-color: $table-bg;
// 				}	
// 				td { 
// 					display: block; 
// 					text-align: right;
// 				}
// 				td[data-title]:before {
// 					content: attr(data-title); 
// 					float: left; 
// 					font-size: inherit; 
// 					font-weight: $table-head-font-weight; 
// 					color: $table-head-color;
// 				}	
// 			}
// 		}
// 		&.pmd-table {
// 			&.table-striped {
// 				td,
// 				tr:nth-child(odd) {
// 					background-color: $table-bg;
// 				}
// 				td:nth-child(odd) {
// 					background-color: $table-accent-bg; 
// 				}
// 			}
// 			&.table-dark {
// 				background-color:transparent;
// 				tr {
// 					background-color: $table-dark-bg;
// 				}
// 				td[data-title]:before { 
// 					color:$table-dark-head-color;
// 				}
// 			}
// 			&.table-hover {
// 				tr:hover {
// 					background-color: $table-bg;
// 				}
// 				td:hover {
// 					background-color:$table-hover-bg;
// 				}
// 				&.table-dark {
// 					tr:hover {
// 						background-color: $table-dark-bg;
// 					}
// 					td:hover {
// 						background-color: $table-dark-hover-bg;
// 					}
// 				}
// 			}
// 			&.table-striped {
// 				&.table-dark {
// 					td,
// 					tr:nth-child(odd) {
// 						background-color:$table-dark-bg;
// 					}
// 					td:nth-child(odd) {
// 						background-color:$table-dark-accent-bg;
// 					}
// 				}
// 			}
// 		}
// 		&.pmd-z-depth { 
// 			background-color:transparent;
// 			box-shadow:none;
// 		}
// 	}
// }

//@at-root
.table {
	>:not(:first-child) {
		border-top: 0 solid currentColor;
	}

	>:not(caption)>*>* {
		background-color: transparent;
	}
}

.pmd-table-card-md {
	&.pmd-table {
		&.table {
			@include media-breakpoint-down(md) {
				border: none;
				background-color: transparent;
				box-shadow: none;

				thead {
					display: none;
				}

				tbody {
					display: block;
				}

				tr {
					display: flex;
					flex-wrap: wrap;
					margin-bottom: $space8;
					@include border-radius($card-border-radius);
					@include pmd-box-shadow($table-card-box-shadow);
					background-color: $table-bg;
					border: solid $border-width $border-color;
					padding-bottom: 1rem;

					&:last-child {
						margin-bottom: 0;

					}
				}

				td[data-title]:before {
					content: attr(data-title);
					font-size: 13px;
					display: block;
					margin-bottom: $space4;
					font-weight: $table-head-font-weight;
				}

				td {
					border: none;
					padding: $space16;
					padding-bottom: 0;

					@include hover {
						background-color: transparent;
					}

					&.checkbox-column {
						@include media-breakpoint-down(md) {
							position: absolute;
							border: none;
							right: 10px;
						}
						@include media-breakpoint-down(sm) {
							right: 0;
						}
					}
				}
			}

			@include media-breakpoint-up(lg) {
				td {
					width: inherit;
				}
			}

			td {
				@include media-breakpoint-up(lg) {
					width: inherit;
				}

				@include media-breakpoint-only(md) {
					&.pbt-md-p50 {
						padding-left: 70px;
					}
				}

				@include media-breakpoint-only(sm) {
					&.pbt-sm-p50 {
						padding-left: 70px !important;
					}
				}

				@include media-breakpoint-down(xs) {
					&.pbt-xs-p50 {
						padding-left: 70px !important;
					}
				}

				@include media-breakpoint-down(md) {
					&.pbt-p50 {
						padding-left: 70px;
					}
				}
			}
		}
	}

}