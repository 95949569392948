.toast-container {
  pointer-events: auto;
  flex-direction: column;

  .pmd-toastr {
    position: relative;
    overflow: hidden;
    padding: 10px 16px;
    margin-bottom: 1rem;
    max-width: 300px;
    border-radius: $space4;
    font-size: 0.75rem;
    color: $white;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);

    //@include box-shadow($card-hover-shadow);
    @media (min-width:1560px) {
      padding: 16px 20px;
      margin-bottom: 1rem;
      font-size: 0.875rem;
      max-width: 380px;
    }

    &.toast-success {
      background-color: #55b938;

      .toast-message {
        &:before {
          content: "\e86c";
          color: #fff;
          font-family: "Material Icons";
          font-size: 24px;
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
        }

        &.ng-star-inserted {
          padding-left: 20px;
          padding-right: 10px;
        }
      }
    }

    &.toast-warning {
      background-color: theme-color("warning");

      .toast-message {
        &:before {
          content: "\e002";
          color: #fff;
          font-family: "Material Icons";
          font-size: 24px;
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
        }

        &.ng-star-inserted {
          padding-left: 20px;
          padding-right: 10px;
        }
      }
    }

    &.toast-error {
      background-color: #ff5667;

      .toast-message {
        &:before {
          content: "\e5c9";
          color: #fff;
          font-family: "Material Icons";
          font-size: 24px;
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
        }

        &.ng-star-inserted {
          padding-left: 20px;
          padding-right: 10px;
        }
      }
    }

    .toast-close-button {
      background-color: transparent;
      border: medium none;
      position: absolute;
      right: 0;
      top: 2px;
      font-size: inherit;
      display: inline-block;
      opacity: 0.6;

      &::after {
        content: "\e5cd";
        font-size: 20px;
        font-family: "Material Icons";
        color: rgba($white, 0.6);
      }

      @include hover-focus() {
        opacity: 1;
      }

      span {
        display: none;
      }
    }
  }

  &.toast-top-right {
    right: $space30;
    position: fixed;
    z-index: 100000;
    top: 1rem;
  }
}