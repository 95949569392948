/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): checkbox.css
 * Copyright 2016-2019 Digicorp, Inc.
 * Licensed under MIT (https://propeller.in/LICENSE)
 */

.pmd-checkbox-ripple-effect {
	@include transform-translateZ(0px)
}
.checkbox {
	.pmd-checkbox-ripple-effect{
		padding-left:0;
	}
	.pmd-checkbox{ 
		padding-left:0;
	}
}
.pmd-checkbox,
.pmd-radio{
	.form-check-input {
		&:focus{
			~ .form-check-label{
				&::after{
					border-color: #D2D6DB;
					outline: 0;
				}
			}
		}		
	}
}

.pmd-checkbox,
.pmd-radio{
	position: relative;
	margin-bottom:0;
	&.form-check{
		padding-left: 0;
		min-height: unset;
	}
	.form-check-input {
		position: absolute;
		left: 23px;
		z-index: -1;
		width: 1rem;
		height: 1.25rem;
		opacity: 0;
	}
	.form-check-label{
		position: static;
		padding-left: 2rem;
		cursor: pointer;
		margin-bottom: 0;
		&::after{
			width:20px; 
			height:20px;
			border:$custom-control-border-width solid $custom-control-border-color;
			top:0;
			margin: auto;
			left: 0;
		}
		&::after,
		&::before{
			position: absolute;
			content: "";
		}
	}
	.form-check-input:checked~.form-check-label{
		&::after{
			border-color:$custom-control-checked-border-bg;
		}
	}
	.form-check-input:focus~.form-check-label::before{
		box-shadow:none;
	}
	.form-check-input:disabled~.form-check-label {
		color:$custom-control-disabled-color;
		cursor: default;
		&::after{
			border-color:$custom-control-disabled-border-color;
		}
	}
	.ink {
	  background-color: $custom-control-ink-bg;
	}
	.invalid-feedback{
		padding-left: 2rem;
	}
}
.pmd-radio {
	.form-check-label {
		&::after{
			border-radius:18px;
		}
		&::before{
			top:7px; 
			background:$radio-check-color;
			border-radius:4px;
			transition:.2s ease-in-out; 
			height:8px; 
			width:8px; 
			left:5px; 
			@include transform-scale(0);
			margin: auto;
		}
	}
	.form-check-input:checked~.form-check-label::after {
		background-color: transparent;
		background-image: none;
	}
	.form-check-input:checked~.form-check-label::before {
		@include transform-scale(1);
		background-color:$radio-check-color;
		border-color:$radio-check-color;
	}
}

.pmd-checkbox {
	.form-check-label {
		&::after{
			border-radius: $checkbox-border-radius;
		}
		&::before{
			height: 12px; 
			top: 3px;
			margin: auto;
			left: 7px;
			width: 6px;
			transition: all .2s;
			opacity: 0;
			@include transform-rotate(45deg);
			border-radius:0;
			z-index: 1;
			background-color:transparent;
		}
	}
	.form-check-input {
		&:checked~.form-check-label{
			&::after {
				background-color:$checkbox-checked-bg;
				background-image:none;
			}
			&::before {
				color: $checkbox-check-color; 
				background-image:none;
				border-image: none; 
				border-style: none solid solid none; 
				border-width: 0 $checkbox-check-size $checkbox-check-size 0;
				opacity: 1;
				background-color:transparent;
				border-color: $checkbox-check-color;
			}
		}
	}
}

/* hover style just for information */
.pmd-checkbox.pmd-checkbox-ripple-effect{
	position:relative;
}
.pmd-checkbox .pmd-checkboxwrap{ 
	position:absolute; 
	z-index:-1; 
	height:40px; 
	width:40px; 
	border-radius:50%; 
	overflow:hidden; 
	top:-8px; 
	left:-11px;
}
.checkbox-inline.pmd-checkbox{
	padding-left: 0;
}
.pmd-checkbox-ripple-effect .ink{ 
	background-color:rgba(0, 0, 0, 0.2);
}

// card inverse disabled checkbox 
.text-white{
	.pmd-checkbox,
	.pmd-radio{
		.form-check-label {
			&::after{
				width:20px; 
				height:20px;
				border:$custom-control-border-width solid $custom-control-inverse-border-color;
			}
		}
		.form-check-input:checked~.form-check-label::after{
			border-color:$custom-control-inverse-checked-bg;
		}
		.form-check-input:focus~.form-check-label::before{
			box-shadow:none;
		}
		.form-check-input:disabled~.form-check-label {
			color:$custom-control-inverse-disabled-color;
			&::after{
				border-color:$custom-control-inverse-disabled-border-color;
			}
		}
		.ink {
		  background-color: $custom-control-ink-dark-bg;
		}
	}
}
