@media print {
    body {
        -webkit-print-color-adjust: exact;
        margin-top: 6mm;
        margin-left: 4mm;
        margin-bottom: 6mm;
        margin-right: 4mm;
    }

    div,
    thead,
    tr,
    th,
    td {
        color-adjust: exact;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
        border-color: #E7E8EB !important;
        border-width: 0.5px !important;
    }

    th {
        border-color: #D2D7E3 !important;
        border-width: 0.5px !important;
    }
}

.print-page {
    max-width: 850px;
    margin: 0 auto;
    padding: 0 4px;

    .pmd-card {
        box-shadow: none;

        &.preview-invoice-card {
            border-color: #D7E0F1 !important;
            padding: 0 !important;
            max-width: 850px;

            .pmd-table.table-bordered {
                border-left: none;
                border-right: none;

                tr {

                    td:first-child,
                    th:first-child {
                        border-left: none;
                    }

                    td:last-child,
                    th:last-child {
                        border-left: none;
                    }
                }
            }
        }
    }

    .indented {
        margin-left: 2.125rem;
    }

    .print-table {
        .pmd-table {
            box-shadow: none;

            tbody {
                tr:first-child {
                    td {
                        border-top: none;
                    }
                }
            }
        }

        thead {
            th {
                -webkit-print-color-adjust: exact;
                font-size: $font-size-xs;
                font-weight: $font-weight-semibold;
                padding-top: $space12;
                padding-bottom: $space12;
                border-top: none;
                border-bottom: none;
            }
        }
    }

    .hr-print {
        border-width: 8px;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
    }
}