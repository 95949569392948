/*!
 * Propeller Pro v1.5.0 (http://pro.propeller.in/): intro-section.css
 * Copyright 2016-2019 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

// Typography
.pmd-subtitle {
  	font-size: $pmd-intro-subtitle-font-size;
  	line-height: $pmd-intro-subtitle-line-height;
	  margin-top: (-$pmd-intro-subtitle-margin-top);
}

// Intro background images
.pmd-intro-bg-img {
  position: relative;
  width: 100%;
  background-position: center center;
  background-color: $pmd-intro-bg-color;
  background-size: cover;
  video{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
  }
}
.pmd-intro-img-overlay{
  &:before{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    bottom: 0;
    margin: auto;
    position: absolute;
    content: "";
    opacity: $pmd-intro-img-overlay-opacity;
    background: $pmd-intro-img-overlay;
  }
}
.pmd-intro-img-overlay:before {
  background: $pmd-intro-bg-color;
  opacity: $pmd-intro-img-overlay-opacity;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

// Intro Gradient images
.pmd-intro-gradient {
  position: relative;
  &:before{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    bottom: 0;
    margin: auto;
    position: absolute;
    content: "";
    background: $pmd-intro-bg-color;
    background: $pmd-intro-gradient;
    // background: -webkit-gradient(linear, left top, left bottom, from(#e1112e), to(#0971b2));
    // background: linear-gradient(45deg, #e1112e 0%, #0971b2 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1112e', endColorstr='#0971b2', GradientType=0 );
    opacity: $pmd-intro-img-overlay-opacity;
  }
  &:after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $black;
    opacity: $pmd-intro-img-overlay-opacity-light;
    z-index: 10;
  }
}

// Intro container
.pmd-intro-container {
  min-height: 600px;
  padding: $pmd-intro-container-padding-y $pmd-intro-container-padding-x;
  z-index: 89;
  position: relative;
  .pmd-card{
    position: relative;
  }
  hr{
    background-color: $pmd-intro-hr;
		opacity: 1;
  }
  &.text-light{
    hr{
      border-top: $border-width solid $pmd-intro-hr-light;
    }
  }
}

// Intro Border
.pmd-text-before-line{
  position: relative;
  padding-left: $pmd-text-before-line-padding-left;
  &:after{
    content: "";
    position: absolute;
    width: $pmd-text-before-line-width;
    height: $pmd-text-before-line-height;
    background-color: $pmd-text-before-line-color;
    top: 50%;
    left: 0;
    @include transform-translateY(-50%) 
  }
}
.pmd-text-before-line-dark {
  position: relative;
  padding-left: $pmd-text-before-line-padding-left;
  &:after{
    content: "";
    position: absolute;
    width: $pmd-text-before-line-width;
    height: $pmd-text-before-line-height;
    background-color: $pmd-text-before-line-dark;
    top: 50%;
    left: 0;
    @include transform-translateY(-50%) 
  }
}

// Intro image
.pmd-intro-img {
	z-index: 11;
  img{
    max-width: 100%;
  }
}

// Intro image wrapper
.pmd-intro-img-wrapper {
  position: relative;
  .pmd-intro-img{
    position: relative;
    z-index: 1;
  }
  &.order-3{
    @include media-breakpoint-down(sm){
      order: 1;
    }
  }
  // Circle Animation 
  .pmd-animate-circle-wrapper{
    left: 50%;
    top: 50%;
    @include transform-translate(-50% , -50%); 
    position: absolute;
    overflow: hidden;
    z-index: 0;
    width: 600px;
    height: 600px;
    .pmd-animate-circle{
      border: $border-width solid $pmd-animate-circle-color;
      border-radius: 50%;
      position: absolute;
      span{
        width: $pmd-animate-dot-size;
        height: $pmd-animate-dot-size;
        border-radius: $pmd-animate-dot-border-radius;
        background-color: $pmd-animate-dot-color;
        position: absolute;
        left: 50%;
      }
      &.pmd-animate-circle-lg {
        width: 100%;
        height: 100%;
        @include transform-rotate(23deg);
        -webkit-animation: circle-lg 35s linear infinite;
        animation: circle-lg 35s linear infinite;
        opacity: $pmd-animate-circle-lg-opacity;
        span.pmd-animate-circle-dot{
          &:nth-child(1n){
            top: -5px;
            margin-left: -5px;
          }
          &:nth-child(2n){
            margin-left: -224px;
            top: 90px;
          }
          &:nth-child(3n){
            margin-left: -202px;
            top: 520px;
          }
          &:nth-child(4n){
            margin-left: 273px;
            top: 405px;
          }
        }
      }
      &.pmd-animate-circle-md {
        width: 500px;
        top: 50px;
        height: 500px;
        left: 50px;
        @include transform-rotate(-41deg);
        -webkit-animation: circle-md 25s linear infinite;
        animation: circle-md 25s linear infinite;
        opacity: $pmd-animate-circle-md-opacity;
        span.pmd-animate-circle-dot{
          &:nth-child(1n){
            top: -5px;
            margin-left: -5px;
          }
          &:nth-child(2n){
            margin-left: -201px;
            top: 90px;
          }
          &:nth-child(3n){
            margin-left: -202px;
            top: 398px;
          }
          &:nth-child(4n){
            margin-left: 235px;
            top: 315px;
          }
        }
      }
      &.pmd-animate-circle-sm {
        width: 400px;
        top: 100px;
        height: 400px;
        left: 100px;
        @include transform-rotate(0deg);
        -webkit-animation: circle-sm 15s linear infinite;
        animation: circle-sm 15s linear infinite;
        opacity: $pmd-animate-circle-lg-opacity;
        span.pmd-animate-circle-dot{
          &:nth-child(1n){
            top: -5px;
            margin-left: -5px;
          }
          &:nth-child(2n){
            margin-left: -175px;
            top: 90px;
          }
          &:nth-child(3n){
            margin-left: -141px;
            top: 340px;
          }
          &:nth-child(4n){
            margin-left: 172px;
            top: 285px;
          }
        }
      }
    }
  }
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes circle-lg {
  0% { @include transform-rotate(0deg); }
  100% { @include transform-rotate(360deg); }
}
/* Standard syntax */
@keyframes circle-lg {
  0% { @include transform-rotate(0deg); }
  100% { @include transform-rotate(360deg); }
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes circle-md {
  0% { @include transform-rotate(0deg); }
  100% { @include transform-rotate(360deg); }
}
/* Standard syntax */
@keyframes circle-md {
  0% { @include transform-rotate(0deg); }
  100% { @include transform-rotate(360deg); }
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes circle-sm {
  0% { @include transform-rotate(0deg); }
  100% { @include transform-rotate(360deg); }
}
/* Standard syntax */
@keyframes circle-sm {
  0% { @include transform-rotate(0deg); }
  100% { @include transform-rotate(360deg); }
}

// Intro with Content on Both Sides code and example
@include media-breakpoint-down(sm) {
	.m-text-block {
		text-align: left !important;
		margin-top: $pmd-m-text-block-margin-top;
	}
}

// Intro Image Circle
.pmd-img-sm {
  max-width: $pmd-img-sm-size;
  height: $pmd-img-sm-size;
}
.pmd-img-md {
  max-width: $pmd-img-md-size;
  height: $pmd-img-md-size;
}
.pmd-img-lg {
  max-width: $pmd-img-lg-size;
  height: $pmd-img-lg-size;
}

// intro floating
.pmd-intro-fab-btn-center {
  position: absolute;
  left: 0;
  right: 0;
  margin: $pmd-intro-fab-margin-top auto 0 ;
}
