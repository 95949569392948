.pmd-avatar{
    height: $pmd-avatar-size;
    width: $pmd-avatar-size;
    overflow: hidden;
    border-radius: $pmd-avatar-border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    flex:0 0 auto;
    &img{
        border-radius: $pmd-avatar-border-radius;            
        height: $pmd-avatar-size;
        width: $pmd-avatar-size;
    }
    &.pmd-avatar-sm{
        height: $pmd-avatar-sm;
        width: $pmd-avatar-sm;
    }
    &.pmd-avatar-md{
        height: $pmd-avatar-md;
        width: $pmd-avatar-md;
    }
    &.pmd-avatar-lg{
        height: $pmd-avatar-lg;
        width: $pmd-avatar-lg;
    }
    &.pmd-avatar-xl{
        height: $pmd-avatar-xl;
        width: $pmd-avatar-xl;
    }
}

@each $color, $value in $theme-colors {
    .pmd-avatar-#{$color} {
        @include avatar-variant(theme-color-level($color, $avatar-bg-level), theme-color-level($color, $avatar-border-level), theme-color-level($color, $avatar-color-level));
        box-shadow: none;
    }
}