/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): accordion.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

// Accordion Inbox
.pmd-accordion-outlined {}

// Accordion with Icons
.pmd-accordion-icon-left {
  float: left;
  padding-right: $accordion-icon-padding-right;
}
.pmd-accordion-icon-right {
  float: right;
  padding-left: $accordion-icon-padding-left;
}

