:root {
  --switches-bg-color: $gray-700;
  --switches-label-color: white;
  --switch-bg-color: white;
  --switch-text-color: $gray-700;
}

body {
  background-color: #fff;
  overflow: unset !important;
  font-size: 0.75rem;

  @media (min-width:1560px) {
    font-size: 0.875rem;
  }
}

body.modal-open {
  overflow: hidden !important;
}

a {
  color: $gray-700;
}

.text-sm {
  font-size: 0.75rem !important;

  @media (min-width:1560px) {
    font-size: 0.875rem !important;
  }
}

.text-xs {
  font-size: 0.675rem !important;

  @media (min-width:1560px) {
    font-size: 0.75rem !important;
  }
}

.text-lighter {
  color: #979797 !important;
}

.btn-dark {
  background-color: $black;
  border-color: $black;
  color: $white;

  &:hover {
    background-color: $gray-800;
    color: $white;
  }
}



.btn-outline-danger {
  border-width: 1px;
  background-color: #fff5f6;

  &:hover {
    background-color: #d92d20;

    svg path {
      fill: #fff;
    }
  }
}

.z-10 {
  z-index: 1024;
}

.-mt-1 {
  margin-top: -2px;
}

.btn.btn-outline-dark {
  border-color: $border-color;
  background-color: #fff;
  text-wrap-mode: nowrap;

  &:hover {
    background-color: $black;

    svg path {
      fill: #fff;
    }
  }
}

.bg-secondary-100 {
  background-color: $secondary-100;
}

.bg-secondary-200 {
  background-color: #FAF0CC;
}

.bg-info-100 {
  background-color: $info-100;
}

.border-dashed {
  border: 2px dashed;
}

.calendar_month {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
}

.icon_right {
  position: absolute;
  right: 1.875rem;
  top: 50%;
  transform: translateY(-50%);
}

.tooltip.show {
  opacity: 1;
}

.pmd-navbar-right-icon {
  margin-right: 0.25rem;
}

.fs-14 {
  font-size: 14px !important;
}


.w-fit {
  width: fit-content;
}

.pmd-pagination .page-item .page-link {
  font-weight: normal;
}

.pmd-sidebar-with-brand {
  ~.pmd-content {
    // padding-left: 20px;
    // padding-right: 20px;

    // @include media-breakpoint-down(lg) {
    //   padding-left: 20px;
    // }
  }

  .input-group-text {
    background-color: transparent;
    border-right: 0;
  }
}

.document_upload_and_verify,
.personal_info {
  .pmd-input-group-filled .file-upload-control {
    color: #646464;
    font-size: 0.75rem;
    padding: .55rem 1rem;

    @media (min-width: 1560px) {
      font-size: 0.875rem;
    }

    &.value-selected {
      color: #212121;
      font-weight: 500;
      border-top-right-radius: 6px !important;
      border-bottom-right-radius: 6px !important;
    }

    &:focus {
      box-shadow: none;
      border-color: #dee2e6;
    }
  }

  .form-control[type="file"]:before {
    content: "";
    padding: 0;
  }

  .icon-top {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    z-index: 1024;
  }
}

.pmd-sidebar-with-brand {
  ~.pmd-content {
    // padding-left: 16px;
    // padding-right: 16px;

    @include media-breakpoint-down(sm) {
      padding-top: 80px;
    }

    @include media-breakpoint-down(xs) {
      padding-top: 68px;
    }
  }

  .pf-sidebar-toggle-container {
    position: absolute;
    top: 38px;
    right: -25px;

    .arrow-back-top {
      background-color: #e4e4e4;
      box-shadow: none;
      border-color: transparent;
    }
  }


  &.pmd-sidebar-open {
    .arrow-back-top {
      transform: rotate(180deg);
      border-color: transparent;
    }

    // ~ .pmd-content {
    //   padding-left: 20px;
    //   padding-right: 20px;

    //   @include media-breakpoint-down(sm) {
    //     padding-left: 15px;
    //     padding-right: 15px;
    //   }
    // }
    .pmd-sidebar-nav {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .pmd-content {
    // background-image: url('../../images/fils-icon-bg.svg');
    // background-repeat: no-repeat;
    // background-size: 140px;
    // background-position: 104% 40px;
    min-height: calc(100vh - 60px);

    @include media-breakpoint-down(sm) {
      padding-top: 84px;
      background-size: 100px;
      background-position: 104% 60px;
    }

    .container-small {
      max-width: 900px;
    }

    .container {
      @include media-breakpoint-down(sm) {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  .nav-item {
    padding: 4px 0;
    margin: 0 1rem;

    &.active {
      .nav-link {
        color: $secondary;
        background-color: #fffbf0;

        i {
          svg path {
            fill: #e6b401;
          }
        }
      }
    }

    .nav-link {

      &:hover,
      &:focus {
        color: $yellow;
        background-color: #fffbf0;
      }
    }
  }
}

.font-heading {
  font-family: $headings-font-family;
}

.text-desc {
  color: #646464 !important;
}

.text-desc-light {
  color: #979797 !important;
}

a:hover,
a:focus {
  text-decoration: none;
}

// Bottom Bar
.bottom-fixed-bar-shown {

  // margin-bottom: 6.75rem;
  @include media-breakpoint-down(sm) {
    // margin-bottom: 5.75rem;
  }
}

.container {
  max-width: 1080px;
}

.modal-dialog-scrollable {

  .model-body {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal-content {
    max-height: 90%;
  }
}

.status-failed {
  background-color: #ffeaec !important;
  border-color: #ffeaec !important;
  color: #d92d20 !important;
}

.status-in-process {
  background-color: #fcf0d3;
  border-color: #fcf0d3;
  color: #dc9203;
}

.status-reviewed-draft {
  background-color: #f0ddff;
  border-color: #d7aefa;
  color: #582a8c;
}

.status-rejected {
  background-color: #e8e8e8;
  border-color: #b9b9b9;
  color: #555454;
}

.status-duplicate {
  background-color: #fff;
  border: 1px solid #bf910a;
  color: rgb(186, 98, 0);

  &.on-badge {
    position: absolute;
    right: -26px;
    font-size: 12px;
    padding: 3px 19px !important;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    transform: rotate(46deg);
    top: 23px;
    border-radius: 0 !important;
    z-index: 9;
  }
}

.status-ready-to-reviewed {
  background-color: #cce6f7 !important;
  border-color: #bce8f1 !important;
  color: $primary !important;
}

.status-reviewed {
  background-color: rgba($color: #0caa31, $alpha: 0.1) !important;
  border-color: #ceedd6 !important;
  color: #065319 !important;
}

.status-uploaded {
  background-color: #ffeadf !important;
  color: #ba6200 !important;
  border-color: #d2c5be !important;
}

.bottom-action-bar {
  position: fixed;
  z-index: 1030;
  top: 100%;
  bottom: auto;
  left: 0;
  right: 0;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  @include pmd-box-shadow($bottom-bar-box-shadow);

  @include media-breakpoint-down(md) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @include media-breakpoint-down(md) {
    .px-2 {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }

    .me-2 {
      padding-right: 0.25rem !important;

      &.btn.pmd-btn-fab.btn-sm {
        padding-right: 0 !important;
      }
    }
  }

  &.shown {
    top: auto;
    bottom: 0;
  }
}

.pmd-sidebar-with-brand {
  &.pmd-sidebar {
    ~.pmd-content {
      .pf-invoice-footer {
        @include media-breakpoint-up(lg) {
          margin-left: 64px;
        }
      }
    }
  }
}

.pmd-sidebar-slide-push {
  &.pmd-sidebar-open {
    ~.pmd-content {
      .pf-invoice-footer {
        margin-left: $sidebar-width;
      }
    }
  }
}

.pmd-nav-item-icon {
  svg {
    width: 20px;
    height: 20px;
  }
}

// Date Picker
.pmd-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.pmd-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: rgba($secondary, 0.16);
}

.pmd-datepicker.pmd-datepicker-primarylight .pmd-datepicker-body table td span.selected,
.pmd-datepicker.pmd-datepicker-primarylight .pmd-datepicker-body table td.selected span {
  background-color: $secondary !important;
  color: $black;
  @include pmd-box-shadow($btn-hover-shadow);
}

// Help & Support Modal Box
.support-form {
  .modal-body {
    background-color: $body-bg;
    margin: 0;
    padding: 1.5rem;
    height: 100%;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-left: $border-width solid $border-color;

    @include media-breakpoint-down(md) {
      border-top: $border-width solid $border-color;
      border-left: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
  }
}

.editor-content {
  Ol {
    padding-left: 1rem;

    li {
      list-style-type: none;
      counter-increment: item;
      display: flex;
      margin-top: 0.5rem;

      &:before {
        content: counter(item) ". ";
        color: $dark;
        font-weight: $font-weight-semibold;
        margin-right: 0.25rem;
      }
    }
  }
}

// Temporary SCSS
.demo-link {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: auto;
  margin: auto;
  z-index: 99999;
  padding: 0rem 1.5rem;
  font-size: $font-size-sm;
  background-color: rgba($dark, 0.87);
  border-radius: $border-radius 0 0 $border-radius;
  color: $white;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $dark;
    color: $white;
  }
}

.pmd-icon-sm {
  font-size: $space28;
}

// KNET Button
.btn.btn-knet-payment {
  .material-icons-round {
    svg {
      path {
        fill: inherit;
      }
    }
  }
}

.pk-onboarding-dashboard {
  padding: 20px;

  @include media-breakpoint-down(sm) {
    padding: 15px;
  }
}

.pk-btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.pmd-owl-invoice {
  &.owl-carousel .owl-item img {
    min-height: 600px;
  }
}

.pmd-table.table {
  .thead-light {
    th {
      background-color: rgba($black, 0.05);
      white-space: nowrap;
      // width: 1%;
      min-width: 100px;

      @include media-breakpoint-down(sm) {
        width: unset;
      }
    }
  }

  tr td {
    vertical-align: middle;
  }
}

.pmd-radio .form-check-label:before {
  left: 6px;
  top: 6px;
}

.status-dropdown-custom {
  label {
    white-space: nowrap;
  }

  .pmd-option-check {
    color: transparent !important;
    border: 2px solid;
    height: 16px;
    width: 16px;
  }

  .ng-dropdown-panel.ng-select-bottom {
    min-width: 100% !important;
    right: 0;
    left: auto;
  }

  .pmd-select.pmd-select-outline {

    &.ng-select-opened,
    &.ng-select-focused {
      border: 1px solid rgba($secondary, 1);
    }

    .ng-select-container {
      width: 100%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .pmd-tab-container.pmd-card .tab-content {
    padding: 0 15px;
  }

  .row-filter-search {
    flex-direction: column;

    div {
      width: 100%;
    }

    .status-dropdown-custom {
      // margin-top: -20px;
      flex-direction: column;
      margin-top: 15px;

      label {
        width: 100%;
        margin-bottom: 5px;
        margin-right: 0 !important;
      }
    }
  }

  .shorting-btn .btn {
    align-items: center;
    padding: 5px;
  }

  .breadcrumb {
    height: 0 !important;
  }
}

.box-shadow-none {
  box-shadow: none;
}

.blank-state {

  // min-height: 50vh;
  .blank-state-desc {
    max-width: 460px;
    font-size: $space18;
    margin: auto;
    text-align: center;
  }
}

.alert {
  z-index: 4;
  // max-width: 460px;

  @include media-breakpoint-down(sm) {
    // left: 50%;
    // transform: translateX(-50%);
    // max-width: 300px;
    width: 100%;
  }
}

.pmd-alert-success {
  background-color: #55b938;
}

.bg-gray-light {
  background-color: $grayLight;
}

.option-icon {
  max-width: 24px;
}

.uploading-invoice {
  position: fixed;
  bottom: 12px;
  right: 12px;
  z-index: 9;
  width: 100%;
  max-width: 320px;

  @include media-breakpoint-up(lg) {
    bottom: 0;
    right: 0;
    max-width: 350px;
  }

  .invoiceSource {
    @include media-breakpoint-down(md) {
      white-space: normal;
    }
  }

  .btn {
    width: 100%;
    border-radius: 6px 6px 0 0;
    padding: 20px;
    text-align: left;
    background-color: $gray-700;
    border: none;
    text-transform: inherit;
    font-size: 16px;
    margin: 0 !important;

    &:focus,
    &:hover {
      outline: none;
    }
  }

  .radius-top-hide {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .uploading-time {
    background: $grayLight;
    padding: 6px 20px;
  }

  .list-group-item {
    align-items: center;
  }

  .list-group {
    box-shadow: none;
    max-height: 238px;

    .list-group-item {
      border-bottom: 1px solid #e4e4e4;
      padding: 13px 0;
    }
  }
}

.pmd-progress {
  background: none repeat scroll 0 0 #e4e4e4;
  border-radius: 5px;
}

.col-form-label {
  font-size: 0.75rem;
  color: #646464;
  padding-top: 0;
  font-weight: 500;
}

.accordion {
  .accordion-item {
    box-shadow: none !important;
  }

  .col-form-label {
    color: $gray7;
    // font-size: 13px;
    padding-bottom: 4px !important;
  }

  table {
    font-size: $space12 !important;

    input,
    textarea {
      // font-size: 12px !important;
    }

    // input, textarea, label {
    //     font-size: $space12 !important;
    // }
    td {
      // padding-left: 7px !important;
      // padding-right: 7px !important;
      padding: 5px 7px !important;

      @include media-breakpoint-down(sm) {
        padding: 5px 8px !important;
      }
    }
  }

  .accordion-header {
    i {
      transition: all linear 0.3s;
      border-radius: 50%;

      &:hover {
        border-radius: 50%;
        background-color: #e5e8ea;
        color: $gray-700;
        box-shadow: 0 0px 1px 0px rgba(44, 62, 80, 0.24);
        transition: all linear 0.3s;
      }
    }
  }

  .accordion-body {
    padding: 10px 15px;

    @include media-breakpoint-down(sm) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .pmd-table.table .thead-light th {
      width: unset;
    }
  }

  .accordion-button {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
    border-bottom: 1px solid $grayLightSecond;
    border-radius: 6px 6px 0 0 !important;
    color: $blue !important;
    font-weight: 600 !important;
    font-size: 15px;

    .accordion-arrow {
      color: $blue !important;
    }
  }

  .btn {
    font-size: 10px !important;
    text-transform: none !important;
    padding: 0;
    font-weight: 600;
    padding-top: 2px !important;
    padding-bottom: 2px !important;

    @include media-breakpoint-up(lg) {
      font-size: 13px !important;
    }

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
}

.h-48 {
  height: 48px;
}

.min-w-200 {
  min-width: 200px !important;
}

/* width */
*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: rgba($gray-700, 0.5);
  border-radius: 50px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: rgba($gray-700, 0.5);
}

.pmd-tooltip {
  .tooltip-inner {
    font-size: 13px !important;
    font-weight: normal;
    line-height: normal !important;
    padding: 8px 10px !important;
  }
}

@include media-breakpoint-down(sm) {
  .pmd-table-card-md.pmd-table.table tr:not(last-child) {
    border: 1px solid rgba(28, 36, 58, 0.1);
    // border-bottom: 1px solid
  }
}

@include media-breakpoint-down(md) {
  .pmd-table-card-md.pmd-table.table tr td {
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 577px) {
  .pmd-table-card-md.pmd-table.table tr td {
    flex-basis: 100%;
  }

  .btn-group-custom {
    margin-top: 20px !important;
    margin-bottom: 20px !important;

    button.pmd-btn-fab {
      width: 30px !important;
      height: 30px !important;
      min-width: 30px !important;

      i {
        line-height: 28px !important;
      }
    }
  }
}

.border-b-1 {
  border-bottom: 1px solid $grayLightSecond !important;
}

.form-select {
  font-size: $space14;
  border: 1px solid $border-color;
  color: $gray-950 !important;
}

.position-sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  z-index: 1;
  background-color: rgb(242 242 242 / 38%);

  // height: calc(100vh - 66px);
  @include media-breakpoint-up(md) {
    top: 115px;
    padding-bottom: 0;
  }

  @include media-breakpoint-up(sm) {
    top: 66px;
    padding-bottom: 20px;
  }

  @include media-breakpoint-down(sm) {
    position: relative;
  }
}

.footer-main {
  // padding: 0 20px;
  // margin-left: -20px;
  // margin-right: -20px;
  border-top: 1px solid rgba(112, 112, 112, 0.2);
  background-color: $white;
  width: 100%;
  // position: fixed;
  // bottom: 0;
  // width: 100%;
}

.pf-pass-valid-list .list-group-item {
  position: relative;
  padding: 0 0 0 1.5rem;
  margin-bottom: 0.5rem;
  width: 50%;
  font-size: 0.749875rem;

  &:before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: #cfd9ee;
    border-radius: 8px;
    position: absolute;
    left: 4px;
    top: 6px;
    text-decoration: none;
  }

  &.valid-point {
    text-decoration: line-through;
    color: pmd-theme-color(secondsecondary, dark);

    &:before {
      left: 0;
      top: 2px;
      width: 18px;
      background-color: transparent;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTggMTgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0ibm9uZSIgZD0iTTAsMGgxOHYxOEgwVjB6Ii8+DQo8cGF0aCBmaWxsPSIjMjU5QjI0IiBkPSJNNi44LDEyLjJMMy42LDlsLTEsMWw0LjIsNC4ybDktOWwtMS0xLjFMNi44LDEyLjJ6Ii8+DQo8L3N2Zz4NCg==");
      background-repeat: no-repeat;
      background-size: 18px;
      height: 18px;
      text-decoration: none;
      display: inline-block;
    }
  }
}

main#mainContent {
  padding-top: 78px;
  min-height: 100vh;
  // min-height: calc(100vh - 65px);
  background-color: $grayLight;
  // padding-bottom: 1rem;
  display: flex;
  flex-direction: column;

  @media(min-width:1560px) {
    padding-top: 88px;
  }

  &.no-beta-message {
    @include media-breakpoint-down(sm) {
      padding-top: 70px;
    }
  }

  @include media-breakpoint-down(sm) {
    padding-top: 66px;
  }
}

.content_block {
  flex: 1;
  padding: 0 0.5rem;
  padding-bottom: 1rem;
  // overflow: hidden;
}

.dropdown-top {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    width: 320px;
  }

  .duration-dropdown .pmd-input-group-outline .form-control {
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
  }
}

.pmd-site-info {
  margin-top: 3px;

  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
}

.pmd-floating-content-wrapper.card-body .pmd-floating-content-bc {
  bottom: -22px;
  font-weight: 700;
  border: none !important;

  @include media-breakpoint-down(sm) {
    bottom: -12px;
  }
}

.turnOverPopUp {
  padding: 0 $space20;
  max-width: 350px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    max-width: 250px;
  }

  // background-color: $gray-100;
  .popover-body {
    padding: 5px;
  }

  .verification-code {
    font-size: $space12;

    .title-info {
      line-height: normal;
      font-weight: 500;
      margin-bottom: $space10;
    }
  }

  .list-group-item {
    padding: 5px 0 0;
    align-items: inherit;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .material-symbols-outlined {
    font-size: 16px;
    // padding-right: 5px;
    display: inline-flex;
    height: 25px;
    // width: 25px !important;
    align-items: flex-start;
    justify-content: center;
    border-radius: 4px;
    font-weight: 300;
    background: transparent;
  }

  &.bs-popover-top .popover-arrow::before {
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.bs-popover-top .popover-arrow::after {
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.bs-popover-bottom .popover-arrow::before {
    top: -9px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.bs-popover-bottom .popover-arrow::after {
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
  }

  .pmd-form-card .pmd-textfield,
  .pmd-form-card .pmd-input-group-outline {
    margin-bottom: $space20;
  }
}

.pk-login-signup-card {
  @include media-breakpoint-down(sm) {
    .pmd-footer {
      display: none;
    }
  }
}

.pmd-form-card .pmd-textfield,
.pmd-form-card .pmd-input-group-outline {}

.pmd-input-group .pmd-textfield-is-invalid~.invalid-feedback,
.pmd-input-group-outline .pmd-textfield-is-invalid~.invalid-feedback,
.pmd-input-group-filled .pmd-textfield-is-invalid~.invalid-feedback {
  padding-left: 0;
}

.visibility-icon {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  right: 10px;
}

.reviewer-admin-list {
  .khr-input-group-outline-icon.khr-icon-r .pmd-textfield-outline .form-control {
    border-radius: 0.5rem;
  }

  .pmd-tabs .nav-tabs .nav-link {
    @include media-breakpoint-down(sm) {
      font-size: 11px;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      padding: 1rem 0.45rem;
    }
  }
}

.invoiceSource {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  color: $black;
  font-weight: 600;
  max-width: 100px;
  font-size: 0.75rem;

  @include media-breakpoint-up(lg) {
    max-width: 150px;
  }

  @media (min-width: 1560px) {
    font-size: 1rem;
  }
}

.khr-page-title {
  .invoiceSource {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
  }

  .card-subtitle {
    color: #646464;
    font-weight: normal;
  }
}

.khr-singal-upload-container {
  .invoiceSource {
    max-width: 100%;

    @include media-breakpoint-up(lg) {
      max-width: 100%;
    }
  }
}

.ng-dropdown-panel {
  box-shadow: 0 8px 24px rgba(44, 62, 80, 0.24);
  border: solid 1px rgba(28, 36, 58, 0.1);
  // min-width: 200px !important;

  .ng-dropdown-panel-items {
    max-height: 182px !important;
  }

  &.ng-select-top {
    border-top-right-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
  }
}

.min-200 .ng-dropdown-panel-items,
.pmd-min-200.ng-dropdown-panel {
  min-width: 200px !important;
}

.extra-custom {
  border: none !important;
  font-size: inherit;
  font-weight: inherit;
  line-height: 21px;
  max-width: 160px !important;
  padding: 0 !important;

  &.min-200 .ng-dropdown-panel-items {
    min-width: 200px;
  }

  .ng-dropdown-panel-items {
    background: #ffffff;
    border-top: 1px solid #e9ecef;
    border-radius: 0 0 0.5rem 0.5rem;
    left: 0;
    overflow: hidden;
    z-index: 1031;
    max-height: 182px;
  }

  .ng-dropdown-header {
    min-width: 200px;
    background: #ffffff;
    padding: 12px;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .ng-select-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .ng-option {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e9ecef;
    -webkit-user-select: none;
    user-select: none;
    white-space: initial;
    font-size: 13px;
    cursor: pointer;

    &.ng-option-marked {
      background: rgb($yellow, 8%);
    }
  }

  .ng-dropdown-panel {
    width: 200px;

    .scroll-host {
      height: 92px;
      overflow: auto;
    }
  }

  .ng-placeholder {
    color: $black !important;
  }
}

.col-form-label {
  .ng-placeholder {
    color: $gray-700 !important;
  }

  .pmd-select.pmd-select-outline {
    padding-left: 0;
  }
}

.pmd-upload-thumb {
  width: 100%;
  object-fit: contain;
}

.image-wrap-outer {
  display: inline-flex;
  align-items: center;
  height: 50px;
  min-width: 40px;
  width: 40px;
  margin-right: 5px;

  @media (min-width:1560px) {
    height: 60px;
    min-width: 48px;
    width: 48px;
  }

  .ng2-pdf-viewer-container {
    overflow: hidden;
  }
}

.image-h {
  height: 50px;
}

.avatar-sm {
  font-size: $space12;
  cursor: pointer;
  width: 25px;
  height: 25px;
  font-weight: 600;
}

.mandatory-field {
  // border: 2px solid $blue !important;
}

.duration-dropdown,
.switch-reviewer {
  position: relative;

  @include media-breakpoint-down(xs) {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: space-between;
  }

  label {
    white-space: nowrap;

    @media only screen and (max-width: 991px) and (min-width: 768px) {
      font-size: $space12;
    }
  }

  .pmd-input-group-outline,
  .pmd-select.pmd-select-outline {
    background-color: pmd-theme-color(primary, light);
    border-radius: $border-radius;

    #datePickerId {
      padding-left: 15px !important;
    }
  }

  .pmd-textfield-outline {
    z-index: 2;

    // .form-control {
    //     padding-right: 40px !important;
    //     cursor: pointer;
    //     background-color: transparent;

    //     @media only screen and (max-width: 991px) and (min-width: 768px) {
    //         padding-right: 8px !important;
    //         font-size: $space12;
    //     }
    // }
  }

  .input-group-append {
    z-index: 1;
  }

  .dropdown-menu {
    padding: 0;
    min-width: 97%;
    max-height: unset;
    z-index: 1025;

    @include media-breakpoint-down(sm) {
      transform: none !important;
      top: 100% !important;
      right: 0;
      left: unset !important;
      z-index: 1025;
    }
  }

  .dropdown-header {
    margin-top: 0;
    border-bottom: solid $border-width $border-color;
    padding: 0.75rem $space16;
    min-height: 66px;
    font-size: $font-size-base;
    white-space: normal;
    position: relative;

    h5 {
      color: $headings-color;
    }

    @include media-breakpoint-down(lg) {
      min-height: inherit;
    }
  }

  .pmd-datepicker {
    box-shadow: none;
    height: 100%;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }

  .pmd-datepicker-custom-range {
    border-right: solid $border-width $border-color;
    width: 145px;
    font-size: 12px;

    @include media-breakpoint-down(sm) {
      // width: 100%;
      // position: absolute;
      // left: 0;
      // top: 0;
      z-index: 1;
      width: 278px;
      display: flex;
      overflow-x: scroll;
    }

    .dropdown-item {
      padding-top: 9px !important;
      padding-bottom: 9px !important;
    }

    @include media-breakpoint-down(sm) {
      .pmd-datepicker-predefined-btns {
        display: flex;
        overflow-x: auto;

        .dropdown-item {
          border-bottom: none;
          border-right: solid $border-width $border-color;
          justify-content: center;
          padding-left: $space12;
          padding-right: $space12;

          &:last-child {
            border-right: none;
          }

          &.selected {
            background: $dropdown-link-hover-bg;
          }
        }
      }
    }
  }

  .pmd-datepicker-primary {

    .pmd-datepicker-multiple,
    .pmd-days-calendar {
      .pmd-datepicker-head {
        border-radius: 0;
        background-color: $gray-950;
        padding-bottom: 25px;
        padding-top: 3px;

        .pmd-datepicker-controls {
          .pmd-datepicker-spacer {
            display: none;
          }

          button {
            margin: 0 auto;

            &.next,
            &.previous {
              width: 30px;
              height: 30px;
              line-height: 40px;

              &:after {
                margin: 10.5px;
              }
            }

            &.current {
              // pointer-events: none;

              &:after {
                // content: none;
              }
            }

            &.previous {
              order: -1;
            }
          }
        }
      }

      .pmd-datepicker-body {
        table {
          thead {
            background: none;

            th {
              font-size: 11px;
              font-weight: 500;
            }
          }

          tr {
            &:hover {
              background-color: unset !important;
            }
          }

          td {
            color: $body-color;

            span {
              border-radius: $border-radius;
              width: 30px;
              height: 30px;
              line-height: 32px;
              font-size: 12px !important;
              font-weight: 500;

              &.disabled {
                //   color: $pmd-datepicker-disabled-color;
                color: $pmd-datepicker-other-month-color;
              }

              &.disabled.is-other-month,
              &.is-other-month {
                color: $pmd-datepicker-other-month-color;
                pointer-events: none;
                display: none;
              }

              @include media-breakpoint-down(lg) {
                width: 32px;
                height: 32px;
                line-height: 32px;
              }
            }

            span[class*="select-"],
            &[class*="select-"] span {
              border-radius: $border-radius;
            }

            span.selected,
            &.selected span {
              background-color: $secondary !important;
              color: pmd-theme-color(black, light);
            }
          }

          &.days {
            span.in-range:not(.select-start):before {
              border-radius: 7px;
              height: 100%;
              top: 0;
              width: 100%;
              left: -1px;
            }
          }
        }

        .months {
          td {
            span {
              width: unset;
              height: unset;
            }
          }
        }

        .years {
          td {
            span {
              width: 40px;
            }
          }
        }
      }
    }
  }

  .pmd-datepicker-multiple+.pmd-datepicker-multiple {
    margin-left: 0;
    border-left: solid $border-width $border-color;

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }

    .pmd-datepicker-head {
      border-radius: 0 3px 0 0;
      // border-left: solid 1px #302148;
      margin-left: -1px;
    }
  }

  .pmd-datepicker-buttons {
    padding: $space16;
    align-items: center;
    padding-top: $space10;
    padding-bottom: $space10;

    span {
      color: $body-color;

      @include media-breakpoint-down(sm) {
        margin-bottom: $space10;
        width: 100%;
      }
    }
  }

  .pmd-select.pmd-select-outline {
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      font-size: $space12;
    }
  }

  .customrange-datepicker {
    overflow: hidden;
    border-radius: $border-radius;

    pmd-daterangepicker-inline {
      display: flex;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }

    .pmd-datepicker {
      .pmd-media-container {
        @include media-breakpoint-down(md) {
          // display: block;
          // flex-direction: row;
        }
      }
    }
  }

  .pmd-datepicker-multiple {
    @include media-breakpoint-down(sm) {
      display: unset;
    }
  }
}

.reviewer-admin-list {
  &.reviewer-details {
    @include media-breakpoint-down(sm) {
      .pmd-tabs {
        padding-top: 0 !important;
      }
    }
  }

  .pmd-tabs {
    @include media-breakpoint-down(sm) {
      // padding-top: 70px !important;
    }

    .invoice-list-tabs td.pf-column-name h6 {
      max-width: 200px;
    }
  }

  .date-dropdown {
    @media only screen and (max-width: 991px) and (min-width: 768px) {
      top: -66px;
    }

    @include media-breakpoint-down(lg) {
      top: -144px !important;
      position: unset;
      left: 15px !important;
      right: auto !important;

      label {
        margin-bottom: 5px;
      }
    }
  }

  .admin-top-header {
    .pmd-textfield-outline {
      .form-control {
        padding: calc(0.75rem - 1px) calc(1rem - 1px);
        height: 40px;
        background-color: #fff;
      }
    }

    .duration-dropdown .pmd-input-group-outline .form-control {
      padding: calc(0.75rem - 1px) calc(1rem - 1px);
      height: 40px;
    }

    .pmd-select.pmd-select-outline .ng-select-container {
      padding: calc(0.65rem - 1px) 0;
    }

    .pmd-input-group-outline>.pmd-textfield-outline:not(:first-child) .form-control {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

.pmd-input-group-outline .pmd-select.ng-select-multiple .ng-arrow-wrapper {
  margin-top: 0;
}

.select_entity {
  .pmd-select.ng-select-opened>.ng-select-container .ng-arrow {
    top: 4px;
  }

  .pmd-select.ng-select-opened .ng-placeholder {
    transform: translateY(-18px);
    font-size: 10px;
  }
}

.avatar-in-field {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.ts-search {
  input {
    border-radius: 0.5rem !important;
  }

  .dropdown-toggle.pmd-btn-fab.btn-sm {
    width: 30px;
    height: 30px;
    min-width: 30px;

    .pmd-icon-xs {
      font-size: 15px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .ng-select-container {
    // padding-top: 9px !important;
    // padding-bottom: 9px !important;
    // height: 36px;
  }

  .selection-dropdown-group .input-group-text {
    height: auto !important;
  }

  .login-section {
    .input-group-text {
      height: auto !important;
    }
  }
}

.status-uploaded {
  background-color: #ffeadf !important;
  color: #ba6200 !important;
  border-color: #d2c5be !important;
}

.status-uploaded,
.status-in-process {
  cursor: unset !important;
}

.pf-column-name {
  cursor: pointer;

  .push-to-upload-thumb {
    width: 30px;
    height: 40px;
    object-fit: contain;
    border: solid rgba(0, 0, 0, 0.1) 1px;
    border-radius: 0.2rem;
  }
}

.pmd-sidebar-right-fixed {
  .list-group-item {
    padding-top: 11px;
    padding-bottom: 11px;
  }
}

.btn {
  font-family: inherit;

  @media screen and (max-width: 1280px) {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  @include media-breakpoint-down(lg) {
    font-size: $space12;
  }
}

.pmd-btn-min {
  @include media-breakpoint-up(md) {
    min-width: 90px !important;
  }
}

.ng-dropdown-panel .scroll-host {
  // min-width: 200px;
}

.business-detail {
  .pmd-col-listing:before {
    border-bottom: 1px solid rgba(0, 57, 102, 0.12);
  }
}

.notification-dropdown {
  width: 300px;
  max-height: unset !important;
  overflow: unset;

  .card-header {
    padding: $space12;
  }

  .media {
    display: flex;
  }

  .list-group {
    max-height: 285px;
    overflow: auto;
  }

  .view-all {
    width: 100%;
    border-top: 1px solid $gray-100;
    border-radius: 0;
  }

  .unread {
    background-color: rgb(255 234 223 / 60%) !important;
  }
}

.notifications {
  .list-group-item {
    &.new-day {
      margin-top: 50px;

      &:before {
        content: attr(data-date);
        display: block;
        font-size: 0.875rem;
        left: 0;
        position: absolute;
        color: rgba(0, 0, 0, 0.64);
        top: -30px;
      }
    }
  }
}

.icon-xs-14 {
  font-size: 14px;
}

.font-weight-normal {
  font-weight: normal !important;
}

.business-activated {
  padding: 0 !important;
  transition: all linear 0.3s;
  box-shadow: none;

  &:hover {
    box-shadow: 0px -2px 5px -2px rgb($black, 26%);
    transition: all linear 0.3s;
    cursor: pointer;
  }

  .info-business {
    width: 100%;
    background-color: (rgba($blue, 0.05));

    .card-info {
      width: 100%;
      padding: 15px;
      border-bottom: 1px solid $grayLightSecond;

      >a {
        font-size: $space16;
        font-weight: 600;
        margin-bottom: 0 !important;
        display: flex;
        line-height: normal;
      }

      .gst-info {
        margin-bottom: $space8;
      }

      .material-icons {
        font-size: $space14;
        color: $gray-700;
      }
    }

    .card-statistic {
      width: 100%;
      padding: 10px;
      // flex-wrap: wrap;

      .recent-status {
        width: 100%;

        h5 {
          color: $blue;
          font-size: $space16 !important;
        }

        span {
          font-size: $space12;
          font-weight: 600;
        }
      }
    }
  }
}

.divider {
  width: 1px;
  display: inline-flex;
  background-color: $grayLightSecond;
}

.business-registered-wrap {
  margin: $space10 0 !important;

  &:first-child {
    margin-top: $space24 !important;
  }

  .registered-business {
    background-color: $white !important;
    border-radius: $space10;

    .divider {
      height: 30px;
    }

    .card-info {
      box-shadow: 0 4px 8px rgb(41 128 185 / 0.5%);

      @include media-breakpoint-down(sm) {
        padding-bottom: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      >.d-flex {
        flex-direction: column;
        align-items: flex-start !important;
      }

      .contact-right {
        margin-left: 15px;
        margin-bottom: 10px;

        .pmd-avatar.pmd-avatar-sm {
          width: 28px;
          height: 28px;

          .pmd-icon-primary {
            font-size: $space14;
            transition: all linear 0.3s;
          }
        }
      }
    }
  }
}

.text-blue h5 {
  color: $blue !important;
}

.business-stastic {
  // border: none;
  box-shadow: none;
  padding: $space16 $space20 !important;
  transition: all linear 0.3s;

  @include media-breakpoint-down(lg) {
    padding: 1rem !important;
  }

  @include media-breakpoint-down(sm) {
    >.d-flex {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start !important;

      .pmd-avatar {
        margin-bottom: $space8;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    >.d-flex {
      flex-direction: row;
    }
  }

  &:hover {
    box-shadow: 0px 0px 8px -2px rgb($black, 26%);
    transition: all linear 0.3s;
    cursor: pointer;
  }

  @include media-breakpoint-down(sm) {
    img {
      margin-bottom: $space10;
    }

    padding: $space10 !important;
  }

  &:before {
    content: "";
    height: 50px;
    width: 3px;
    background: $yellow;
    display: block;
    position: absolute;
    left: -1px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 10px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .pmd-avatar.pmd-avatar-md {
    @include media-breakpoint-down(lg) {
      height: 40px;
      width: 40px;

      span {
        font-size: 26px;
      }
    }

    @include media-breakpoint-down(sm) {
      height: 30px;
      width: 30px;

      span {
        font-size: 20px;
      }
    }
  }
}

.title-section {
  @include media-breakpoint-down(xs) {
    justify-content: flex-start !important;
    align-items: flex-start !important;
    margin-bottom: 15px !important;
    flex-direction: column;
  }

  .pmd-textfield.pmd-textfield-outline .form-control {
    height: 40px;
  }

  .pmd-select {
    height: 40px;

    .ng-select-container {
      padding-top: $space8 !important;
    }
  }

  p {
    font-weight: 600;
  }

  @include media-breakpoint-down(xs) {
    .date-dropdown {
      width: 100% !important;

      label {
        margin-bottom: 5px;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    >.d-flex {
      flex-direction: column;
      width: 100%;

      label {
        margin-left: 10px;
        margin-bottom: 5px;
      }
    }
  }
}

.card-overview {
  .card-body {
    @include media-breakpoint-down(xs) {
      margin-bottom: 0;
    }
  }
}

.reviewer-info {
  .reviewer-info-detail {
    >a {
      font-size: $space14;
      font-weight: 600;
    }
  }
}

.tab-business {
  .nav-link {
    padding-top: $space10 !important;
  }

  .tab-content {
    max-height: 430px;
    overflow: auto;

    @include media-breakpoint-down(sm) {
      max-height: unset;
      overflow: unset;
    }
  }

  .pmd-tabs {
    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
  }

  .col-lg-6 {
    @media screen and (max-width: 1300px) {
      flex-basis: 33.33% !important;
    }

    @include media-breakpoint-down(md) {
      flex-basis: 50% !important;
    }

    @include media-breakpoint-down(xs) {
      flex-basis: 100% !important;
    }
  }
}

a.card-subtitle {
  &:hover {
    color: $blue;
    height: unset;
  }
}

.scrollbar {
  max-height: 470px;
  overflow: auto;

  @include media-breakpoint-down(sm) {
    max-height: unset;
  }
}

.pmd-table-header-fixed {
  position: relative;
}

.pmd-table-header-fixed .header-fixed {
  position: absolute;
  top: 0;
  background-color: #fff;
  left: 0;
  right: 0;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.pmd-table-header-fixed .header-fixed th {
  transition: none;
}

.row-dashboard {
  >.col-lg-6:first-child {
    @media screen and (max-width: 1300px) {
      flex-basis: 100% !important;
    }

    @include media-breakpoint-down(md) {
      flex-basis: 100% !important;
      width: 100% !important;
    }
  }

  >.col-lg-6:last-child {
    @media screen and (max-width: 1300px) {
      flex-basis: 100% !important;
    }

    @include media-breakpoint-down(md) {
      flex-basis: 100% !important;
      width: 100% !important;
    }
  }
}

.pmd-avatar {
  &:hover {
    .pmd-icon-primary {
      color: $dark;
      transition: all linear 0.3s;
    }
  }
}

.reviewer-admin-list {
  .columnAction {
    padding-top: 14px !important;
  }
}

.w-max-content {
  min-width: 300px !important;
}

.pmd-upload-button {
  cursor: pointer;
}

.icon-edit-option {
  position: absolute !important;
  top: 0;
  right: 1px;
  font-size: 18px;
  background: #ffffff;
  background-color: transparent !important;
  height: unset !important;
  width: unset !important;
  box-shadow: none !important;
  border: none !important;

  i {
    font-size: 20px;
    line-height: 30px;
    color: #cccccc !important;
  }
}

.input-in-dropdown:focus+.icon-edit-option i {
  color: $yellow !important;
  opacity: 1;
}

// #datePicker{
//     min-width: 210px;
// }
.date-dropdown {
  .pmd-filter-row {
    max-width: unset !important;
  }
}

.word-wrap {
  word-break: break-all;
}

.pmd-filter-row .ng-placeholder,
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $gray-700 !important;
  //  font-style: italic;
  font-size: 13px;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $gray-700 !important;
  //   font-style: italic;
  font-size: 13px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $gray-700 !important;
  //  font-style: italic;
  font-size: 13px;
}

.input-in-dropdown {
  font-size: 13px !important;
}

.accordion-header,
.btn-user {
  i {
    transition: all linear 0.3s;
    border-radius: 50%;

    &:hover {
      border-radius: 50%;
      background-color: #e5e8ea;
      color: $gray-700;
      box-shadow: 0 0px 1px 0px rgba(44, 62, 80, 0.24);
      transition: all linear 0.3s;
    }
  }
}

.btn-group-custom {
  @include media-breakpoint-down(lg) {
    padding-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

.pmd-checkbox .form-check-label,
.pmd-radio .form-check-label {
  font-weight: normal;
}

.input-group {
  height: 100%;
}

.pmd-input-group-outline.input-group-sm .custom-select,
.pmd-input-group-outline.input-group-sm .form-control:not(textarea) {
  padding-right: calc(1rem - 1px);
  padding-left: calc(1rem - 1px);
  padding-right: calc(1rem - 1px);
  padding-left: calc(1rem - 1px);
}

.reviewed-by-user {
  span {
    line-height: 24px;
    font-size: 13px;
  }

  .avatar-sm {
    font-size: 11px;
  }
}

.date-dropdown {
  top: -72px !important;
}

.columnAction {
  .pmd-icon-sm {
    font-size: 24px;
  }
}

.row-selected {
  background-color: rgba(51, 198, 203, 0.06) !important;
}

.pmd-breadcrumb .breadcrumb-item.active {
  color: $gray-950;
  font-weight: 700;

  a {
    text-decoration: none;
  }
}

.pmd-breadcrumb .breadcrumb-item {
  font-size: .75rem;
  font-weight: 400;

  @media (min-width:1560px) {
    font-size: 0.875rem;
  }

  a {
    text-decoration: underline;
    text-underline-position: under;
  }
}

.pmd-col-listing .pmd-col-listing-item {
  padding: 0.5rem 1.25rem;
}

.ng-invoice-per-page {
  .ng-dropdown-panel {
    min-width: 60px !important;
  }
}

.ng-select .ng-clear-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 21px;
  position: absolute !important;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.ng-select.pmd-select-sm .ng-clear-wrapper {
  height: 18px;
}

.apexcharts-treemap-series {
  rect {
    stroke-width: 0 !important;
    /* Set the stroke width to 0 to remove the white stroke */
  }
}

.pmd-sidebar-nav .nav-link.active {
  background-color: transparent !important;
  // color: #fff;
}

.tags-table {

  input,
  textarea {
    &:focus {
      border: none !important;
      padding: inherit;
    }
  }

  td {
    vertical-align: middle !important;
  }

  .row-empty-tag {
    background-color: rgb(220 0 0 / 4%);
  }

  .tag-select {
    transition: all linear 0.3s;

    .ng-select-disabled.ng-select {
      border: transparent !important;

      .ng-select-container {
        background-color: transparent !important;
      }
    }

    .ng-select-container {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .ng-value-container {
        flex: 1;
      }
    }

    .ng-placeholder {
      color: $gray-700 !important;
      // font-style: italic;
      font-size: 13px;
      opacity: 1;
      // /* Firefox */
      // padding-left: 8px;
      // padding-top: 2px;
      // width: 100%;
    }
  }
}

.w-fit-content {
  width: fit-content;
}

.pmd-chip {
  background: rgba(41, 128, 185, 0.16);
  color: #2c3e50;
  font-weight: 500;
  line-height: normal;
  padding: 0.2rem 0.45rem 0.25rem;
  margin: 0 0.5rem 0.25rem 0;
  flex-direction: row-reverse;
  border-radius: 8px;

  &:hover {
    background: rgba(41, 128, 185, 0.16);
    color: #2c3e50;
  }

  i {
    content: "close";
    font-size: 13px;
    height: 1.25rem;
    width: 1.25rem;
    margin: -0.1rem -0.3rem -0.125rem 0.25rem;
    border-radius: 50%;
    border: none;
    font-weight: bold;
    font-style: normal;
    // line-height: 0.95;
    letter-spacing: normal;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    display: flex;
    align-self: center;
    justify-content: center;
    background-color: rgba(41, 128, 185, 0.16);
    color: rgba(41, 128, 185, 0.54);
    line-height: 18px;
  }
}

.percentage-value {
  color: $gray-400;
  font-weight: 500;
}

.w-40 {
  width: 40% !important;
}

.supplierNameTrim {
  max-width: 400px;

  @include media-breakpoint-down(sm) {
    max-width: 200px;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  padding: 5px 14px;
  background: #f9f9f9;
  font-size: 12px;
}

.supplier-name-truncate {
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.selection-item input {
  min-width: 100px;
}

.pmd-user-info .dropdown-menu {
  min-width: 200px;
  width: 100%;
}

.thead-bg {
  &:hover {
    background-color: transparent !important;
  }
}

.pmd-sidebar-nav .nav-link {
  font-weight: normal;
  font-size: 0.75rem;

  padding: 0.65rem;

  @media (min-width: 1560px) {
    padding: 0.875rem;
    font-size: 0.875rem;
  }
}

.dashboard-container {
  @include media-breakpoint-down(sm) {
    padding-right: 0;
    padding-left: 0;

    .pmd-tabs .nav-tabs .nav-link {
      padding: 1.5rem 8px 1.3rem;
      font-size: 12px;
    }

    h1 {
      font-size: 16px;
    }

    h4 {
      font-size: 14px;
    }

    .search-upload .pmd-filter-row {
      width: 100% !important;
    }
  }

  .dashboard-tabs {
    @include media-breakpoint-down(sm) {
      .date-dropdown {
        top: -115px !important;

        .selection-dropdown-group {
          margin-right: 5px !important;
        }

        input {
          padding-right: 16px !important;
        }

        .clear-input {
          width: 28px;
        }

        // .dropdown-toggle{
        //     max-width: 200px;
        // }
      }
    }
  }
}

.selection-dropdown-group .border-r-full .pmd-textfield-outline .form-control {
  border-radius: 0.5rem !important;
}

.demo-link {
  padding: 0rem 10px;
  font-size: 12px;
  height: 25px;
}

.channel-selection {
  input {
    max-width: 10px;
    width: 100%;
  }

  .ng-select-container {
    padding-top: calc(0.875rem - 3px);

    .ng-value-container {
      flex-wrap: nowrap !important;

      @include media-breakpoint-down(sm) {}

      .ng-value {
        margin-bottom: 0 !important;

        @include media-breakpoint-down(sm) {
          width: auto !important;
        }
      }
    }

    .ng-input {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .ng-select.ng-select-multiple {
    height: 100%;
    min-width: 165px !important;

    .ng-value-container {
      align-items: center;
    }

    .ng-value,
    .ng-clear-wrapper {
      display: none !important;
    }

    .ng-has-value .ng-placeholder {
      display: inherit;
    }

    pmd-checkbox {
      width: 100%;

      .pmd-checkbox.form-check-label {
        width: 100%;
      }
    }
  }

  .ng-select {
    min-width: 165px;
    // height: 48.6px;

    // @include media-breakpoint-down(sm){
    //     height: 40px;
    //     display: flex;
    //     align-items: center;
    // }
    // .ng-value{
    //     margin-bottom: 0;
    // }
  }
}

.border-navy {
  border-color: $gray-700 !important;
}

.sync-code-dropdown {
  width: 220px !important;

  .dropdown-item {
    &:hover {
      background-color: transparent !important;
    }
  }
}

.manage-tags {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }

  .pmd-filter-row .pmd-select .ng-select-container {
    width: 100% !important;
  }

  .pmd-filter-row {
    @include media-breakpoint-down(sm) {
      margin-right: 0 !important;
    }
  }

  .filter-n-sorting {
    @media screen and (max-width: 842px) {
      width: 100%;

      .status-dropdown-custom {
        flex-direction: column;
        // margin-top: 15px;
        //flex-basis: 50%;
        align-items: flex-start !important;
        margin-left: 0 !important;

        label {
          margin-bottom: 5px;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;

      .status-dropdown-custom {
        flex-basis: 100%;
        margin-left: 0 !important;
      }
    }
  }
}

.pf-navbar-right-icon {
  @include media-breakpoint-down(sm) {
    .pmd-btn-fab {
      width: 30px !important;
      height: 30px !important;
      min-width: 30px !important;
      margin-right: 8px !important;

      span {
        font-size: 20px !important;
        line-height: 30px !important;
      }

      svg {
        height: 20px !important;
      }
    }

    .btn-user {
      padding-left: 0 !important;

      .pmd-avatar {
        height: 30px !important;
        width: 30px !important;
      }
    }
  }
}

foreignObject {
  width: 100% !important;
}

.tag-select-list {
  background-color: #ffffff;
}

.pmd-select.tag-select-list .ng-option {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e9ecef;
  -webkit-user-select: none;
  user-select: none;
  white-space: initial;
  cursor: pointer;
  background-color: #ffffff;
}

.tag-select-list .ng-dropdown-panel-items {
  border-radius: 0.5rem;
}

.pmd-user-info .dropdown-menu {
  min-width: 200px;
  width: 100%;
}

.invoice-list-tabs,
.dashboard-container {
  .pmd-table.table .thead-light {
    th {
      min-width: unset;
    }
  }

  .pf-column-name {
    h6.card-title {
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 0.875rem;
    }
  }
}

.msg-web-info {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.msg-info {
  max-width: 100%;
  /* margin: 0 12px 10px; */
  /* top: 78px; */
  position: fixed;
  left: 0;
  border-radius: 0;
  width: 100%;
  text-align: center;
  /* height: auto; */
  z-index: 1025;
  border-right: none;
  border-left: none;
}

.pmd-sidebar-scrollbar {
  background-color: #fff;
  height: 100%;

  .tooltip {
    font-weight: normal;
  }
}

.no-beta-message-nav {
  @include media-breakpoint-up(md) {
    top: 28px;
  }
}

.pmd-navbar.khar_navbar {
  border-bottom: 1px solid $border-color;
  box-shadow: 0px 1px 10px 1px #4040400f;
  height: 62px;

  @media (min-width:1560px) {
    height: 72px;
    padding: 6px 1rem;
  }
}

.ng-dropdown-panel-items {
  .ng-option {


    .pmd-checkbox-option {
      display: flex;
      align-items: center;

      p {
        line-height: 1;
      }

      .pmd-option-check {
        margin-right: 0.5rem;

        &:before {
          content: "\e835";
          opacity: 0.54;
        }
      }
    }

    &.ng-option-marked {
      background: $pmd-theme-hover;
    }

    &.ng-option-disabled {
      cursor: default;
      opacity: 0.54;
    }

    &.ng-option-selected {
      background: $yellow-50;
      color: $ng-select-highlight;

      .pmd-checkbox-option {
        .pmd-option-check {
          &:before {
            content: "\e834";
            opacity: 1;
            // color: $pmd-theme-selection-checkbox;
            //   box-shadow:$textfield-outline-focus-box-shadow;
          }
        }
      }
    }

    .pmd-option-title {
      color: $pmd-list-title-color;
      font-size: $pmd-list-font-size;

      &:last-child {
        margin-bottom: 0;
      }

      &:first-child {
        margin-bottom: 0.5rem;
      }
    }

    .pmd-option-subtitle {
      color: $pmd-list-subtitle-color;
      font-size: $pmd-list-subtitle-font-size;
      margin-top: -0.25rem;

      &:first-child {
        margin-bottom: 0;
        margin-top: 0;
      }
    }

    .pmd-option-text:last-child,
    .pmd-option-subtitle:last-child {
      margin-bottom: 0;
    }

    .pmd-option-subtitle+.pmd-option-subtitle {
      margin-top: 0;
    }

    .pmd-option-text {
      color: $pmd-list-text-color;
      font-size: $pmd-list-text-font-size;
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  flex-direction: row-reverse;

  .ng-value-icon {
    margin: -0.15rem -0.3rem -0.125rem 0.25rem;
    min-width: 1.25rem;
  }
}

.selection-item .ng-select .ng-clear-wrapper {
  height: auto;
}

.invoice-circle-color {
  span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    background-color: transparent;
    border: 5px solid;
    margin-top: 7px;

    &.status-reviewed-count {
      // border-color: steelblue ;
      border-color: #339de1;
    }

    &.status-in-process-count {
      // border-color: goldenrod ;
      border-color: #ebb901;
    }

    &.status-rejected-count {
      // border-color: indianred ;
      border-color: #d97983;
    }
  }
}

.pmd-badge-paid {
  background: rgb(178 224 97 / 46%);
  // border: 1px solid #EBB901;
  color: #5c7d24;
}

.pmd-badge-unpaid {
  background: rgb(253 127 111 / 46%);
  // border: 1px solid #D97983;
  color: #8b2316;
}

.pmd-badge-partially-paid {
  background: rgb(126 176 213 / 46%);
  // border: 1px solid #339DE1;
  color: #11456b;
}

.status-success {
  background-color: #ecfdf3;
  color: #039855;
}

.pf-cursor-pointer {
  cursor: pointer !important;
}

.pmd-sidebar.pmd-sidebar-with-brand-beta {
  margin-top: 90px;

  @media (min-width:1560px) {
    margin-top: 100px;
  }
}

pmd-navbar.no-beta-message {
  top: 0;
}

pmd-sidebar.no-beta-message {
  margin-top: 0;
}

#mainContent.no-beta-message {
  padding-top: 110px;

  @media(min-width:1560px) {
    padding-top: 120px;
  }
}

.ng-select-sm {
  div:first-child {
    padding-top: 4px !important;
    padding-bottom: 3px !important;
  }
}

.modal-payment-status {
  .pmd-badge {
    text-transform: none;
  }

  .invoice-info {
    overflow: hidden;

    .pmd-col-listing:before {
      border-bottom: 0;
    }
  }

  // .pmd-textfield.pmd-textfield-outline.pmd-textfield-floating-label label {
  //     transform: translateY(12px);
  // }

  // .pmd-textfield.pmd-textfield-outline.pmd-textfield-floating-label.pmd-textfield-floating-label-active label {
  //     transform: translateY(-11px);

  // }
  .pmd-select.pmd-select-outline .ng-select-container {
    padding-top: calc(0.875rem - 3px);
  }
}

.table-child {
  background-color: #ffffff !important;

  td {
    vertical-align: middle !important;
    // padding: 5px 3px !important;
  }

  th {
    // background-color: transparent !important;
  }

  input {
    cursor: pointer;
    margin-left: -8px;
  }

  .ng-select-container {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;

    input {
      &:focus {
        border: none !important;
      }
    }
  }

  .pmd-input-group-outline .pmd-select {
    background-color: transparent;
    border-color: transparent;
    margin-left: -15px;
  }

  .pmd-select.pmd-select-outline.ng-select-opened {
    border: 1px solid $border-color;
  }

  .pmd-input-group-outline .pmd-select:not(.pmd-select-dark) .ng-value-label {
    color: $gray-950;
  }
}

.ng-dropdown-panel {
  background-color: #ffffff;
}

.bg-child-table {
  background: rgba($color: $secondary, $alpha: 0.06);
  position: relative;

  .pmd-card {
    border-color: $border-color !important;
  }

  &:hover {
    background-color: rgba($color: $secondary, $alpha: 0.06) !important;
  }

  >td {
    border-top-color: $border-color !important;
  }

  th {
    background-color: #ffffff !important;
  }

  tr {
    td {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }
  }
}

.row-expand {
  position: relative;

  td {
    background-color: rgba($black, 0.05);

    i {
      color: $blue !important;
    }
  }
}

// Switch toggle
.switch-expenditure .knobs:after {
  content: "Expense" !important;
}

.switch-sheet .knobs:after {
  content: "Sheet" !important;
}

.switch-toggle {

  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .button {
    position: relative;
    height: 26px;
    overflow: hidden;
    width: 137px;
  }

  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }

  .button.b2 {
    border-radius: 5px;
  }

  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .knobs {
    z-index: 2;
  }

  .layer {
    width: 100%;
    // background-color: rgba($color: $yellow, $alpha: 0.2);
    border: 1px solid $gray-300;
    transition: 0.3s ease all;
    z-index: 1;
    overflow: hidden;
    border-radius: 6px;
  }

  /* Button 10 */
  .knobs:before,
  .knobs:after,
  .knobs span {
    position: absolute;
    // top: 4px;
    top: 1px;
    width: 20px;
    height: 24px;
    font-size: 0.6rem;
    font-weight: 600;
    text-align: center;
    line-height: 1;
    padding: 8px 4px;
    transition: 0.3s ease all;
  }

  .knobs:before {
    content: "";
    left: 1px;
    background-color: $gray-100;
    width: 66px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .knobs:after {
    content: "Credit Note";
    right: 0px;
    color: $gray-800;
    width: 70px;
    top: 0px;
  }

  .knobs span {
    display: inline-block;
    left: 0;
    color: $black;
    z-index: 1;
    width: 70px;
    top: 0px;
    height: 100%;
  }

  .checkbox:checked+.knobs span {
    color: $gray-800;
  }

  .checkbox:checked+.knobs:before {
    left: 70px;
    background-color: $gray-100;
    border-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .checkbox:checked+.knobs:after {
    color: $black;
  }
}

.switch-table {
  .knobs {
    &:after {
      content: "E" !important;
      width: 23px;
      font-size: 11px;
      padding: 8px 4px;
      line-height: 9px;
    }

    &:before {
      width: 23px;
    }

    span {
      width: 23px;
      font-size: 11px;
      padding: 8px 4px;
      line-height: 9px;
    }
  }

  .button {
    width: 46px;
  }

  .checkbox:checked+.knobs:before {
    left: 24px;
  }
}

@media screen and (max-width: 768px) {
  .mb-xs-2 {
    margin-bottom: 10px !important;
  }

  .dashboard-tabs .statistic-total .total-supplier.mb-xs-2 {
    margin-bottom: 10px !important;
  }
}

.sort-payments {
  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .ng-select-container {
    width: 100% !important;
  }

  .ng-option {
    &.ng-option-marked {
      background: inherit !important;
    }

    &.ng-option-selected {
      background: rgb($yellow, 8%) !important;
    }

    &:hover {
      background: rgb($yellow, 8%) !important;
    }
  }

  .pmd-select .ng-placeholder {
    font-style: normal !important;
    z-index: 0 !important;
  }

  @media screen and (max-width: 400px) {
    flex-wrap: wrap;
  }

  .status-dropdown-custom {
    @media screen and (max-width: 767px) {
      flex-basis: 50%;
    }

    @media screen and (max-width: 400px) {
      flex-basis: 100%;
      margin-left: 0 !important;
      margin-bottom: 10px;
    }
  }

  .payment-mode-dropdown {

    .ng-value,
    .ng-clear-wrapper {
      display: none !important;
    }

    .ng-has-value .ng-placeholder {
      display: inherit;
    }
  }

  pmd-checkbox {
    width: 100%;

    .pmd-checkbox.form-check-label {
      width: 100%;
    }
  }

  .pmd-select.pmd-select-outline {
    height: 100%;
  }

  .paymentMode {
    max-height: unset;
  }

  .pmd-select.pmd-select-outline {
    height: 100%;
  }

  .scroll-host {
    max-height: unset !important;
  }

  .ng-select {
    min-width: 165px;
  }
}

.divider-vertical {
  display: block;
  width: 1px;
  height: 70px;
  background: #efefef;
  margin-right: 15px;
  margin-left: 15px;
  display: block;
  width: 1px;
  height: 59px;
  background: #efefef;
  margin-right: 2px;
  margin-left: 2px;

  @include media-breakpoint-up(xl) {
    margin-right: 15px;
    margin-left: 15px;
  }
}

.min-width-unset {
  min-width: unset !important;
}

.input-group-append {
  .input-group-text {
    padding: 5px 6px;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: transparent;
  }
}

.m-minus-10 {
  margin: -10px;
}

.pmd-sidebar.pk-right-sidebar {
  padding-top: 90px;

  @include media-breakpoint-up(md) {
    padding-top: 120px;
  }
}

.no-beta-message .pmd-sidebar {
  .pk-right-sidebar {
    padding-top: 100px;
  }
}

.ng-select .ng-arrow-wrapper {
  display: block !important;
  margin-top: 0;
}

.payment-supplier-truncate {
  max-width: 295px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.invoice-list-tabs {
  .clear-input {
    width: 25px !important;

    @include media-breakpoint-up(xl) {
      right: auto !important;
      left: -33px !important;
    }
  }

  .duration-dropdown {
    .clear-input {
      right: 24px !important;
    }
  }

  .tab-header-filter {
    width: 360px;

    @include media-breakpoint-down(sm) {
      position: initial;
      width: 100%;
    }
  }
}

.fullscreen-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  /* Set a background color or style as needed */
  z-index: 1034;
  padding-top: 15px;

  .image-container {
    max-width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    height: calc(100vh - 294px) !important;
  }

  pmd-accordion {
    height: calc(100vh - 160px);
    overflow: auto;
    margin-bottom: 10px;
  }

  .position-sticky {
    top: 0;
    height: 100%;
  }

  .pmd-accordion {
    position: relative;
    border-radius: 6px;
    border-top: solid 1px rgba(28, 36, 58, 0.1);
    border-bottom: solid 1px rgba(28, 36, 58, 0.1);

    .accordion-item:first-of-type {
      margin-top: 0;
    }

    .accordion-item:last-of-type {
      margin-bottom: 0;
    }
  }
}

.product-gallery__featured {

  .owl-carousel .owl-stage,
  .owl-item {
    width: 100% !important;
  }
}

.content-scroll {
  height: calc(100vh - 100px);
  overflow: auto;
  margin-bottom: 10px;

  pmd-accordion {
    height: auto;
    overflow: visible;
  }

  .btn-group-custom {
    margin-bottom: 1.25rem;
  }
}

.col-due-date {
  width: 120px;
}

.invoice-list-tabs .clear-input.clear-right {
  right: 0 !important;
  left: auto;
  top: 4px;
  height: 100%;
}

.invoice-list-tabs .duration-dropdown .icon_right {
  right: 24px !important;
}

.close_icons {
  position: absolute;
  right: 34px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.invoice-list-tabs .duration-dropdown .icon_right {
  right: 32px !important;
}

.pmd-textfield-outline {
  .clear-input {
    right: 10px;
    left: auto;
    background: transparent;
    width: auto;
    opacity: 0.75;
  }
}

.color-inherit {
  color: inherit;
}

.w-75 {
  width: 78%;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.khr-logedin-user {
  max-width: 200px;
}

/* New added Dec-28 */
/* Pmd-select-sm*/

.pmd-select.pmd-select-outline.pmd-select-sm {
  font-size: 13px !important;
}

.pmd-textfield-floating-label .pmd-select.pmd-select-sm.ng-select-opened .ng-placeholder,
.pmd-textfield-floating-label .pmd-select.pmd-select-sm .ng-has-value .ng-placeholder {
  transform: translateY(-16px);
  font-size: 10px;
}

.invalid-feedback {
  font-size: 10px;
  display: block;
}

.khr-payment-date-dd {
  &.position-absolute-right {
    top: 12px !important;
  }

  // .duration-dropdown .pmd-input-group-outline {
  //   width: 260px;
  // }
}

.khr-payment-date_w {
  min-width: 280px;
}

.khr-intro-section-wrap {
  padding: 76px 0 52px;
  margin-top: 70px;
}

.khr-overlay-card {
  margin-top: -80px;
}

ul.khr-listing {
  padding-left: 18px;

  li {
    margin-bottom: 10px;
  }

  ul {
    margin-top: 8px;
  }
}

.lower-alpha {
  list-style: lower-alpha;
}

.khr-swith-ingroup .input-group-text {
  padding: 2px 2px 2px 5px;
  height: 33.2px;
}

.khr-page-num {
  .ng-dropdown-panel {
    min-width: auto !important;
  }
}

pmd-alert.khr-missign-data {
  margin-left: 5px;
  padding: 0px 5px;
  font-size: 11px;
  font-weight: 500;
  color: #df6a4e;
  margin-bottom: 0;
  background-color: transparent;
  border-color: transparent;
  border-left: 1px solid #e4e4e4;
  border-radius: 0;
  max-width: 530px;
}

.sticky-tab {
  position: sticky;
  background: #fff;
  top: 62px;
  z-index: 1024;
  border-radius: 8px 8px 0 0;

  @media (min-width:1560px) {
    top: 66px;
  }
}

.mt-8n {
  margin-top: -8px;
  margin-right: -0.5rem !important;
}

.btn-sm {
  border-radius: 8px;
}

.associalted-invoice-list {
  max-height: 270px;
  overflow: auto;
}

.modal .associalted-invoice-list {
  max-height: 230px;
}

.container-minheight {
  min-height: 50vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.khr-entity-dd {
  @media only screen and (max-width: 575px) {
    order: 1;
    // margin-bottom: 10px;
  }

  .input-group-text {
    font-size: 12px !important;
  }

  .ng-value-container {
    @include media-breakpoint-down(md) {
      flex-wrap: nowrap !important;
      overflow-y: auto;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
    }
  }

  .ng-select {
    &.pmd-select {
      font-size: 12px !important;
      max-width: none !important;
      background-color: #fff !important;

      @include media-breakpoint-down(sm) {
        border-radius: 0.5rem !important;
      }

      .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        padding: 0.5rem 0.5rem;
        font-size: 0.75rem;

        @media (min-width:1560px) {
          font-size: 0.875rem;
        }
      }

      .pmd-checkbox-option .material-icons {
        font-size: 20px;
        color: rgba($black, 0.34);
      }

      .ng-option-selected .pmd-option-check:before {
        color: $secondary !important;
      }

      .ng-option-label {
        text-transform: uppercase !important;
        font-size: 10px;
      }
    }

    .ng-value {
      max-width: 66px;
    }

    .ng-value-label {
      line-height: 18px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media only screen and (max-width: 1024px) {
        // width: 100px !important;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      @media only screen and (max-width: 575px) {
        max-width: 145px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &.pmd-select-outline {
    padding-left: 8px !important;
  }

  .ng-value-icon {
    background-color: rgba(41, 128, 185, 0.3) !important;
    color: rgb(0 66 110) !important;
  }

  .ng-value.ts-more {
    .ng-value-label {
      margin: 0 !important;
    }
  }

  .ng-select-container {
    @include media-breakpoint-up(md) {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
    }
  }

  .ng-value-container {
    min-height: auto !important;
    width: 100%;

    .ng-value {
      margin-top: 4px !important;
      margin: 0.25rem !important;
    }
  }

  .pmd-select.pmd-select-outline.ng-select-multiple .ng-value {
    padding: 0.15rem 0.5rem;
  }

  .pmd-textfield-floating-label .pmd-select.ng-select-opened .ng-placeholder {
    font-size: 10px;
    transform: translateY(-18px);
  }
}

.khr-header-entity-dropdown {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 172px;
  }

  @include media-breakpoint-up(xl) {
    width: 240px;
  }
}

.header_entity-dd {
  .ng-select-container {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}

.navbar-multi-select-entity {
  width: 90%;

  @include media-breakpoint-up(md) {
    width: 190px;
  }

  @include media-breakpoint-up(lg) {
    width: 280px;
  }

  .ng-select-container {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}

.navbar-multi-select {
  .pmd-select-outline .ng-select-container {
    height: 40px;
  }

  .pmd-select.ng-select-multiple .ng-arrow-wrapper {
    margin-top: 0.25rem;
  }

  .pmd-select.ng-select-opened>.ng-select-container .ng-arrow {
    top: 6px;
  }
}

.select_entity {
  .pmd-select .ng-arrow-wrapper .ng-arrow {
    top: 4px;
  }
}

.btn-sm {
  border-radius: 0.5rem;
}

.khr-logedin-user {
  max-width: 200px;
}

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (max-width: 1024px) {
  .khr-entity-dd .ng-value.ts-more .ng-value-label {
    width: auto !important;
  }

  .edit-action {
    position: absolute;
    right: 0px;
    top: 30px;

    svg.bi-pencil-square {
      margin-right: 4px !important;
    }
  }

  .tags-table .pmd-table-card-md.pmd-table.table tr {
    position: relative;
    padding-right: 30px;
  }
}

.khr-has-validation {
  border-radius: 0;
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: 1560px) {
    padding: .52rem .75rem;
    font-size: 0.75rem;
  }
}

.khr-import-btn {
  position: absolute;
  right: 15px;
  top: -56px;

  .btn {
    svg {
      width: 20px;
    }
  }
}

.khr-import-btn-1 {
  .btn {
    &:hover {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}

.text-sucess {
  color: green;
}

.khr-balance {
  .khr-amount {
    border: 1px solid $border-color;
    border-radius: 8px;
    padding: 2px 10px;
    background: rgba($color: $secondary, $alpha: 0.05);

    .amount {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .form-control {
    @include media-breakpoint-down(sm) {
      height: 37px !important;
    }
  }
}

.white-space-normal.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal;
}

.pmd-textfield-floating-label .pmd-select.pmd-with-label .ng-has-value .ng-placeholder {
  color: #2980b9 !important;
  padding: 0 15px 0 5px;
}

.pmd-textfield-floating-label .pmd-select.pmd-with-label .ng-has-value .ng-placeholder {
  padding: 0 5px;
}

.pmd-textfield-floating-label .pmd-select.pmd-with-label.ng-select-opened .ng-has-value .ng-placeholder {
  color: $yellow !important;
}

.pmd-textfield-floating-label .pmd-select.pmd-label-mandatory.pmd-with-label .ng-placeholder {
  padding-right: 10px !important;
}

.pmd-with-label.mandatory-field .ng-placeholder:before {
  content: "*";
  color: red;
  position: absolute;
  z-index: 9;
  right: 0;
}

@media only screen and (max-width: 1100px) {
  .invoice-count .count-reviewed.d-flex {
    display: flex !important;
  }

  .invoice-list-tabs .clear-input {
    right: 50px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .duration-xs {
    position: absolute;
    right: 0;
    width: 40%;
  }

  .tags-table .edit-action {
    top: 0px !important;
  }

  .pmd-table-card-md.pmd-table.table td[data-title]:before {
    text-align: left;
  }

  .m-minus-10 {
    margin: 0px;
  }

  .pmd-select.khr-select-in-table {
    border: 1px solid $border-color !important;
  }

  .pb-xs-2 {
    padding-bottom: 16px;
  }

  .mb-xs-0 {
    margin-bottom: 0 !important;
  }

  .item-tab.pmd-card {
    margin-bottom: 15px !important;
  }
}

@media only screen and (max-width: 780px) {
  .mt-xs-4 {
    margin-top: 3rem !important;
  }

  .duration-xs {
    position: relative;

    .selection-dropdown-group .input-group-text {
      height: auto !important;
    }
  }

  .sync-code-admin {
    margin-bottom: 20px;
  }

  .sync-code-admin .input-group-text {
    height: auto !important;
  }

  .cdk-drop-list .cdk-drag {
    padding-right: 0;

    &:last-child {
      padding-right: 10px;
    }

    .reviewer-admin-list {
      .duration-dropdown .pmd-input-group-outline {
        width: 270px;
        margin-top: 20px;
      }
    }
  }

  .py-xs-2 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .payment-list .pmd-table-card-md.pmd-table.table td {
    padding: 8px 1rem !important;

    &.text-end {
      text-align: left !important;
    }
  }

  .payment-list .pmd-table-card-md.pmd-table.table tr {
    padding-top: 10px;
  }

  .payment-list .pmd-input-group-outline {
    margin-bottom: 0 !important;
  }

  // .khr-entity-dd .ng-select .ng-value {
  //   max-width: 115px;
  // }

  .duration-dropdown .pmd-datepicker-buttons {
    display: flex;
    font-size: 13px;
  }

  .selection-item .status-dropdown-outer {
    padding-left: 96px !important;
  }

  .pmd-table-header-fixed .header-fixed {
    display: none;
  }

  .tags-table .pmd-table-card-md.pmd-table.table td.checkbox-column {
    right: 8px;
    top: 20px;
  }

  .pmd-table-card-md.pmd-table.table td {
    text-align: left !important;
  }
}

.bg-light-gray {
  background-color: rgba($color: $gray-100, $alpha: 0.2);
  border-radius: 10px;
}

.bg-gray-100 {
  background-color: #f2f4f6;
}

@media only screen and (max-width: 767px) {
  .cdk-drop-list .cdk-drag {
    padding-right: calc(var(--bs-gutter-x) * 0.5);

    &:last-child {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
    }
  }

  .manage-tags .pmd-filter-row .pmd-select .ng-select-container {
    padding-right: 0 !important;
  }

  .manage-tags .row-filter-search .input-group-text {
    height: 40px !important;
  }

  .import-modal {
    .pmd-file-input-sm .pmd-file-wrapper .pmd-drop-container {
      flex-direction: column;
      height: 150px;
    }
  }

  .date-dropdown .selection-dropdown-group .input-group-text,
  .date-dropdown .pmd-textfield.pmd-textfield-outline .form-control-sm {
    height: 38px !important;
  }

  .same-as-parent .input-group-text {
    height: 49px !important;
  }

  .mb-sm-3 {
    margin-bottom: 20px;
  }

  .payment-filter {
    display: flex;
    flex-direction: column;

    .sort-payments>.d-flex {
      width: 100%;
    }

    .pmd-filter-row .pmd-select .ng-select-container {
      padding-right: 0;
    }

    .sort-payments .pmd-select.pmd-select-outline {
      width: 100%;
    }
  }

  .entity-search {
    .input-group-text {
      height: 50px !important;
    }
  }
}

@media only screen and (max-width: 760px) {
  .sticky-tab {
    top: 46px;
  }

  .pmd-datepicker-custom-range .dropdown-header {
    display: none;
  }
}

@media only screen and (max-width: 580px) {
  .dashboard-tabs .total-invoice span {
    width: 70%;
    margin: 0 auto;
  }

  .invoice-thumb-img {
    margin-right: 20px;
  }

  .tags-table .pmd-table-card-md.pmd-table.table td.checkbox-column {
    right: 8px;
    top: 14px;
  }

  .khr-supplier-tab .total-card.total-gst,
  .khr-supplier-tab .total-card.total-amount,
  .khr-supplier-tab .total-card.total-invoices {
    margin-bottom: 15px !important;
  }

  .upload-invoice {
    .pmd-card .card-body .pmd-list .list-group-item {
      font-size: 10px;
      padding: 5px 10px;

      svg {
        width: 14px;
      }
    }

    .pmd-list-transparent {
      flex-direction: column !important;
    }
  }

  .tags-table .edit-action {
    position: absolute;
    //right: 27px;
    top: -8px !important;
  }

  .tags-table .pmd-table-card-md.pmd-table.table tr {
    position: relative;
    padding-right: 0;
  }

  .payment-filter.pmd-filter-row .pmd-select .ng-select-container {
    padding-right: 0;
  }

  .duration-dropdown .pmd-datepicker-primary .pmd-datepicker-multiple .pmd-datepicker-body table td span,
  .duration-dropdown .pmd-datepicker-primary .pmd-days-calendar .pmd-datepicker-body table td span {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .pmd-datepicker-body {
    min-width: 100%;
  }

  .duration-dropdown .pmd-datepicker-buttons span {
    margin-right: 0 !important;
    text-align: center;
    font-size: 13px;
  }

  .khr-entity-card .khr-add-btn {
    margin-left: 9px !important;
  }

  .search-box {
    width: 100%;

    .input-group-text {
      margin-top: 2px;
    }
  }

  .supplier-name-truncate {
    max-width: max-content;
    white-space: pre-wrap;
  }
}

.fs-10 {
  font-size: 10px;
}

.khr-supplier-table {
  .pmd-table.table .thead-light th {
    min-width: inherit;
  }
}

.khr-select-200 {
  min-width: 200px;
}

.full-preview .image-container {
  max-width: 100% !important;
  height: 1800px !important;
}

.ng-select.khr-small-dd .ng-select-container,
.khr-auto-field .khr-small-dd.ng-select .ng-select-container {
  min-width: 50px !important;
  width: 60px !important;
  max-width: 60px !important;
}

.khr-full-screen {
  .zoom-controller {
    position: fixed;
    top: 0;
    width: 1136px;
    z-index: 1030;
  }

  .full-preview {
    width: 1200px;
    margin: 0 auto;
    position: relative;

    .invoiceSource {
      max-width: 100%;
    }

    .image-container {
      max-width: 100% !important;
      height: 1700px !important;
      width: 1500px !important;
      align-items: self-start !important;
      background-color: #fff !important;
      margin-top: 80px !important;
    }

    .zoom-image {
      width: 1500px !important;
    }
  }
}

.khr-full-screen-details {
  border-left: none !important;
}

@media only screen and (max-width: 1500px) {
  .khr-full-screen .full-preview .invoiceSource {
    margin-left: 140px;
    margin-top: 10px;
  }

  .khr-full-screen .full-preview .image-container {
    height: 1400px !important;
  }

  .khr-full-screen .full-preview .zoom-image,
  .khr-full-screen .full-preview {
    width: 100% !important;
  }

  .khr-full-screen .zoom-controller {
    width: 95%;
    max-width: 1500px;
  }
}

@media (orientation: portrait) {
  .khr-full-screen .full-preview .image-container {
    height: 1400px !important;
  }
}

// @media only screen and (min-height: 1024px) {
//   .khr-full-screen .full-preview .image-container {
//     height: 1024px !important;
//   }
// }
.status-dropdown-custom {
  .ng-dropdown-panel .ng-option {
    padding-right: 0 !important;
  }

  .compnay-name-select {

    &.ng-select-opened,
    &.ng-select-focused {
      border: 1px solid rgba($secondary, 1);
    }
  }
}

.btn-sm-tag {
  padding-left: 7px !important;
  padding-right: 7px !important;
  width: 104px !important;
  white-space: nowrap !important;
}

.w-280 {
  min-width: 280px;
}

.dropdown_col {
  min-width: 290px;
}

.showing-lable {
  visibility: visible !important;
}

.khr-tag-management {
  .pf-column-name {
    @include media-breakpoint-up(lg) {
      max-width: 200px;
    }
  }
}

.khr-select-in-table-hauto {
  &.pmd-select.pmd-select-outline.pmd-select-sm {
    border-color: $border-color;

    &.ng-select-opened.ng-select {
      border: 1px solid $border-color;
      background-color: transparent;
    }

    .ng-clear-wrapper {
      .ng-clear {
        font-size: 14px;
      }
    }

    .ng-select-container {
      height: auto;
      padding-top: 4px;
      padding-bottom: 1px;

      .ng-value-label {
        font-size: 0.749875rem;
        font-weight: normal;
        white-space: normal;
      }
    }
  }
}

table.pending-tag-tbl .ng-select .ng-select-container .ng-value-container {
  flex: 1 !important;
}

.tag-select-list.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;
}

.apexcharts-zoom-icon.apexcharts-selected svg {
  fill: $secondary !important;
}

.apexcharts-pan-icon.apexcharts-selected svg {
  stroke: $secondary !important;
}

.modal-title {
  color: $black;
}

.pmd-datepicker.pmd-datepicker-primary .pmd-datepicker-body table td span.selected {
  background-color: #ebb901;
  color: $black;
}

.pmd-textfield-floating-label .pmd-select.pmd-select-sm.khr-invoice-dd .ng-has-value .ng-placeholder {
  transform: translateY(-18px);
  line-height: 1;
}

.khr-extra-field .pmd-select,
.khr-extra-field .pmd-select .ng-select-container {
  max-width: 70px;
}

.flex-1 {
  flex: 1 1 auto;
}

.report_list_block {

  .pmd-select.pmd-select-outline.pmd-select-sm .ng-select-container,
  .pmd-input-group-outline .pmd-select.ng-select-multiple.pmd-select-outline .ng-select-container {
    padding-top: calc(0.75rem - 1px);
    padding-bottom: calc(0.75rem - 1px);
    height: calc(2.5rem - 1px);
  }

  .pmd-select.pmd-select-outline.ng-select-opened {
    border: 1px solid $yellow;
  }

  .pmd-textfield-floating-label .pmd-select.pmd-select-sm.ng-select-opened .ng-placeholder {
    font-size: 12px;
    transform: translateY(-20px);
  }

  .pmd-input-group-outline.pmd-input-group-icon .pmd-textfield-outline .form-control[placeholder] {
    padding-top: calc(0.75rem - 1px);
    padding-bottom: calc(0.75rem - 1px);
    height: calc(2.65rem - 1px);
  }

  #select-entity {
    .pmd-select.pmd-select-outline.ng-select-opened {
      .ng-arrow-wrapper {
        top: 4px;
      }
    }
  }

  .khr-entity-dd .pmd-textfield-floating-label .pmd-select.ng-select-opened .ng-placeholder {
    font-size: 12px;
  }
}

.jdropdown-container {
  z-index: 9999 !important;
  top: 0;
  left: 0;
}

.jss_border_main {
  border: 2px solid #000 !important;
}

.jss_content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.jss_content::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: #f5f5f5;
}

.jss_content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.flex-1 {
  flex: 1 1 auto;
}

.search-radius {
  border-radius: 0.5rem !important;
}

.jss {
  width: 100%;
}

.jss_container {
  width: 100%;

  .jdropdown-default {
    .jdropdown-container {
      width: 100%;
    }

    .jdropdown-item {
      overflow: visible;
      white-space: unset;
      padding: 8px 40px 8px 8px;
      border-bottom: 1px solid #d1d1d1;
    }
  }
}

.jss>tbody>tr>td.jss_dropdown {
  text-wrap: wrap;
  overflow: visible;
}

.jss_content {
  width: 100% !important;
  scrollbar-width: auto !important;
  border: 1px solid #ccc;

  table {
    .jss_header {
      padding: 8px;
      color: #000;
      font-weight: bold;
      text-align: center;
    }

    tbody>tr>td.jss_row {
      padding: 10px 12px !important;
    }

    tr.selected {
      td.jss_row {
        background-color: rgba($color: #dcdcdc, $alpha: 1);
      }
    }
  }
}

.product-table,
#totalTableJSS {
  .jss_content {
    max-height: 350px !important;
    height: 100% !important;
  }

  thead>tr>th:first-child,
  tbody>tr>td:first-child {
    border-left: 0;
  }
}

.product-table {
  .jss_content {
    height: 350px !important;
  }
}

#tdsSheet,
#tcsSheet {

  thead>tr>th:first-child,
  tbody>tr>td:first-child {
    border-left: 0;
  }
}

.jss>tbody>tr>td {
  font-size: 12px;
}

.jtabs-content {
  .jss_search_container {
    background-color: #fff;
    align-items: center;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
  }

  .jss_content {
    background-color: #f3f3f3;
  }

  .jss_search,
  .jss_pagination_dropdown {
    border: 1px solid $border-color;
    border-radius: 0.5rem;
    padding: 6px 8px;
  }
}

.jss_pagination {
  flex-wrap: wrap;

  div:last-child {
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
  }

  .jss_page {
    border: 1px solid $border-color;
    color: $secondary;
    border-radius: 4px;
    font-size: 14px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }
  }

  .jss_page_selected {
    border-color: $secondary;
    background-color: $secondary;
    color: $white;
  }
}

.icon-exclamation {
  @include media-breakpoint-down(sm) {
    position: unset !important;
    width: 12px !important;
  }
}

.pmd-sidebar-overlay {
  z-index: 2025;
}

.pmd-sidebar {
  &.pmd-sidebar-open-hover {
    position: fixed;
    left: 0;
    min-width: 210px;
    width: 210px;
    z-index: 2024;

    @media(min-width:1560px) {
      min-width: 240px;
      width: 240px;
    }

    .pmd-sidebar-nav .nav-item .nav-link .flex-grow-1 {
      margin-left: 1rem;
      white-space: nowrap;
      overflow: visible;
      flex: 1;
      opacity: 1;
      flex-grow: 1 !important;
    }
  }
}

.h-fit {
  height: fit-content;
}

#tagspreadsheetpro,
#tab-uploaded {
  .jss_content {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .ng2-pdf-viewer-container {
    overflow: hidden !important;
  }
}

.activity-table {
  @include media-breakpoint-down(sm) {
    border: 0;
  }
}

.activity-log-table {
  max-height: 260px;
}

svg {
  &:focus {
    outline: 0;
  }
}

.sellerName {
  p.card-title {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.statement-col {
  max-width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.issue-due-date {
  p {
    font-weight: normal;
    max-width: 90px;
  }
}

.pmd-tooltip.tooltip {
  z-index: 2024;
}

.border-none {
  border: 0;
}

.checkbox_payment_col {
  width: 50px;
}

.ps-20 {
  padding-left: 20px;
}

.date_and_select_group {
  @include media-breakpoint-up(lg) {
    width: 36%;
  }

  @media (min-width: 1366px) {
    width: 30%;
  }

  @media (min-width: 1800px) {
    width: 22%;
  }
}

.filter_dropdown-dashboard {
  margin-bottom: 1rem;

  .drop_box {
    min-width: 108px;

    @include media-breakpoint-up(lg) {
      min-width: 128px;
    }

    .pmd-select.pmd-select-outline {
      font-size: 13px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      background-color: #f1f1f1;
      padding: 0 6px;

      @include media-breakpoint-up(lg) {
        padding: 0 10px;
        font-size: 0.75rem;
      }

      .ng-select-container {
        padding-top: calc(0.6rem - 1px);
        padding-bottom: calc(0.6rem - 1px);

        @include media-breakpoint-up(md) {
          padding-top: calc(0.465rem - 1px);
          padding-bottom: calc(0.465rem - 1px);
        }

        align-items: center;

        .ng-value-container {
          min-height: unset;
        }
      }

      &.ng-select-opened,
      &.ng-select-focused {
        border-width: 1px !important;
        border-color: #d1d1d1;
      }

      &.ng-select-opened {
        >.ng-select-container .ng-arrow {
          top: 3px;
        }
      }
    }

    .pmd-select .ng-arrow-wrapper .ng-arrow {
      top: 7px;
    }
  }

  .khr-input-group-outline-icon.khr-icon-r .pmd-textfield-outline .form-control {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.ivoice-list-date-select-group {
  width: 315px;

  @include media-breakpoint-up(md) {
    width: 352px;
  }
}

.refresh-btn {
  padding: 0.375rem 0.75rem;
}

.list_view_selection {
  .filter_dropdown-dashboard {
    .khr-input-group-outline-icon.khr-icon-r .pmd-textfield-outline .form-control {
      height: unset;
    }

    .pmd-textfield-floating-label-active {
      .form-control {
        border-width: 1px;
      }
    }
  }
}

.checkbox_payment_col {
  width: 50px;
}

.ps-20 {
  padding-left: 20px;
}

.pdf-image-container {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  display: flex;
  border-radius: 0.375rem;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;

  .ng2-pdf-viewer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: unset !important;
  }
}

.pdf_viewer_block {
  height: 60vh;

  @include media-breakpoint-up(lg) {
    height: 65vh;
  }
}

.fullscreen-section {
  .pdf_viewer_block {
    @include media-breakpoint-up(lg) {
      height: 66vh;
    }
  }
}

.btn-wrap-zoom {
  position: absolute !important;
  top: 0;
  right: 10px;
  z-index: 2;
}

.invoice-review-btn-wrap-zoom,
.btn-wrap-zoom {
  z-index: 2;
  flex: 1;
  display: flex;
  justify-content: end;

  .btn-dark {
    background-color: #fff;
    border-color: $border-color;
    padding: 0.375rem 0.5rem;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;

    .material-icons {
      font-size: 18px;
    }
  }
}

.pmd_sidebar_common {
  background-color: #fff;
  opacity: 0;

  .sidebar-header,
  .sidebar-footer {
    background-color: #faf0cc;
    color: $dark;
    padding: 1rem;
  }

  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    @media (min-width: 1560px) {
      padding: 1.5rem;
    }
  }

  .sidebar-body {
    padding: 0 1rem;
    height: calc(100vh - 158px);
    overflow: auto;
    margin: 1rem 0;

    @media (min-width: 1560px) {
      height: calc(100vh - 180px);
    }
  }

  .ng-select .ng-select-container {
    justify-content: space-between;
    align-items: center;
  }
}

.card-title.h6 {
  font-size: 0.875rem;

  @media (min-width: 1560px) {
    font-size: 1rem;
  }
}

.table-align-middle.table {
  tr {
    th {
      background-color: #fff9e3;
      font-size: 12px;
      color: #212121;
      font-weight: bold;
    }
  }

  td {
    vertical-align: middle;
  }
}

.pmd_sidebar_common {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  overflow: hidden;
  z-index: 2025;

  &.pmd-sidebar-open {
    opacity: 1;
    min-width: 350px;

    @include media-breakpoint-up(md) {
      min-width: 520px;
      width: 520px;
    }
  }
}

.payment_log_sidebar.pmd-sidebar-open,
.invoice_specific_account_sidebar.pmd-sidebar-open {
  @include media-breakpoint-up(xl) {
    min-width: 1200px;
    width: 1200px;
  }
}

.notes_text {
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-responsive-lg {
  @include media-breakpoint-down(xl) {
    overflow-x: auto;
  }
}

.image_zoom_view {
  .invoice-review-btn-wrap-zoom {
    padding-right: 10px;
  }

  .pdf-image-container {
    .ng2-pdf-viewer-container {
      align-items: start;
    }
  }
}

.handwrite_checkbox {
  border: 1px solid #d1d1d1;
  border-radius: .5rem;
  padding: 0.765rem;
}

.khar_filter_dropdown {
  .dropdown-menu {
    width: 252px;
  }

  .check_group {
    max-height: 130px;
    overflow: auto;
  }
}

.table_border {
  @include media-breakpoint-down(sm) {
    border: none;
  }

  .ng-select-container {
    justify-content: space-between;
  }
}

.handwrite_checkbox {
  border: 1px solid #d2d6db;
  border-radius: 0.5rem;
  min-width: 150px;
  padding: 0.6rem;

  @media (min-width: 1560px) {
    padding: 0.675rem;
  }
}

.transition-none {
  @include media-breakpoint-up(lg) {
    transition: none !important;
  }
}

.arrow-back-top:hover,
.arrow-back-top:focus {
  background-color: #f1f1f1;
  box-shadow: none;
}

.arrow-back-top:active {
  border: none;
}

.khr-singal-upload-container {
  .ng2-pdf-viewer-container {
    overflow: hidden !important;
  }
}

.bg-light-success {
  background-color: #ecfdf3 !important;
}

.bg-light-danger {
  background-color: #fff5f6 !important;
}

.btn-light-secondary {
  background-color: #faf0cc;
  border: 1px solid #e6b401;

  &:hover {
    background-color: $secondary;
    color: $dark;
  }
}

.table-padding {

  th,
  td {
    padding: 0.875rem !important;
  }
}

.khr-upload-table-block,
.khar_bank_statement {
  .table-padding {

    th,
    td {
      padding: 1rem;
    }
  }
}

.table {
  .table-header-secondary {
    th {
      background-color: #FFF9E3;
      color: $dark;
    }
  }
}

.no-data-row {
  td {
    flex-basis: 100% !important;
  }
}

.alert-yellow {
  background-color: #fff9ec;
  padding: 0 1rem;
  border: 1px solid #d2d6db;
  height: 48px;

  @media (min-width: 1200px) {
    border: 0;
    border-bottom: 1px solid #d2d6db;
    position: absolute;
    top: 62px;
    left: 0;
    border-radius: 0;
    right: 0;
  }

  @media (min-width: 1560px) {
    top: 72px;
  }
}

.submenu_dropdown_selection {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    padding: 12px 14px;
    background-color: #EEEEEE;
    color: #2C3E50;
    font-weight: 600;
  }
}

.alert-top {
  top: 90px;

  @media (min-width: 1560px) {
    top: 100px;
  }
}

.alert-success {
  background-color: #ECFDF3;
}

.submenu_dropdown_selection {
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    padding: 12px 14px;
    background-color: #EEEEEE;
    color: #2C3E50;
    font-weight: 600;
  }
}