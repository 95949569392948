.pmd-modal {
    .modal-dialog{
        max-width: 600px;
    }
    @include media-breakpoint-up(sm) {
        .modal-lg{
            max-width: 900px;
        }
    }
    .modal-notes{
        &.alert{
            border-radius: 0;
            padding: 0.5rem 1.5rem;
            font-size: 13px;
            @include media-breakpoint-down(md) {
                padding-right: 1rem;
                padding-left: 1rem;
            }
            &.alert-dark{
                background-color: $dark;
                color: $gray-300;
                border: none;
                margin-right: -1px;
            }
        }
    }
    .modal-header{
        &.pmd-modal-border{
            padding-bottom: 1rem;
            @include media-breakpoint-down(md) {
                padding-bottom: 0.5rem;
            }
        }
        // @include media-breakpoint-down(md) {
        //     padding: 1rem 1rem 0 1rem;
        // }
    }
    .modal-footer{
        // @include media-breakpoint-down(md) {
        //     padding: 0 1rem 1rem 1rem;
        // }
    }
    .modal-body{
        @include media-breakpoint-down(md) {
            margin-bottom: 1rem;
            margin-top: 1rem;
            padding: 0 1rem;
        }
    }
    .modal-content{
        @include pmd-box-shadow($btn-hover-shadow);
        .close{
            background-color: transparent;
            border: none;
            outline: none;
            height: 32px;
            width: 32px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba($dark, 0.54);
            &:hover{
                background-color: rgba($dark, 0.16);
                color: $gray-800;
            }
        }
    }
}