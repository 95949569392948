// Card Icon Circle
// .pmd-icon-with-title {
//     transition: $transition-base;

//     .pmd-icon-title {
//         letter-spacing: 0.3px;
//     }

//     .pmd-icon-light {
//         background: theme-color('light');
//         color: pmd-theme-color(secondary, dark);
//         transition: $transition-base;
//     }

//     .pmd-icon-danger {
//         background: $danger-lighter;
//         border-color: $danger-lighter;
//     }
//     .pmd-icon-dark-outline {
//         color: pmd-theme-color(secondary, dark);
//         background-color: transparent;
//         border: solid $border-width pmd-theme-color(secondary, dark);
//     }

//     &:hover,
//     &:active {
//         .pmd-icon-secondary {
//             background: theme-color('secondary');
//             color: pmd-theme-color(primary, light);
//             transition: $transition-base;
//         }

//         .pmd-icon-danger {
//             background: theme-color('danger');
//             color: pmd-theme-color(primary, light);
//             transition: $transition-base;
//         }

//         .pmd-icon-success {
//             background: theme-color('success');
//             color: pmd-theme-color(primary, light);
//             transition: $transition-base;
//         }

//         .pmd-icon-light {
//             background: pmd-theme-color(secondary, dark);
//             color: pmd-theme-color(primary, light);
//             transition: $transition-base;
//         }

//         .pmd-icon-dark-outline {
//             color: pmd-theme-color(primary, light);
//             background-color: pmd-theme-color(primary, dark);
//             border-color: pmd-theme-color(primary, dark);
//         }
//     }
//     &.active {
//         .pmd-icon-dark-outline {
//             color: pmd-theme-color(primary, light);
//             background-color: pmd-theme-color(primary, dark);
//             border-color: pmd-theme-color(primary, dark);
//         }
//     }

//     &:focus {
//         box-shadow: none;
//         .pmd-icon-secondary {
//             box-shadow: $btn-secondary-focus-box-shadow;
//         }

//         .pmd-icon-success {
//             box-shadow: $btn-success-focus-box-shadow;
//         }

//         .pmd-icon-danger {
//             box-shadow: $btn-danger-focus-box-shadow;
//         }

//         .pmd-icon-light {
//             box-shadow: $btn-dark-focus-box-shadow;
//         }
//         .pmd-icon-dark-outline {
//             box-shadow: $btn-dark-focus-box-shadow;
//         }
//     }
// }

// .pmd-icon-rounded {
//     &.pmd-icon-xs {
//         font-size: 20px;
//     }
// }

.pmd-icon-light{
    color: pmd-theme-color(icon, dark);
}

.material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }