/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): tab.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */
.pmd-tab-container.pmd-card {
	.tab-content{
		padding:0 $card-spacer;
		margin-top: $card-spacer;
		margin-bottom: $card-spacer;
	}
}
.pmd-tabs{ 
	position:relative;
	@include media-breakpoint-down(sm) {
		overflow-x: auto; 
		overflow-y: hidden;
	}
	.nav-tabs, .nav-pills {
		.nav-item{
			&.show{
				.nav-link{
					color:$nav-tabs-link-active-color;
					background-color:$nav-tabs-link-active-bg;
				}
			}
		}
		.nav-link{
			padding:$nav-tabs-link-padding-top $nav-tabs-link-padding-x $nav-tabs-link-padding-bottom;
			border:none;
			border-radius:0;
			color:$nav-tabs-link-color; 
			font-size:$nav-tabs-link-font-size;
			font-weight:$nav-tabs-link-font-weight;
			margin-right:0;
			line-height:1;
			text-transform:uppercase;
			display: flex;
			justify-content: center;
			align-items: center;
			&.active{
				color:$nav-tabs-link-active-color;
				cursor:default;
				background-color:$nav-tabs-link-active-bg;
				border:none;
				border-bottom-color: transparent;
				opacity:1;
			}
		}
		border-bottom:none;
	}
	.pmd-tab-active-bar {
		position: absolute;
		bottom: 0;
		width: 25%; 
		height: $nav-tabs-link-active-bar-height; 
		background: $nav-tabs-link-active-bar-bg;
		@include transition($transition-base);
	}
}
.pmd-tabs-dark {
	background-color:$nav-tabs-dark-bg;
	.nav-tabs {
		.nav-link{
			color:$nav-tabs-dark-link-color;
			&.active{
				color:$nav-tabs-dark-link-active-color;
				background-color:$nav-tabs-link-active-bg;
			}
			.dropdown-menu a {
				color: $dropdown-link-color;
			}
		}
	}
	.pmd-tabs-scroll-right,
	.pmd-tabs-scroll-left {
		background-color:$nav-tabs-dark-scroll-arrow-bg;
		color:$nav-tabs-dark-scroll-arrow-color;
	}
}
.pmd-tabs-scroll-container {
	display: block;
	width: 100%;
	position: relative;
	overflow: hidden;
}
.pmd-tabs-scroll-right {
	float: right;
	right:0;
	top:0;
}
.pmd-tabs-scroll-left {
	float: left;
	left:0;
}
.pmd-tabs-scroll-right, 
.pmd-tabs-scroll-left{
	position:absolute; 
	z-index:1;
	text-align: center;
	cursor: pointer;
	display: none;
	white-space: no-wrap;
	vertical-align: middle;
	padding:$nav-tabs-scroll-arrow-padding-top $nav-tabs-scroll-arrow-padding-x $nav-tabs-scroll-arrow-padding-bottom;
	background-color:$nav-tabs-scroll-arrow-bg;
	color:$nav-tabs-scroll-arrow-color;
	line-height: 1;
}
.pmd-tabs-icons-bottom-label{
	.nav-tabs {
		.nav-link{
			flex-direction: column;
			.material-icons{
				padding-bottom:$nav-tabs-icons-padding-bottom;
			}
		}
	}
}