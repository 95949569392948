.pmd-sidebar {
  background-color: #fff;

  &.pmd-sidebar-with-brand {
    margin-top: 47px;

    @include media-breakpoint-up(md) {
      margin-top: 62px;
    }

    @media (min-width:1560px) {
      margin-top: 72px;
    }

    ~ {
      .pmd-content {
        .bottom-action-bar {
          @include transition($sidebar-transition);

          @include media-breakpoint-up(lg) {
            margin-left: 64px;
          }
        }
      }
    }
  }

  &.pmd-sidebar-open {
    box-shadow: none;

    ~.pmd-content .bottom-action-bar {
      margin-left: $sidebar-width;

      @include media-breakpoint-down(sm) {
        margin-left: 0;
      }
    }
  }

  box-shadow: none;

  .pmd-sidebar-footer {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;

    .pmd-site-info,
    .pmd-footer-nav {
      opacity: 0;
    }
  }

  .fils-logo {
    visibility: hidden;
    display: none;
    width: 130px;
    @include transition($sidebar-transition);
    opacity: 0;
  }

  .fils-logo-icon {
    visibility: visible;
    display: block;
    width: auto;
    @include transition($sidebar-transition);
    opacity: 1;
  }

  &.pmd-sidebar-open {
    .fils-logo {
      visibility: visible;
      display: block;
      opacity: 1;
    }

    .fils-logo-icon {
      visibility: hidden;
      display: none;
      opacity: 0;
    }

    .pmd-sidebar-footer {
      padding-left: $space16;
      padding-right: $space16;

      .pmd-site-info,
      .pmd-footer-nav {
        opacity: 1;
      }
    }
  }

  &.pmd-sidebar-open {
    .pf-sidebar-toggle-conteiner {
      padding-left: $space16;
      padding-right: $space16;
      padding-bottom: $space16;
    }
  }

  .pmd-site-info p {
    white-space: nowrap;
  }

  .pmd-footer-nav {
    flex-wrap: nowrap;

    li {
      white-space: nowrap;
    }
  }


  &.pk-right-sidebar {
    background-color: #f1f1f1;
    width: 300px;
    z-index: 1029;
    border-left: solid $border-width $border-color;
    padding: 94px 20px 20px 20px;

    &.pmd-sidebar-right-fixed {
      transform: translate3d(300px, 0, 0);

      &.pmd-sidebar-open {
        transform: translate3d(0, 0, 0);
      }
    }
  }
}