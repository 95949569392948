/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): card.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

// Social icon 
// .pmd-social-icon {
// 	margin-top: $social-icon-top-margin;
// 	a {
// 		margin: 0 $social-icon-margin-x;
// 		display: inline-flex;
// 		justify-content: center;
// 		svg {
// 			fill: $social-svg-icon-color;
// 			width: $social-svg-icon-width;
// 		}
// 		&:hover svg {
// 			fill: $social-svg-icon-hover-color;
// 		}
// 	}
// }

/* Card info css */
// .pmd-card-info {
// 	font-size:$card-info-font-size;
// 	color: $card-info-color;
// 	padding:$card-info-padding-y 0;
// 	border-top:$card-info-border-width solid $card-info-border-color;
// 	border-bottom:$card-info-border-width solid $card-info-border-color;
// }

// Card project progress css 
// .pmd-list-progress {
// 	.list-group-item { 
// 		padding-left: $progress-list-group-item-padding-left;
// 		width:100%;
// 		background-color: $progress-bar-group-item-bg;
// 		&:before { 
// 			content:""; 
// 			width:$progress-bar-width; 
// 			height:100%; 
// 			background:$progress-bar-bg; 
// 			left:0; 
// 			position:absolute;
// 			left:30px; 
// 			top:0;
// 			z-index:9;
// 		}
// 		&:after{
// 			content:"";
// 			position:absolute;
// 			left:25px; 
// 			top:0; 
// 			bottom:0; 
// 			margin:auto; 
// 			width:12px; 
// 			height:12px; 
// 			border-radius:50%; 
// 			background:#fff;
// 		}
// 		&.list-group-item-success:before,
// 		&.list-group-item-success:after {
// 			background-color:#67b951;
// 		}
// 		&.list-group-item-danger:before,
// 		&.list-group-item-danger:after {
// 			background-color:#df6c6c;
// 		}
// 	}
// }

// Card overlay css
// .pmd-card-overlay {
// 	background-color: #041a34; 
// 	overflow:hidden;
// 	.card-title{ 
// 		position: absolute; 
// 		color: #fff; 
// 		top: 0; 
// 		left: 0; 
// 		right: 0;
// 		transform: translateY(47%);
// 		-webkit-transform: translateY(47%);
// 		-moz-transform: translateY(47%); 
// 		bottom: 0;
// 		text-align: center;
// 		z-index: 2;
// 		transition: all ease-in-out 0.3s;
// 		-ms-transition: all ease-in-out 0.3s;
// 		-webkit-transition: all ease-in-out 0.3s;
// 		font-size: 24px;
// 	}
// 	img {
// 		opacity: 0.64;
// 		height: 350px;
// 		display: block;
// 		transition: all ease-in-out 0.3s; 
// 		-ms-transition: all ease-in-out 0.3s;
// 		-webkit-transition: all ease-in-out 0.3s;
// 		min-width: 100%;
// 	}
// 	&:hover{
// 		.card-title{
// 			transform: translateY(20%);
// 			-webkit-transform: translateY(20%);
// 			-moz-transform: translateY(20%);
// 		}
// 		img{
// 			opacity: 0.16;
// 			transform: scale(1.1);
// 		}
// 	}
// }
// .pmd-view-details{
// 	padding: 0 32px; 
// 	text-align: center; 
// 	color: #fff; 
// 	opacity:0;
// 	font-size: 14px;
// 	position: absolute;
// 	bottom: 0;
// 	z-index: 100; 
// 	left: 0; 
// 	right: 0; 
// 	-webkit-transform: translate(0,50px);
// 	transform: translate(0,50px);
//     -webkit-transition: all ease-in-out 0.3s; 
// 	transition: all ease-in-out 0.3s; 
// 	overflow: visible; 
// 	margin: 0 auto; 
// 	top: 50%;
// 	&:before {
// 		content: attr(data-title);
// 		position: absolute;
// 		bottom: -28px;
// 		-webkit-transform: translate(-17px, 0);
// 		transform: translate(-17px, 0);
// 		color: #fff;
// 	}
// }

// Card hover css 
// .pmd-card-overlay:hover .pmd-view-details, 
// .pmd-card-overlay .card-title:hover + .pmd-view-details{
// 	-webkit-transform: translate(0,-40%);
// 	-moz-transform: translate(0,-40%);   
// 	transform: translate(0,-40%);
// 	opacity: 1;
// }

// icon wrap
.icon-wrap {
	height: 100px;
	width: 100px;
	background: #fff;
	border-radius: 50%;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
	margin: auto;
	line-height: 100px;

	svg {
		display: inline-block;
		vertical-align: middle;
		height: 60px;
	}
}

// Card header with backdround
.pmd-card {
	div[class^="card-header bg-"] {
		padding-bottom: $card-spacer;

		@include media-breakpoint-down(sm) {
			padding-bottom: $card-spacer-sm;
		}
	}

	div[class^="card-footer bg-"] {
		padding-top: $card-spacer;

		@include media-breakpoint-down(sm) {
			padding-top: $card-spacer-sm;
		}
	}

	.list-group-item {
		background-color: transparent;
	}
}

// Card-inverse
.pmd-card-dark {
	color: $white;

	a,
	.card-title,
	.material-icons,
	.pmd-list-title {
		color: $white;
	}

	.card-subtitle,
	.pmd-list-subtitle {
		color: pmd-theme-color(secondary, light);
	}

	svg {

		path,
		rect,
		circle,
		polygon {
			fill: $white;
		}
	}

	.pmd-btn-flat {
		&:hover {
			background-color: rgba(229, 229, 229, 0.2);
		}
	}

	.pmd-inline-list-divider {
		li {
			border-color: pmd-theme-color(hint, light);
		}
	}

	hr {
		border-color: pmd-theme-color(hint, light);
	}
}

// SVG Fill
.fill-primary {
	svg {

		path,
		rect,
		circle,
		polygon {
			fill: $primary;
		}
	}
}

.fill-secondary {
	svg {

		path,
		rect,
		circle,
		polygon {
			fill: $secondary;
		}
	}
}

.fill-secondary {
	svg {

		path,
		rect,
		circle,
		polygon {
			fill: $white;
		}
	}
}

// Card title border css
.pmd-title-separator,
.pmd-title-separator-center,
.pmd-title-separator-right {
	&:after {
		content: "";
		display: block;
		width: $card-title-separator-width;
		height: $card-title-separator-height;
		border-radius: $card-title-separator-border-radius;
		background-color: $card-title-separator-background-color;
		margin: $pmd-title-separator-margin-top 0 $pmd-title-separator-margin-bottom;
	}
}

.pmd-title-separator-center {
	&:after {
		margin-left: auto;
		margin-right: auto;
	}
}

.pmd-title-separator-right {
	&:after {
		margin-left: auto;
	}
}


.pmd-card-media {
	position: relative;
}

// Card Fill Buttons
.pmd-btn-fill {
	display: flex;

	.btn {
		flex: 1;
	}

	&.pmd-item-justify-border {
		.btn {
			border-bottom: 0;
			margin-left: -1px;

			&:first-child {
				border-left: 0;
				margin-left: 0;
			}

			&:last-child {
				border-right: 0;
			}
		}
	}
}

// Card Fill List
.pmd-list-fill {
	display: flex;

	&.list-inline {
		margin-left: 0;
		margin-right: 0;

		.list-inline-item {
			text-align: center;
			padding: $pmd-list-fill-padding-x $pmd-list-fill-padding-y;
		}
	}

	.list-inline-item {
		flex: 1;
	}
}

// Floating Icon
.pmd-floating-icon-wrapper {
	position: relative;
	display: inline-block;

	.pmd-floating-icon-br,
	.pmd-floating-icon-tr,
	.pmd-floating-icon-tl,
	.pmd-floating-icon-bl {
		background-color: $pmd-floating-icon-bg;
		border-radius: 100%;
		color: $white;
		height: $pmd-floating-icon-height;
		width: $pmd-floating-icon-width;
		padding: $pmd-floating-icon-padding;
		display: flex;
		align-items: center;
		justify-content: center;
		font-style: normal;
		font-size: $pmd-floating-icon-font-size;
		position: absolute;

		&.material-icons {
			font-size: $pmd-floating-icon-size;
		}
	}

	.pmd-floating-icon-br {
		bottom: $pmd-floating-icon-bottom;
		right: $pmd-floating-icon-right;
	}

	.pmd-floating-icon-bl {
		bottom: $pmd-floating-icon-bottom;
		left: $pmd-floating-icon-left;
	}

	.pmd-floating-icon-tl {
		top: $pmd-floating-icon-top;
		left: $pmd-floating-icon-left;
	}

	.pmd-floating-icon-tr {
		right: $pmd-floating-icon-right;
		top: $pmd-floating-icon-top;
	}
}

// Floating Content
.pmd-floating-content-wrapper {
	position: relative;

	.pmd-floating-content-br,
	.pmd-floating-content-bl,
	.pmd-floating-content-bc {
		position: absolute;
		bottom: 0;
		transform: translateY(50%);
	}

	.pmd-floating-content-bl {
		left: $pmd-floating-content-spacer;
	}

	.pmd-floating-content-br {
		right: $pmd-floating-content-spacer;
	}

	.pmd-floating-content-bc {
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}

	.pmd-floating-content-tr,
	.pmd-floating-content-tl,
	.pmd-floating-content-tc {
		position: absolute;
		top: 0;
		transform: translateY(-50%);
	}

	.pmd-floating-content-tl {
		left: $pmd-floating-content-spacer;
	}

	.pmd-floating-content-tr {
		right: $pmd-floating-content-spacer;
	}

	.pmd-floating-content-tc {
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
	}

	&.card-body {

		.pmd-floating-content-br,
		.pmd-floating-content-bl,
		.pmd-floating-content-bc {
			bottom: -$pmd-floating-content-spacer;
		}

		.pmd-floating-content-tr,
		.pmd-floating-content-tl,
		.pmd-floating-content-tc {
			top: -$pmd-floating-content-spacer;
		}
	}
}

// pmd-col-listing
.pmd-col-listing {
	position: relative;

	&:before,
	&:after {
		content: "";
		position: absolute;
		background-color: $white;
		z-index: 1;
		bottom: 0;
		right: 0;
	}

	&:before {
		height: 2px;
		left: 0;
	}

	&:after {
		width: 2px;
		top: 0;
	}

	.pmd-col-listing-item {
		border-bottom: 1px solid pmd-theme-color(dividers, dark);
		border-right: 1px solid pmd-theme-color(dividers, dark);
		padding: 1.25rem;
		display: block;
		color: pmd-theme-color(primary, dark);
		position: relative;
		@include transition($transition-base);

		.material-icons {
			color: $secondary;
		}

		.card-title {
			margin-top: 0.5rem;
		}
	}

	&.pmd-col-listing-hover {
		.pmd-col-listing-item {
			&:hover {
				background-color: $secondary;
				color: $white;
				@include box-shadow-depth(2);
				z-index: 2;
				text-decoration: none;

				.material-icons {
					color: $white;
				}
			}
		}
	}
}