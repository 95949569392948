.pmd-filter-row {
  background-color: $card-bg;

  &.multi-filter-row {
    background-color: transparent;
    box-shadow: none !important;

    .pmd-input-group-outline {
      .input-group-text {
        // height: 46px;
        z-index: 1;
        padding: 10px;
      }

      .pmd-filter-col {
        @media (max-width: 1199px) {
          position: relative;
          display: block;
          width: 100%;
          padding: 0;
          margin-top: 20px;
          border-left: 0 !important;
        }
      }

      .pmd-select {
        .ng-select-container {
          width: 40%;
          overflow: hidden;

          @media (max-width: 1280px) {
            width: 30%;
          }

          @media (max-width: 1199px) {
            width: 95%;
          }
        }

        @media (max-width: 1199px) {
          background-color: #fff;
        }

        &.status-dropdown-outer {
          padding: 0 1rem !important;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          min-width: 120px;
          border: 0 !important;
          border-left: rgba(28, 36, 58, 0.1) solid 1px !important;
          margin-right: 1px;
          background-color: transparent;

          &.ng-select-opened {
            .ng-arrow {
              top: 4px !important;
            }
          }

          .ng-arrow-wrapper {
            display: block;

            .ng-arrow {
              top: 7px;
            }
          }

          @media (max-width: 1199px) {
            height: 49px;
            border-left: 0 !important;
            border-radius: 0.5rem !important;
            border: 1px solid #b0b0b0 !important;
          }

          .ng-select-container {
            width: 100% !important;
            padding-right: 0;
          }

          .ng-dropdown-panel {
            width: 160px;
            right: 0;
            left: auto;

            @media (max-width: 1199px) {
              width: 100%;
            }

            .ng-option {
              padding-top: 12px;
              padding-bottom: 12px;
            }
          }
        }
      }
    }
  }

  .ts-search {
    .pmd-select {
      background-color: #fff;
    }
  }

  @include box-shadow($card-shadow);
  border-radius: $border-radius;
  position: relative;

  .ts-search {
    height: 100%;

    .form-control {
      width: 100%;
      height: 100%;

      @media (max-width: 1559px) {
        padding: calc(.65rem - 1px) calc(1rem - 1px);
      }
    }
  }

  .pmd-select {
    // padding-left: 52px;
    // border: solid #b3b3b3 $border-width;
    border-radius: $textfield-outline-border-radius !important;

    @include media-breakpoint-down(sm) {
      // padding-left: 48px;
    }

    .ng-select-container {
      @include media-breakpoint-down(sm) {
        width: 100%;
        // padding-right: 40px;
      }
    }

    &.ng-select-focused,
    &.ng-select-opened {
      // padding-left: 51px;
    }

    .ng-arrow-wrapper {
      display: none;
    }

    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          padding-top: $space16;
          padding-bottom: $space16;
        }
      }
    }
  }

  .input-group-text {
    padding: $space12;

    .md-dark {
      color: pmd-theme-color(icon, dark);

      svg {
        fill: pmd-theme-color(icon, dark);
      }
    }
  }

  .pmd-option-title {
    text-decoration: underline;
  }

  .ng-dropdown-panel {
    border: $border-width solid #b0b0b0;
    margin-top: 2px;

    .ng-dropdown-panel-items {
      max-height: 260px;
    }
  }
}

.pmd-filter-col {
  border-left: $border-color solid $border-width;
  position: absolute;
  display: flex;
  align-items: center;
  right: 0;
  padding: 1rem;
  height: 100%;
  border-radius: 0 !important;

  .pmd-radio {
    .form-check-label {
      padding-left: 30px;
      line-height: 1;
    }
  }
}