/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): dropdown.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

// Dropdown menu
.pmd-dropdown {
	&.show>.btn-primary.dropdown-toggle:focus {
		box-shadow: none;
	}

	.dropdown-menu {
		border: none;
		border-radius: 0;
		box-shadow: none;
		clip: rect(0 0 0 0);
		background-color: transparent;
		padding: $dropdown-padding-y 0;
		opacity: 0;
		margin: 0;
		@include pmd-box-shadow($dropdown-box-shadow);
		@include transition($transition-base);

		// proper js fixing
		.dropdown-item {
			padding: 0.5rem $dropdown-item-padding-x;
			font-size: 0.75rem;
			color: $dropdown-link-color;
			line-height: $dropdown-link-line-height;

			@include hover-focus {
				color: $dropdown-link-hover-color;
				background-color: $dropdown-link-hover-bg;
			}

			@media(min-width:1560px) {
				padding: $dropdown-item-padding-y $dropdown-item-padding-x;
				font-size: 0.875rem;
			}

			&.disabled,
			&:disabled {
				color: $dropdown-link-disabled-color;
				background-color: transparent;
			}

			&.active,
			&:active {
				background-color: $dropdown-link-hover-bg;
			}
		}

		&::before {
			border-radius: 0;
			background-color: $dropdown-bg;
			border: none;
			@include transform(0);
			@include transform-origin(left top);
			@include transition($dropdown-transition);
			position: absolute;
			content: "";
			height: 100%;
			width: 100%;
			z-index: -1;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}

		&[x-placement="top-start"] {
			&::before {
				@include transform-origin(left bottom);
			}
		}

		&[x-placement="top-end"] {
			&::before {
				@include transform-origin(right bottom);
			}
		}

		&[x-placement="bottom-end"] {
			&::before {
				@include transform-origin(right top);
			}
		}
	}

	&.show {
		>.dropdown-menu {
			opacity: 1;

			&::before {
				@include transform(1);
			}
		}
	}

	&.dropup {
		.dropdown-menu {
			top: 0;
			transform: translate3d(0px, -100%, 0px);
		}

		.caret {
			border-bottom: 4px solid;
		}
	}

	&.dropleft {
		.dropdown-menu {
			top: 0;
			transform: translate3d(-100%, 0px, 0px);
		}
	}

	.dropdown-menu-right {
		clip: rect(0 0 0 0);
	}
}

// Dropdown header
.dropdown-header {
	padding: $dropdown-header-padding-y $dropdown-header-padding-x;
	margin-top: 8px;
	color: $dropdown-header-color;
}

/* .navbar-fixed-bottom {
	.pmd-dropdown{
		&.dropdown {
			.caret {
				border-bottom: 4px solid;
			}
		}
	}
}*/

// Dropdown in sidebar
/* .pmd-sidebar-open{
	&.pmd-navbar-sidebar{
		.dropdown-menu{ 
			background-color:transparent; 
			top:0; 
			border: none; 
			border-radius: 0; 
			box-shadow:none; 
			opacity:1; 
			&{
				.show { display: block;}
			}
		}
	}
}
@include media-breakpoint-down(sm) {
	.pmd-sidebar-dropdown {
		.dropdown-menu{
			opacity:1;
		}
	}
} */