.language-dropdown-form {
    .language-dropdown {
        border-radius: 30px !important;
        background-color: rgba($purple, 0.30);
        cursor: pointer;
        @include transition($btn-transition);
        .ng-select-container{
            &:hover{
                .ng-value-label{
                    color: $primary;
                }
            }
            .ng-value-label{
                font-weight: $font-weight-medium;
                @include transition($btn-transition);
            }
        }
        &:hover{
            color: $primary;
            background-color: $purple;
            .material-icons-round{
                color: $primary;
            }
            .ng-value-label{
                color: $primary;
            }
        }
        &.input-group.pmd-input-group-icon {
            .pmd-select{
                border: none;
                &.ng-select-focused, &:hover{                    
                    // padding-left: calc(0.75rem - 1px);
                    // padding-right: calc(0.75rem - 1px);
                }
                .ng-select-container{
                    height: 40px;
                    padding: 0;
                    line-height: 40px;
                    .ng-input{
                        top: 0;
                        left: 0;
                        height: 40px;
                    }
                }
                .ng-arrow-wrapper{
                    display: none;
                }
            }
            .input-group-text{
                z-index: 1;
            }
        }
        
    }
}