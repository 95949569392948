/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): badge.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

// Colors
@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}
@each $color, $value in $theme-colors {
  @include bg-variant(".bg-#{$color}", $value);
}
@each $color, $value in $theme-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}