.pmd-accordion{
    &.outline-bottom{
        .accordion-item{
            border: none;
            padding: 0 0.5rem;
            &::after{
                background-color: $border-color;
                height: 1px;
                width: calc(100% - 1rem);
                margin-left: 0.5rem;
                content: "";
                display: block;
            }
            &:last-child{
                border-radius: 0;
                border-bottom: none;
                box-shadow: none;
                &::after{
                    display: none;
                }
            }
            .pmd-accordion-header{
                .accordion-button{
                    font-weight: $font-weight-semibold;
                    background-color: transparent;
                    padding-left: 0;
                    padding-right: 0;
                    box-shadow: none;
                    border-radius: 0;
                    &:focus{
                        box-shadow: none;
                    }
                    &::before{
                        content: "";
                        height: 6px;
                        min-width: 6px;
                        align-self: start;
                        margin-top: 10px;
                        border-radius: 50%;
                        display: inline-flex;
                        margin-right: 0.5rem;
                        margin-left: 0.25rem;
                        background-color: theme-color(primarylight);
                    }
                    .pmd-accordion-arrow{
                        margin-top: 0;
                        margin-right: .25rem;
                        align-self: start;
                    }
                }
            }
            
            &.active{
                background-color: $secondary-lighter;
                border-radius: $border-radius;
                margin: -1px 0;
                .pmd-accordion-header{
                    .accordion-button{
                        color: $primary;
                        &::before{
                            background-color: $secondary;
                        }
                    }
                }
            }
        }
        .accordion-body{
            padding-top: 0;
            padding-right: 0;
        }
    }
}