// Propeller variable and functions
@import "./app/shared/propeller/assets/scss/functions";
@import "./app/shared/propeller/assets/scss/mixins";

// Project styles
@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap");

// Add your custom variables scss here
@import "./assets/scss/variables";

// Propeller styles
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// @import url('https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css');

// Resizeable css
@import "../node_modules/angular2-draggable/css/resizable.min.css";

@import "./app/shared/propeller/assets/scss/propeller.scss";
@import "./app/shared/propeller/datepicker/pmd-datepicker.scss";
@import "./app/shared/propeller/assets/scss/vendor/ng-select/pmd-ng-select";
@import "./app/shared/propeller/assets/scss/vendor/toaster/toaster.scss";

// Owl Carousel
// @import '../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
// @import '../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import "./assets/scss/vendor/owl/owl.carousel.scss";
@import "./assets/scss/vendor/owl/owl.theme.default.scss";

// Project styles
@import "./assets/scss/base/reboot";
@import "./assets/scss/components/components";
@import "./assets/scss/templates/templates";

