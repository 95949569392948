/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): pagination.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

.pmd-pagination {
	.page-item {
		display: flex;
		.page-link {
			border: 0;
			display: inline-flex;
			vertical-align: middle;
			justify-content: center;
			align-items: center;
			margin: 0 $pagination-link-margin-x;
			font-size: $pagination-font-size;
			font-weight: $pagination-font-weight;
			border-radius: $pagination-link-border-radius;
			line-height: $pagination-link-line-height;
			color: $pagination-link-color;
			height: $pagination-height;
			width: $pagination-width;
			padding: $pagination-link-padding-y $pagination-link-padding-x;
			&:focus {
				box-shadow: none;
				text-decoration: none;
			}
			&:active:focus {
				text-decoration: none;
			}
			&:hover {
				background-color: $pagination-item-hover-bg;
				color: $pagination-item-hover-color;
			}
			&.disabled{
				opacity: 0.6;
			}
		}
		&.pagination-arrow{
			.page-link {
				background-color: $pagination-arrow-bg;
				&:hover {
					background-color: $pagination-arrow-hover-bg;
					color: $pagination-arrow-hover-color;
				}
			}
		}
		&.active {
			.page-link {
				background-color: $pagination-active-item-bg;
				color: $pagination-active-link-color;
			}
		}
		&:first-child , &:last-child {
			.page-link {
				border-radius: $pagination-link-border-radius;
			}
		}
	}
	&.pmd-pagination-outline {
		.page-item {
			.page-link {
				border: $pagination-outline-border-width solid $pagination-outline-border-color;
				&:hover {
					border-color: $pagination-outline-hover-border-color;
				}
			}
			&.active {
				.page-link {
					border-color: $pagination-outline-active-border-color;
				}
			}
		}
	}
	&.pagination-lg {
		.page-item {
			.page-link {
				@include button-size($pagination-padding-y-lg, $pagination-padding-x-lg, $pagination-font-size-lg, $pagination-line-height-lg, $pagination-border-radius-lg);
				// i {
				// 	line-height: $pagination-line-height-lg;
				// 	font-size: $pagination-font-size-lg;
				// }
			}
			&:first-child , &:last-child {
				.page-link {
					border-radius: $pagination-border-radius-lg;
				}
			}
		}
	}
	&.pagination-sm {
		.page-item {
			.page-link {
				@include button-size($pagination-padding-y-sm, $pagination-padding-x-sm, $pagination-font-size-sm, $pagination-line-height-sm, $pagination-border-radius-sm);
				// i {
				// 	line-height: $pagination-line-height-sm;
				// 	font-size: $pagination-font-size-sm;
				// }
			}
			&:first-child , &:last-child {
				.page-link {
					border-radius: $pagination-border-radius-sm;
				}
			}
		}
	}
}

