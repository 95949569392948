@mixin form-validation-state($state, $color) {
	.#{$state}-feedback {
		color: $color;
	}
	.#{$state}-tooltip {
		color: #fff;
		background-color: rgba($color, .8);
	}
	.pmd-textfield {
		.form-control,
		.custom-select {
			.was-validated &:#{$state},
			&.is-#{$state} {
				border-color: $color;
				&:focus {
					border-color: $color;
					box-shadow:none;
				}
				~ .pmd-textfield-focused {
					background-color:$color;
				}	
			}
		}
		label{
			&.is-#{$state} {
				color:$color;
			}
		}
	}

  .form-check-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .form-check-label {
        color: $color;
      }
    }
  }

.pmd-checkbox,
.pmd-radio{
	.custom-control-input {
	.was-validated &:#{$state},
		&.is-#{$state} {
			~ .custom-control-label {
				color: $color;
				&::after {
					border-color:$color;
				}
			}
		}
	}
}

.pmd-radio {
	.custom-control-input {
	.was-validated &:#{$state},
		&.is-#{$state} {
			~ .custom-control-label {
				&::before {
					background:$color;
					border-color:$color;
				}
			}
		}
	}
}

.pmd-checkbox {
	.custom-control-input {
	.was-validated &:#{$state},
		&.is-#{$state} {
			~ .custom-control-label {
				&::before {
					background-color:transparent;
					box-shadow: none;
					border-color: $checkbox-check-color;
				}
				&::after {
					background-color:$color;
				}
			}
		}
	}
}

  // custom file
  .custom-file-input {
    .was-validated &:#{$state},
    &.is-#{$state} {
      ~ .custom-file-label {
        border-color: $color;
        &::before { border-color: inherit; }
      }
      &:focus {
        ~ .custom-file-label {
          box-shadow:none;
        }
      }
    }
  }
}