.pmd-paymnent-tab-group {
  width: 100%;

  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - 170px);
    display: flex;
  }



  .pmd-tabs {
    overflow: hidden;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid $border-color;
    margin-bottom: 1rem;
    min-width: 210px;

    @include media-breakpoint-up(lg) {
      position: sticky;
      margin-bottom: 0;
      top: 66px;
      z-index: 1024;
      height: calc(100vh - 110px);
      margin-right: 1rem;
    }

    @include media-breakpoint-up(xl) {
      min-width: 240px;
    }

    @include media-breakpoint-up(xxl) {
      min-width: 280px;
    }

    .nav {
      @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
      }

      .nav-item {
        position: relative;

        @include media-breakpoint-down(sm) {
          // width: 100%;
        }

        &.active {
          background-color: rgba(255, 251, 240, 1);
          font-weight: 600;

          .nav-link {
            color: $secondary;
          }

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            border-radius: 0 0 10px 10px;
            background-color: $secondary;

            @include media-breakpoint-up(lg) {
              left: 0;
              top: 0;
              bottom: unset;
              width: 4px;
              height: 100%;
            }
          }

          .nav-img {
            filter: brightness(0) saturate(100%) invert(83%) sepia(55%) saturate(3505%) hue-rotate(4deg) brightness(95%) contrast(99%);
          }
        }
      }

      .nav-link {
        margin: 0;
        width: 100%;
        justify-content: start;
        padding: 1.25rem 1.5rem;
        color: #646464;

        @include media-breakpoint-down(sm) {
          padding: 0.75rem 15px;

          img {
            display: none;
          }
        }

        span {
          font-weight: 500;
        }
      }
    }
  }

  .tab-content {
    width: 100%;
    background-color: #fff;
    border: 1px solid $border-color;
    border-radius: 10px;
    min-height: 100%;
    padding: 24px;
  }

  .khar-pay-bills-blocks {
    .khar-categories-list {
      .khar-lists {
        margin-top: 2rem;
        display: flex;
        flex-wrap: wrap;
        column-gap: 1rem;

        .list-grid {
          width: 130px;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: $dark;
          padding: 0 16px;
          margin-bottom: 1.5rem;
          text-align: center;

          .list-grid-img {
            width: 100px;
            height: 100px;
            border-radius: 999px;
            background-color: #fff9e3;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
          }

          .list-grid-icon-img {
            width: 100px;
            height: 125px;
            border-radius: 10px;
            background-color: #fff9e3;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .ng-select-container {
    padding-top: calc(0.65rem - 1px);
    padding-bottom: calc(0.65rem - 1px);
  }

  .transction-card {
    background-color: #fff9e3;
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    @include media-breakpoint-up(sm) {
      min-width: 72px;
    }

    &.transaction-card-empty {
      @include media-breakpoint-down(sm) {
        padding: 18px 4px;
      }
    }

    @include media-breakpoint-down(sm) {
      width: unset;
      height: unset;
      padding: 12px;
      min-width: unset;

      img {
        width: 30px;
      }
    }

  }
}

.vh-50 {
  height: 50vh;
}

.payment-status-block {
  width: 50% !important;

  @include media-breakpoint-up(md) {
    max-width: 596px;
  }

  .khar-pay-bills-success-block {
    color: #646464;

    .payment-details-block {
      border: 1px solid $border-color;
      border-radius: 8px;
      padding: 1rem;
    }

    .khar-stepper {
      display: grid;
      gap: 20px;
      counter-reset: step;
      padding: 0;
      overflow: hidden;

      li {
        display: flex;
        position: relative;

        &::before {
          content: counter(step) " ";
          counter-increment: step;
          display: grid;
          place-content: center;
          height: 26px;
          min-width: 26px;
          background: $secondary;
          color: #4d3d00;
          font-size: 14px;
          font-weight: 600;
          border-radius: 50%;
          z-index: 1;
          margin-right: 1rem;
        }

        &::after {
          content: "";
          position: absolute;
          inset: auto auto 100% 12px;
          height: 100%;
          border: 1px dashed #d2d6db;
        }
      }
    }
  }

  .accordion-wallet {
    .accordion-button {
      padding: 1rem !important;

      &:not(.collapsed) {
        color: $dark !important;
      }
    }
  }
}

.khar-tc-details-block {
  .details-block-light {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 1.5rem;
  }

  .details-stepper {
    padding: 1.5rem 1rem;
    border: 1px solid #d2d6db;
    border-radius: 8px;
    margin-top: 1rem;

    .Step_details {
      position: relative;
      padding-left: 45px;
      list-style: none;
      color: #212121;
      margin-bottom: 0;
    }

    .Step_details::before {
      display: inline-block;
      content: "";
      position: absolute;
      top: 0;
      left: 21px;
      width: 10px;
      height: 100%;
      border-left: 1px dashed #ccc;
    }

    .Step_details-item {
      position: relative;
      counter-increment: list;
      font-weight: 500;
    }

    .Step_details-item:not(:last-child) {
      padding-bottom: 28px;
    }

    .Step_details-item::before {
      display: inline-block;
      content: "";
      position: absolute;
      left: -24px;
      height: 100%;
      width: 10px;
    }

    .Step_details-item::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: -4px;
      left: -37px;
      width: 28px;
      height: 28px;
      border: 1px dashed #ccc;
      border-radius: 50%;
      background-color: #fff;
    }

    .Step_details-item.is-done::before {
      border-left: 2px solid #039855;
    }

    .Step_details-item.is-done::after {
      content: "\e5ca";
      font-family: "Material Symbols Outlined";
      font-size: 16px;
      color: #fff;
      text-align: center;
      font-size: 20px;
      border: 2px solid #039855;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #039855;
    }

    .Step_details-item.current::after {
      font-family: "Material Symbols Outlined";
      content: "\e88e";
      font-size: 20px;
      color: #fff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #e6b401;
      background-color: #e6b401;
    }
  }

  .message-ques {
    background-color: #fff9e3;
    width: 48px;
    height: 48px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.khar-pay-bills-category-block {
  .search_icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }

  .card-light-ye {
    background-color: #fffcf5;
    max-width: 1024px;
    border-radius: 10px;
    padding: 1.25rem;

    @include media-breakpoint-down(sm) {
      background-color: transparent;
      padding: 0;
    }

    .pmd-accordion {
      .accordion-item {
        .accordion-button {
          padding: 0.875rem 1rem !important;
          border-color: #d2d6db;
          border-radius: 8px;
          line-height: normal;

          &:after {
            background-color: #f2f2f2;
            border-radius: 3px;
            background-size: 1rem;
            background-position: center;
          }

          &:not(.collapsed) {
            border-bottom: 0;
            color: #212121;
          }
        }
      }
    }
  }

  .total-payable-amount {
    border: 1px solid #d2d6db;
    border-radius: 10px;
    padding: 1.25rem;

    @media (min-width: 768px) {
      max-width: 484px;
    }
  }

  .content-min-w {
    @media (min-width: 768px) {
      max-width: 484px;
    }
  }
}

.footer-payment {
  position: fixed;
  width: calc(100% - 88px);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff9e3;
  border-top: 1px solid #d2d6db;
  padding: 1.5rem;
  z-index: 1025;
  margin-left: 88px;

  @media (max-width: 992px) {
    width: 100%;
    margin-left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.pmd-body-open .footer-payment {
  margin-left: 220px;
  width: calc(100% - 220px);
}

.bh-logo-sm {
  @media (max-width: 992px) {
    position: absolute;
    right: 20px;
    top: 20px;
    max-width: 70px;
  }
}