/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): accordion.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

.pmd-accordion {
	.accordion-item {
		// position: relative;
		margin: $accordion-panel-margin-y $accordion-panel-margin-x;
		// border: none;
		// @include border-radius(0px);
		@include transition($transition-base);

		.accordion-header {

			// padding: 0;
			// background-color: transparent;
			// border-color: transparent;
			.accordion-button {
				// display: flex;
				padding: $accordion-heading-padding-y $accordion-heading-padding-x;
				line-height: $accordion-heading-line-height;
				font-size: 0.75rem;
				color: $accordion-heading-color;
				// text-decoration:none;
				font-weight: $accordion-heading-font-weight;

				// width: 100%;
				// text-align: left;
				// white-space:inherit;
				// @include hover-focus {
				// 	color: inherit;
				// }
				@media (min-width:1560px) {
					font-size: $accordion-heading-font-size;
				}
			}

			&.pmd-accordion-header {
				.accordion-button {
					&::after {
						display: none;
					}
				}
			}
		}

		&.active {
			.material-icons {
				&.pmd-accordion-arrow {
					@include transform-rotate(180deg);
				}
			}
		}
	}
}

.pmd-accordion:not(.pmd-accordion-outlined) {
	.accordion-item {
		@include pmd-box-shadow($accordion-box-shadow);
	}
}

.pmd-accordion:not(.pmd-accordion-nospace) {
	.accordion-item {
		border-radius: var(--bs-accordion-border-radius);

		&:not(:first-of-type) {
			border-top: solid $border-width $border-color;
		}

		.accordion-header {
			.accordion-button {
				border-radius: var(--bs-accordion-border-radius);
			}
		}
	}
}

// Accordion Nospace
.pmd-accordion-nospace {
	.accordion-item {
		margin: 0;
	}
}

// Accordion Actie Arrow
.pmd-accordion-arrow {
	display: flex;
	margin-left: auto;
}