.pmd-checkbox,
.pmd-radio {
  .form-check-label {
    padding-left: 1.75rem;
    font-weight: $font-weight-medium;

    &::after {
      background-color: #fff;
    }
  }

  .form-check-input {
    ~.form-check-label {
      .text-muted {
        @include media-breakpoint-down(md) {
          display: none;
          max-height: 0;
          height: 0;
          transform: scaleY(0);
          transform-origin: top;
          transition: transform 0.26s ease;
        }
      }
    }

    &:checked {
      ~.form-check-label {
        color: theme-color("black");
        font-weight: 600;

        &::after {
          border-color: theme-color("secondary");
          // box-shadow: 0 0 0 0.25rem rgba(theme-color("secondary"), 0.25);
        }

        strong,
        span {
          color: theme-color("secondary");
        }

        .text-muted {
          @include media-breakpoint-down(md) {
            display: block;
            max-height: 36px;
            height: 36px;
            transform: scaleY(1);
          }
        }
      }
    }

    &:focus {
      ~.form-check-label {
        &::after {
          border-color: theme-color("secondary");
          // box-shadow: 0 0 0 0.32rem rgba(theme-color("secondary"), 0.25);
        }
      }
    }
  }
}

.form-check-inline {
  margin-right: 1.25rem;
}

.pmd-checkbox {
  .form-check-input {
    &:checked~.form-check-label {
      &::after {
        background-color: $checkbox-checked-bg;
        background-image: none;
      }
    }
  }
}