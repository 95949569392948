// stylelint-disable at-rule-no-vendor-prefix, declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix

// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css


// Document
//
// 1. Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.
// 2. Change the default font family in all browsers.
// 3. Correct the line height in all browsers.
// 4. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 5. Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so
//    we force a non-overlapping, non-auto-hiding scrollbar to counteract.
// 6. Change the default tap highlight to be completely transparent in iOS.


html {
  font-family: sans-serif; // 2
  line-height: 1.15; // 3
  -webkit-text-size-adjust: 100%; // 4
  -ms-text-size-adjust: 100%; // 4
  -ms-overflow-style: scrollbar; // 5
  -webkit-tap-highlight-color: rgba($black, 0); // 6
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Set an explicit initial text-align value so that we can later use the
//    the `inherit` value on things like `<th>` elements.

body {
  font-family: $body-font-family;
  font-weight: $body-font-weight;
  line-height: $body-line-height;
  color: $body-color;
  text-align: $body-text-align;
  background-color: $body-bg;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba($black, 0);
	font-size: $body-font-size;
	background-color: $body-bg;
}

//
// Typography
//

// Remove top margins from headings
//
// By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
// margin for easier control within type scales as it avoids margin collapsing.
// stylelint-disable selector-list-comma-newline-after
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
}
// stylelint-enable selector-list-comma-newline-after

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.
p {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
}

dt {
  font-weight: $dt-font-weight;
}

// stylelint-disable font-weight-notation
b,
strong {
  font-weight: bolder; // Add the correct font weight in Chrome, Edge, and Safari
}
// stylelint-enable font-weight-notation

small {
  font-size: 80%; // Add the correct font size in all browsers
}

//
// Links
//
a {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

caption {
  padding-top: $table-cell-padding;
  padding-bottom: $table-cell-padding;
  color: $table-caption-color;
  text-align: left;
  caption-side: bottom;
}

//
// Forms
//
label {
  // Allow labels to use `margin` for spacing.
  display: inline-block;
}
