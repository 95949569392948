/*!
 * Propeller Pro v1.5.0 (http://pro.propeller.in/): contact-sections.css
 * Copyright 2016-2019 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */
/* Media Queries */
// .section-inverse {
// 	color: rgba(255,0255,255,0.78);
// 	color: rgba(255,255,255,0.85);
// 	.primary {
// 		color: rgba(255,255,255,0.9);
// 	}
// 	.secondary {
// 		color: pmd-theme-color(secondary, light);
// 	}
// 	.text-muted {
// 		color: rgba(255,255,255,0.4);
// 	}
// 	.hint {
// 		color: rgba(255,255,255,0.4);
// 	}
// 	.disabled {
// 		color: rgba(255,255,255,0.4);
// 	}
// 	.icon {
// 		color: rgba(255,255,255,0.4);
// 	}
// 	.hover {
// 		color: pmd-theme-color(hover, light);
// 	}
// 	.divider {
// 		color: pmd-theme-color(dividers, light);
// 	}
// 	.site-info {
// 		color: pmd-theme-color(hover, light);
// 		a {
// 			color: rgba(255,255,255,0.8);
// 		}
// 		strong {
// 			color: $white;
// 		}
// 	}
// 	.footer-menu {
// 		li {
// 			a {
// 				color: pmd-theme-color(secondary, light);
// 				&:hover {
// 					color: rgba(255,255,255,0.9);
// 				}
// 			}
// 		}
// 	}
// 	.content-hover-effect {
// 		li {
// 			a {
// 				&:after {
// 					background-color: rgba(255,255,255,0.9);
// 				}
// 			}
// 		}
// 	}
// 	.side-line-effect {
// 		li {
// 			border-color: rgba(255,255,255,0.2);
// 		}
// 	}
// 	.border-right {
// 		border-color: rgba(255,255,255,0.3) !important;
// 	}
// 	label {
// 		color: pmd-theme-color(secondary, light);
// 	}
// 	.pmd-textfield-floating-label.pmd-textfield-floating-label-completed {
// 		label {
// 			color: pmd-theme-color(secondary, light);
// 		}
// 	}
// 	.form-control {
// 		color: rgba(255,255,255,0.8);
// 	}
// 	.pmd-textfield {
// 		.form-control {
// 			border-bottom: solid $border-width pmd-theme-color(dividers, light);
// 		}
// 	}
// 	.border-bottom {
// 		border-color: rgba(255,255,255,.1) !important;
// 	}
// 	hr {
// 		border-color: rgba(255,255,255,.1) !important;
// 	}
// 	.pmd-list-subtitle {
// 		color: pmd-theme-color(hint, light);
// 	}
// 	.pmd-list-title {
// 		color: rgba(255,255,255,0.8);
// 	}
// 	.media {
// 		a {
// 			color: rgba(255,255,255,0.8);
// 		}
// 	}
// 	p {
// 		a {
// 			color: rgba(255,255,255,0.8);
// 		}
// 	}
// 	.social-icons {
// 		.btn {
// 			.material-icons {
// 				svg {
// 					fill: pmd-theme-color(secondary, light);
// 				}
// 			}
// 			&:hover {
// 				.material-icons {
// 					svg {
// 						fill: rgba(255,255,255,0.9);
// 					}
// 				}
// 			}
// 		}
// 		.btn-light.btn {
// 			.material-icons {
// 				svg {
// 					fill: rgba(0,0,0,0.5);
// 				}
// 			}
// 			&:hover {
// 				.material-icons {
// 					svg {
// 						fill: rgba(0,0,0,0.9);
// 					}
// 				}
// 			}
// 		}
// 	}
// }
.pmd-contact-us {
	.pmd-title {
		margin-top: $pmd-contact-title-margin-top;
		border-bottom: $pmd-contact-border-width solid $pmd-contact-title-border-color;
		display: inline-block;
		padding-bottom: $pmd-contact-title-padding-bottom;
		@include media-breakpoint-down(sm){
			margin-top: $pmd-contact-title-margin-top-sm;
		}
	}
	textarea {
		resize: none;
	}
	hr{ 
		background-color: $pmd-contact-hr;
		opacity: 1;
	}
}
.section-inverse.section.border-bottom {
	border-color: $pmd-section-inverse-border-bottom-color !important;
}
.pmd-word-break {
	word-break: break-word;
}
@include media-breakpoint-down(sm) {
	.sm-mb-4 {
		margin-bottom: $pmd-contact-margin-bottom-sm;
	}
}