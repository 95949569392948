@import 'variables';

@mixin theming-light($background) {
    background: $background;
    color: $pmd-datepicker-color;
    .pmd-datepicker-head {
      background-color: $background;
      button{
        color: $pmd-datepicker-color;
      }
    }
    .pmd-datepicker-body {
      table {
        th{
          color: $pmd-datepicker-weekday-color;
        }
        td {
          color: $pmd-datepicker-day-color;
          span.selected,
          &.selected span,
          span[class*="select-"]:after,
          &[class*="select-"] span:after {
            background-color: $background;
          }

          span.selected,
          &.selected span {
            background-color: $pmd-datepicker-day-selected-color;
            color: color-yiq($pmd-datepicker-day-selected-color);
          }

          span.is-other-month,
          &.is-other-month span {
            color: $pmd-datepicker-other-month-day-color;
          }

          &.week span {
            color: $pmd-datepicker-day-selected-color;
          }

          &.active-week span:hover {
            cursor: pointer;
            background-color: $background;
            color: $font-color-01;
            opacity: 0.5;
            transition: 0s;
          }
        }
      }
    }
}

@mixin theming-dark($background) {
  // background: $background;
  color: $pmd-datepicker-dark-color;
  .pmd-datepicker-head {
    background-color: $background;
    button{
      color: $pmd-datepicker-dark-color;
    }
  }
  .pmd-datepicker-body {
    table {
      th{
        color: $pmd-datepicker-weekday-dark-color;
      }
      td {
        color: $pmd-datepicker-day-color;
        span.selected,
        &.selected span,
        span[class*="select-"]:after,
        &[class*="select-"] span:after {
          background-color: $background;
        }

        span.selected,
        &.selected span {
          background-color: $background;
          color: color-yiq($background);
        }

        span.is-other-month,
        &.is-other-month span {
          color: $pmd-datepicker-other-month-day-color;
        }

        &.week span {
          color: $background;
        }

        &.active-week span:hover {
          cursor: pointer;
          background-color: $background;
          color: $font-color-01;
          opacity: 0.5;
          transition: 0s;
        }
      }
    }
  }
}
