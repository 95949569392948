/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): progressbar.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

.pmd-progress { 
	background:none repeat scroll 0 0 $progress-bg;
	border-radius:$progress-border-radius;
	box-shadow: none;
	height: $progress-height;
}
.progress-bar {
	box-shadow:none;
}