.pmd-dropdown {
    .dropdown-toggle{
        display: flex;
        align-items: center;
        justify-content: center;
        &.pmd-btn-fab{
            &::after{
                display: none;
            }
        }
        &::after{
            // content: "expand_more";
            font-family: 'Material Icons';
            font-size: 1.25rem;
            border:none;
            font-weight: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            margin-right: -0.5rem;
            -webkit-font-feature-settings: 'liga';
            -webkit-font-smoothing: antialiased;
        }
    }
    &.show{
        .dropdown-toggle{
            &::after{
                transform: rotate(180deg);
            }
        }
    }
    &.dropup{
        .dropdown-toggle{
            &::after{
                transform: rotate(180deg);
            }
        }
        &.show{
            .dropdown-toggle{
                &::after{
                    transform: rotate(0deg);    
                }
            }
        }
    }
    &.pmd-dropdown-sm{
        .dropdown-menu {
            .dropdown-item{
                padding:0.5rem 1rem;
            }
        }
    }
    .dropdown-menu {
        font-size: $font-size-base;
        border-radius: $dropdown-border-radius;
        background-color: $dropdown-bg;
        &::before {
            border-radius: $dropdown-border-radius;
            background-color: transparent;
        }
        &::-webkit-scrollbar-track {
            border-radius: $sidebar-scrollbar-border-radius;
        }
        &::-webkit-scrollbar-track {
            border-radius: $sidebar-scrollbar-border-radius;
        }
        &::-webkit-scrollbar {
            width: $sidebar-scrollbar-width;
            background-color:$sidebar-scrollbar-bg;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: $sidebar-scrollbar-thumb-border-radius;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color:$sidebar-scrollbar-thumb-bg;
        }
        
        .dropdown-header{
            &.alert{
                border-radius: $dropdown-border-radius $dropdown-border-radius 0 0;
                margin-top: -0.5rem;
                min-width: 220px;
                white-space: inherit;
                font-size: $font-size-sm;
                padding: 0.5rem 1rem;
                line-height: 1.25;
                margin-bottom: 0;
                &.alert-dark{
                    background-color: rgba($dark,0.16);
                    color: rgba($dark, 0.87);
                }
            }
        }
        .dropdown-item{
            font-weight: $font-weight-medium;
            &:last-child{
                border-bottom: none;
            }
            &.disabled{
                color: rgba($text-muted,0.74);
                background-color: rgba($text-muted,0.06);
                .material-icons-round{
                    color: rgba($text-muted,0.54);
                    svg{
                        fill: rgba($text-muted,0.54);
                    }
                }
            }
            .material-icons-round{
                margin-right: 0.825rem;
                opacity: 0.74;
                svg{
                    display: block;
                    fill: $dropdown-link-color;
                }
            }
            &:hover, &:focus{
                text-decoration: none;
                .material-icons-round{
                    color: $secondary;
                    opacity: 1;
                    svg, path{
                        fill: $secondary;
                    }
                }
            }
        }
    }
}

.show > .btn-secondary.dropdown-toggle:focus{
    @include pmd-box-shadow($btn-hover-shadow);
}