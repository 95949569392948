/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): button.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * This software is covered by Propeller Pro Plan 1 License (License.txt)
 * Usage without proper license is prohibited.
 */

.btn {
  font-weight: $btn-font-weight;
  border-width: $btn-border-width;
  @include button-size(
    $btn-padding-y,
    $btn-padding-x,
    $btn-font-size,
    $btn-line-height,
    $btn-border-radius
  );
  @include transition($btn-transition);

  // Ripple Effect
  &.pmd-ripple-effect {
    &:focus,
    &.focus {
      box-shadow: $pmd-ripple-effect-box-shadow;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      &:focus {
        box-shadow: none;
      }
    }
  }

  // Buttons raised
  &.pmd-btn-raised {
    @include pmd-box-shadow($btn-raised-box-shadow);

    &:focus,
    &.focus {
      @include pmd-box-shadow($btn-raised-focus-box-shadow);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      &:focus {
        @include pmd-box-shadow($btn-raised-focus-box-shadow);
      }
    }
  }

  // Buttons circle
  &.pmd-btn-fab {
    padding: 0;
    border-radius: 50%;
    @include fab-button-size($btn-fab-size);

    &.btn-lg {
      @include fab-button-size($btn-fab-size-lg);
    }

    &.btn-sm {
      @include fab-button-size($btn-fab-size-sm);
    }

    &.btn-xs {
      @include fab-button-size($btn-fab-size-xs);
    }
  }

  &.pmd-btn-rounded {
    border-radius: $border-radius;
    padding: 0;
    @include fab-button-size($btn-fab-size);

    &.btn-lg {
      @include fab-button-size($btn-fab-size-lg);
    }

    &.btn-sm {
      @include fab-button-size($btn-fab-size-sm);
    }

    &.btn-xs {
      @include fab-button-size($btn-fab-size-xs);
    }
  }
}

// Buttons colors
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

.btn-default {
  &.pmd-btn-flat {
    color: color-yiq(theme-color("light"));
  }

  &.pmd-btn-outline {
    color: color-yiq(theme-color("light"));
    border-color: color-yiq(theme-color("light"));

    &:hover,
    &:focus {
      border-color: color-yiq(theme-color("light"));
    }
  }
}

// Button Sizes
.btn-lg {
  @include button-size(
    $btn-padding-y-lg,
    $btn-padding-x-lg,
    $btn-font-size-lg,
    $btn-line-height-lg,
    $btn-border-radius-lg
  );
}

.btn-sm {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-sm,
    $btn-line-height-sm,
    $btn-border-radius-sm
  );
}

// Ripple effect
.pmd-ripple-effect {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.ink {
  display: block;
  position: absolute;
  pointer-events: none;
  border-radius: 50%;
  -ms-transform: scale(0);
  transform: scale(0);
  background: #fff;
  opacity: 1;

  &.animate {
    animation: ripple 0.5s linear;
  }
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

// Button groups
.btn-group .btn {
  border-radius: 2px;
}

.btn-group.open .dropdown-toggle {
  outline: 0;
  outline-offset: 0;
  box-shadow: none;
}

// .btn-group .btn + .btn,
// .btn-group .btn + .btn-group,
// .btn-group .btn-group + .btn,
// .btn-group .btn-group + .btn-group {
// 	margin-left: 0;
// }
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 0;
}

.btn-group > .btn:focus:hover,
.btn-group-vertical > .btn:focus:hover,
.btn-group > .btn:active:hover,
.btn-group-vertical > .btn:active:hover,
.btn-group > .btn.active:hover,
.btn-group-vertical > .btn.active:hover {
  z-index: 2;
}

.btn-xxs {
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  font-size: 10px !important;

  i {
    font-size: 13px !important;
    line-height: 18px !important;
  }
}
