/*!
 * Propeller Pro v1.5.0 (http://pro.propeller.in/): footer.css
 * Copyright 2016-2019 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

.social-links{
	a{
		&:after{
			@include transition($transition-base);
		}
	}
}

// Footer
.pmd-footer {
	padding: $pmd-footer-padding-y $pmd-footer-padding-x;
	display: flex;
	flex-wrap:nowrap;
	align-items:center;
	flex-direction: column;
}

// ********************
// Footer Light
// ********************

// Site Info
.pmd-site-info {
	font-size: $pmd-site-info-font-size;
	margin: $pmd-site-info-margin;
	color: $pmd-site-info-color;
	a {
		font-weight: $pmd-site-info-link-weight;
		color: $pmd-site-info-link-dark;
		img{
			max-width: 100%;
		}
	}
	strong {
		color: $pmd-site-info-strong;
		font-weight: $pmd-site-info-strong-weight;
	}
	p {
		display: inline-block;
		margin: $pmd-site-info-margin;
	}
}

// Footer Menu
.pmd-footer-nav {
	padding: $pmd-footer-nav-padding;
	margin: $pmd-site-info-margin (-$pmd-footer-nav-margin-x);
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	list-style: none;
	li {
		padding: $pmd-footer-nav-padding $pmd-footer-nav-margin-x;
		a {
			font-size: $pmd-footer-nav-link-font-size;
			text-transform: $pmd-footer-nav-link-text-transform;
			font-weight: $pmd-footer-nav-link-weight;
			color:  $pmd-footer-nav-link-dark;
			&:hover {
				color: $pmd-footer-nav-link-dark-hover;
			}
		}
	}
}

// Footer nav column


// Footer divider
.pmd-footer-nav-divider li {
	border-right: $border-width solid $pmd-footer-nav-divider-color;
	&:last-child {
		border-right: none;
	}
}

// Button with SVG Icone
.btn {
	.pmd-svg-icons{
		display: inline-flex;
		justify-content: center;
		align-items: center;
		svg {
			padding: $pmd-footer-svg-icons-padding;
		}	
	}
	// &.btn-sm {
	// 	.pmd-svg-icons.pmd-sm {
	// 		height:38px;
	// 		svg {
	// 			width: $material-icons-sm;
	// 			height: $material-icons-sm;
	// 		}
	// 	}
	// }
	// &.btn-md {
	// 	.pmd-svg-icons.pmd-md {
	// 		svg {
	// 			width: 30px;
	// 			height: 30px;
	// 		}
	// 	}
	// }
	// &.btn-lg {
	// 	.pmd-svg-icons.pmd-lg {
	// 		svg {
	// 			width: 45px;
	// 			height: 45px;
	// 		}
	// 	}
	// }
}

// Social icons
.pmd-social-icons {
	.btn {
		margin: $pmd-footer-social-icons-margin-y $pmd-footer-social-icons-margin-x;
		line-height: $pmd-footer-social-icons-line-height;
		img {
			width: $material-icons-sm;
			height: $material-icons-sm;
		}
	}
	.pmd-footer-sub-title {
		padding-right: $pmd-footer-sup-title-padding;
	}
}

// Footer Utilities
.pmd-footer-sub-title {
	font-size: $pmd-footer-sub-title-font-size;
	font-weight: $pmd-footer-sub-title-font-weight;
	display: inline-block;
	vertical-align: middle;
}

// visit us
.pmd-visit-us {
	padding-top: $pmd-footer-padding-y;
	@include media-breakpoint-down(md){
		padding-bottom: $pmd-visit-us-padding-bootom-md;
	}
	@include media-breakpoint-down(sm){
		padding-bottom: $pmd-visit-us-padding-bootom-sm;
	}
	.pmd-social-icons{
		.btn{
			margin-bottom: (-$pmd-social-icon-btn-margin-bottom);
			&.btn-sm{
				margin-bottom: (-$pmd-social-icon-btn-sm-margin-bottom);
			}
			&.btn-md{
				margin-bottom: (-$pmd-social-icon-btn-margin-bottom);
			}
			&.btn-lg{
				margin-bottom: $pmd-social-icon-btn-lg-margin-bottom;
			}
		}
	}
}

.pmd-social-inline {
	margin: $pmd-social-inline-margin-y $pmd-site-info-margin;
	text-align: center;
	@include media-breakpoint-down(md){
		width: 100%;
		display: block;
	}
	.pmd-social-icons {
		margin: 0 auto;
	}
}

// Hover Effect 
.pmd-footer-nav-hover {
	// After Hover Effect 
	li a {
		position: relative;
		&:after {
			content: "";
			position: absolute;
			height: $pmd-footer-nav-hover-link-border-height;
			bottom: 0;
			left: 51%;
			right: 51%;
			background-color: $pmd-footer-nav-hover-link-border-color;
			@include transition($transition-base);
		}
		&:hover:after {
			right: 0;
			left: 0;
		}
	}
	// Effect starts from right
	&.effect-right li a {
		&:after {
			right: 0;
			left: 100%;
		}
		&:hover:after {
			left: 0;
		}
	}
	// Effect starts from center
	&.effect-left li a {
		:after {
			right: 100%;
			left: 0;
		}
		&:hover:after {
			right: 0;
		}
	}
}

/* Footer Dark */
.pmd-footer-dark {
	color: $pmd-footer-nav-link-hover;
	.primary {
		color: $pmd-footer-dark-primary-color;
	}
	.secondary {
		color: $pmd-footer-dark-secondary-color;
	}
	.text-muted, .hint, .disabled, .icon {
		color: $pmd-footer-dark-hint-color;
	}
	.hover {
		color: $pmd-footer-dark-hover-color;
	}
	.divider {
		color: $pmd-footer-dark-dividers-color;
	}
	.pmd-site-info {
		color: $pmd-footer-dark-hover-color;
		a{
			color: $pmd-footer-dark-secondary-color;
		}
		strong{
			color: $pmd-footer-dark-primary-color;
		}
	}
	.pmd-footer-nav li a {
		color: $pmd-footer-dark-secondary-color;
		&:hover{
			color: $pmd-footer-nav-link-hover;
		}
		&:after{
			background-color: $pmd-footer-nav-link-hover;
		}
	}
	.pmd-footer-nav-divider li {
		border-color: $pmd-footer-dark-dividers-color;
	}
	.pmd-social-icons .btn .material-icons svg {
		fill: $pmd-footer-dark-hint-color;
	}
	.pmd-social-icons .btn:hover .material-icons svg {
		fill: $pmd-footer-nav-link-hover;
	}
	hr {
		border-color: $pmd-footer-hr;
	}
	/*.pmd-footer-dark a{color: rgb(255,255,255);}*/
	.border-end {
		border-color: $pmd-footer-dark-hover-color !important;
	}
	label {
		color: $pmd-footer-dark-secondary-color;
	}
	.pmd-textfield-floating-label.pmd-textfield-floating-label-completed label {
		color: $pmd-footer-dark-secondary-color;
	}
	.form-control {
		color: $pmd-footer-nav-link-hover;
	}
	.pmd-textfield .form-control {
		border-bottom: solid $border-width $pmd-footer-dark-dividers-color;
	}
	.pmd-list-subtitle{
		color: $pmd-footer-dark-secondary-color;
	}
	.pmd-list-title{
		color: $pmd-footer-dark-primary-color;
	}
}

.pmd-list-transparent{
	.list-group-item{
		background-color: transparent;
	}
}