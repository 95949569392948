.pf-navbar-right-icon {
    .language-dropdown-form {
        margin-right: 20px;

        @include media-breakpoint-down(sm) {
            margin-right: 8px;
        }
    }
}

.pmd-navbar {
    @include transition($transition-base);

    &.navbar {
        @include media-breakpoint-down(sm) {
            padding-left: 0.5rem;
            padding-right: 0.25rem;
        }
    }

    .fils-logo-icon {
        height: 40px
    }

    &.navbar {
        .breadcrumb {
            @include transition($transition-base);
            height: 20px;
        }

        .btn {
            @include transition($transition-base);

            &.pmd-sidebar-toggle {
                @include media-breakpoint-down(sm) {
                    margin-left: calc(-1 * $navbar-toggler-margin-right/2);
                }
            }
        }

        &.navbar-sm {
            .btn {
                &.pmd-btn-fab {
                    margin-top: calc($navbar-right-icon-margin-y/2);
                    margin-bottom: calc($navbar-right-icon-margin-y/2);

                    @include media-breakpoint-down(sm) {
                        margin-top: calc($navbar-right-icon-mobile-margin-y/2);
                        margin-bottom: calc($navbar-right-icon-mobile-margin-y/2);
                    }
                }

                &.pmd-sidebar-toggle {
                    @include media-breakpoint-down(sm) {
                        margin-left: calc(-1 * $navbar-toggler-margin-right/2);
                    }
                }
            }
        }
    }
}