.pmd-owl-carousel{
    height: 100%;
    display: flex;
    .owl-theme {
        .owl-dots{
            margin-top: 0 !important;
            display: flex;
            align-items: start;
            justify-content: center;
            @include media-breakpoint-down(sm) {
                padding-top: 0.5rem;
                margin-bottom: 1.5rem;
            }
            .owl-dot{
                span{
                    background-color: rgba($primary, 0.3);
                    margin: 0.25rem;
                    height: 0.5rem;
                    width: 0.5rem;
                    transition: all 0.25s ease-in-out 0s;
                }
                &.active{
                    span{
                        background-color: $secondary;
                        width: 1.75rem;
                        @include pmd-box-shadow($btn-raised-box-shadow);
                    }
                }
            }
        }
    }
}