.btn {
  font-family: $font-family-rubik;

  @include media-breakpoint-down(md) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  font-size: 12px;

  @media (min-width:1560px) {
    font-size: 14px;
  }

  &:hover,
  &:focus,
  &:active {
    @include pmd-box-shadow($btn-hover-shadow);
  }

  &.btn-light {

    &:hover,
    &:focus,
    &:active {
      color: $black;
    }
  }

  &.pmd-btn-flat {
    &.btn-light {
      color: pmd-theme-color(secondary, dark);
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
      background-color: transparent;

      span {
        text-decoration: underline;
      }
    }
  }

  &.btn-secondary {
    color: #4D3D00;

    &:not(:disabled):active {
      color: #4D3D00;
    }
  }

  &.btn-outline-dark {
    border-color: $black;
    border-width: 1px;
  }

  &.btn-outline-secondary {
    color: $black;
  }

  &.btn-primarylight {
    background-color: rgba($purple, 0.3);
    border-color: transparent;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: $purple;
      color: $primary;
    }
  }

  &.pmd-btn-icon {
    .svg-icons {
      display: flex;

      svg {
        height: 20px;
      }
    }

    &.btn-sm {
      .material-icons-round {
        // font-size: 1.25rem;
        margin-right: 0.25rem;
      }
    }
  }

  &:disabled {
    background-color: $gray-200;
    border-color: $gray-200;
  }
}

.link-text {
  text-decoration: underline;
  color: $black;
  font-weight: $font-weight-medium;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $black;
  }
}

// Start Radio Buttons
.pf-box-radiobtns {
  button {
    padding: 44px !important;
    background-color: transparent;
    border: 2px solid transparent !important;
    border-radius: 8px;

    &.active,
    &:hover,
    &:focus {
      border: 2px solid rgb(33, 234, 153) !important;
      background-color: rgb(33, 234, 153, 0.16) !important;
      box-shadow: none !important;
    }
  }
}

// End Radio Buttons