.pmd-tabs {
    .nav-tabs {
        .nav-link {
            margin-left: $space16;
            margin-right: $space16;
        }
    }

    .pmd-tab-active-bar {
        border-radius: 2px;
    }
}

.pmd-tab-bordered {
    .pmd-tabs {
        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 4px;
            border-top: $border-width solid $border-color;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 0;
            margin: auto;
            bottom: -2px;
        }
    }
}

.pmd-tabs-aline-left {
    .nav-tabs {
        .nav-link {
            align-items: flex-start;
        }
    }
}