.pmd-sidebar-scrollbar {
  @include pmd-scrollbar($sidebar-scrollbar-bg, $sidebar-scrollbar-thumb-bg);
  display: flex;
  flex-direction: column;
}

.sidebar-title {
  padding: 1rem 1.75rem;
  margin-bottom: 0;
  font-size: 12px;
}

.pmd-sidebar-with-brand {
  z-index: 1031;
  padding-top: 0;
  // overflow: hidden;
  border-right: 1px solid $border-color;

  app-sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @include media-breakpoint-up(lg) {
    transform: translateZ(0);
    min-width: 74px;
    width: 74px;
  }

  .pmd-sidebar-brand {
    background-color: $white;
  }

  .pmd-sidebar-nav {
    padding-left: 0;
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;

    .nav-item {
      .nav-link {

        // font-family: $font-family-noto;
        .pmd-nav-item-icon {
          transition: all 0.5s ease-out;
          display: flex;
          justify-content: center;
        }

        .flex-grow-1 {
          white-space: nowrap;
          overflow: hidden;
          flex: inherit;
          opacity: 0;
          flex-grow: 0 !important;
          transition: all 0.7s ease-in-out;
        }
      }
    }
  }

  .submenu-toggle-icon {
    margin-left: auto;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: 10px;
    top: 0.875rem;
    transition: all 0.7s ease-in-out;

    &.open {
      transform: rotate(-180deg);
    }
  }

  .arrow-show {
    opacity: 1;
    visibility: visible;
    width: auto;
    height: auto;
  }

  .submenu {
    list-style: none;
    margin: 0;
    padding: 0 0 0 32px;
    max-height: 0;
    padding-left: 32px !important;
    overflow: hidden;
    position: unset;
    background-color: transparent;
    border: 0;
    box-shadow: unset;
    transition: max-height 0.7s ease, opacity 0.7s ease;
    border-bottom: 0 !important;
    opacity: 0;

    &.open {
      max-height: 500px;
      /* Adjust this value based on your submenu's maximum height */
      opacity: 1;
      position: relative;
      transition: all 0.7s ease;

      &::before {
        content: "";
        content: "";
        position: absolute;
        width: 1px;
        left: 16px;
        top: 12px;
        bottom: 0;
        height: 90%;
        background-color: #d2d6db;
      }
    }
  }

  .submenu-item {
    .submenu-link {
      text-decoration: none;
      padding: 10px 12px;
      display: flex;
      align-items: center;
      border-radius: 6px;
      column-gap: 10px;
      margin-top: 12px;
    }

    &.active {
      .submenu-link {
        background-color: #fffbf0;
        color: $secondary;
        border-radius: 6px;
      }
    }
  }

  &.pmd-sidebar-open {
    .pmd-sidebar-nav {
      padding-left: 12px;
      padding-right: 12px;

      .nav-item {
        .nav-link {
          .flex-grow-1 {
            margin-left: 1rem;
            white-space: nowrap;
            overflow: visible;
            flex: 1;
            opacity: 1;
            flex-grow: 1 !important;
          }
        }
      }
    }
  }
}

.pmd-sidebar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.pmd-sidebar-with-brand {
  ~ {
    .pmd-content {
      @include transition($sidebar-transition);

      @include media-breakpoint-up(lg) {
        margin-left: 74px;
      }
    }
  }
}

.pmd-sidebar-slide-push {
  &.pmd-sidebar-open~ {
    .pmd-navbar {
      // margin-left: $sidebar-width;

      @include media-breakpoint-down(sm) {
        margin-left: 0;
      }
    }
  }
}