$ng-select-highlight: pmd-theme-color(secondary, dark);
$ng-select-primary-text: rgba(black, 0.87);
$ng-select-primary-light-text: rgba(white, 0.87);
$ng-select-secondary-text: rgba(black, 0.54);
$ng-select-secondary-light-text: rgba(white, 0.54);
$ng-select-disabled-text: rgba(black, 0.38);
$ng-select-divider: pmd-theme-color(hover, dark);
$ng-select-bg: #ffffff;
$pmd-text-secondary: pmd-theme-color(secondary, dark);
$pmd-text-hint: pmd-theme-color(hint, dark);


$pmd-theme-selection-checkbox: theme-color("secondary");
$pmd-theme-primary: theme-color("primary"); // baseline purple, 500 tone
$pmd-theme-secondary: theme-color("secondary");
// $pmd-theme-primary-dark: $dark-blue; 
$pmd-theme-selection: rgba($secondary, 0.10);
$pmd-theme-hover: rgba($secondary, 0.10);
$pmd-theme-icon-bg: $cyan;
$pmd-theme-background: $white; // White

$ng-placeholder-color: pmd-theme-color(primary, dark);