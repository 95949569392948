.pmd-textfield-floating-label {
    .pmd-select {
        &.ng-select-multiple {
            .ng-has-value {
                .ng-value-container {
                    margin: -0.15rem 0 -0.25rem -0.25rem;
                }
            }
        }

        .ng-has-value {
            .ng-placeholder {
                color: $black;
            }
        }
    }

    &.pmd-textfield-floating-label-active {
        .pmd-select {

            &.ng-select-opened,
            &.ng-select-focused {
                .ng-placeholder {
                    color: $secondary;
                }
            }
        }
    }

    &.pmd-invalid-field {
        .pmd-select {
            &.pmd-select-outline {
                border-color: $danger;

                .ng-placeholder {
                    color: $danger;

                    &::after {
                        content: "*";
                        color: $danger;
                        display: inline-flex;
                        padding-left: 0.15rem;
                    }
                }
            }
        }
    }

    &.pmd-required-field {
        .pmd-select {
            &.pmd-select-outline {
                .ng-placeholder {
                    &::after {
                        content: "*";
                        color: $danger;
                        display: inline-flex;
                        padding-left: 0.15rem;
                    }
                }
            }
        }
    }
}

.pmd-select {
    .ng-dropdown-panel {
        .ng-dropdown-panel-items {
            .ng-option {
                .pmd-checkbox-option {
                    .pmd-option-check {
                        &.pmd-option-check-warning {
                            color: rgba(theme-color("warning"), 0.54);

                            &::after {
                                background-color: rgba(theme-color("warning"), 0.54);
                            }
                        }

                        &.pmd-option-check-secondary {
                            color: rgba(theme-color("secondary"), 0.54);

                            &::after {
                                background-color: rgba(theme-color("secondary"), 0.54);
                            }
                        }

                        &.pmd-option-check-danger {
                            color: rgba(theme-color("danger"), 0.54);

                            &::after {
                                background-color: rgba(theme-color("danger"), 0.54);
                            }
                        }

                        &.pmd-option-check-dark {
                            color: rgba(theme-color("dark"), 0.54);

                            &::after {
                                background-color: rgba(theme-color("dark"), 0.54);
                            }
                        }

                        &.pmd-option-check-paied {
                            color: theme-color("secondary");

                            &::after {
                                background-color: theme-color("secondary");
                            }
                        }

                        position: relative;

                        &:before {
                            opacity: 1;
                        }

                        &::after {
                            height: 14px;
                            width: 14px;
                            content: "";
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            border-radius: 0;
                        }
                    }
                }

                &.ng-option-selected {
                    .pmd-checkbox-option {
                        .pmd-option-check {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}