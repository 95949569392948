/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): breadcrumb.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

.pmd-breadcrumb {
  background-color: $breadcrumb-bgcolor;
  padding: 0;
  margin-bottom: 0;
  .breadcrumb-item {
    font-size: $breadcrumb-font-size;
    font-weight: $breadcrumb-font-weight;
    a {
      color: $breadcrumb-font-color;
      @include hover-focus-active {
        color: $breadcrumb-hover-color;
      }
    }
    &.active {
      color: $breadcrumb-active-color;
      font-weight: $breadcrumb-active-font-weight;
    }
    &::before {
      font-weight: $font-weight-normal;
      color: $breadcrumb-font-color;
    }
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
  &.pmd-breadcrumb-dark {
    .breadcrumb-item {
      a {
        color: $breadcrumb-dark-font-color;
        @include hover-focus-active {
          color: $breadcrumb-dark-hover-color;
        }
      }
      &.active {
        color: $breadcrumb-dark-active-color;
      }
      &::before {
        color: $breadcrumb-dark-font-color;
      }
    }
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: $breadcrumb-divider;
}

.pmd-breadcrumb {
  background-color: $breadcrumb-bgcolor;
  padding: 0;
  margin-bottom: 0;
  .breadcrumb-item {
    font-size: $breadcrumb-font-size;
    font-weight: $breadcrumb-font-weight;
    a {
      color: $breadcrumb-font-color;
      @include hover-focus-active {
        color: $breadcrumb-hover-color;
      }
    }
    &.active {
      color: $breadcrumb-active-color;
      font-weight: $breadcrumb-active-font-weight;
    }
    &::before {
      font-weight: $font-weight-normal;
      color: $breadcrumb-font-color;
    }
  }
}

.pmd-breadcrumb-icon {
  .breadcrumb-item {
    display: flex;
    .breadcrumb-link,
    &.active {
      display: inline-flex;
      align-items: center;
      i,
      .material-icons {
        margin-right: $breadcrumb-icon-margin-right;
      }
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    padding-right: $breadcrumb-icon-padding-y;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: $breadcrumb-icon-padding-y;
  }
}
