.pmd-textfield {

  /* Chrome, Safari, Edge, Opera */
  .form-control::-webkit-outer-spin-button,
  .form-control::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:-internal-autofill-selected {
    background-color: transparent !important;
  }

  /* Firefox */
  .form-control[type=number] {
    -moz-appearance: textfield;
  }

  &.pmd-textfield-outline {
    &.pmd-textfield-floating-label {
      &.pmd-textfield-floating-label-completed {
        label {
          color: $gray-600;
          font-weight: $font-weight-medium;
          z-index: 1;
          position: relative;
        }
      }

      &.pmd-textfield-floating-label-active {
        label {
          color: $black;
        }

        .pmd-textfield-label-wrapper {
          @include pmd-box-shadow($textfield-outline-active-box-shadow);
          border-radius: $border-radius;
        }
      }
    }

    &.pmd-textfield-is-invalid {
      &.pmd-textfield-floating-label-active {
        .pmd-textfield-label-wrapper {
          box-shadow: 0 6px 6px rgb($danger, 0.08);
        }
      }
    }
  }

  // Editable Text Field
  &.pmd-content-editable {
    .form-control {
      height: auto;

      .editor-tag {
        background-color: rgba($secondary, 0.16);
        font-size: $font-size-sm;
        border-radius: 1rem;
        color: $black;
        font-weight: $font-weight-medium;
        padding: 0.25rem 0.5rem;
      }
    }
  }
}

.pmd-select {
  &.pmd-select-outline {
    &.ng-select-multiple {

      &.ng-select-opened,
      &.ng-select-focused,
      &.ng-select-opened {
        .ng-has-value {
          padding-bottom: 0.6rem;
        }
      }

      .ng-has-value {
        padding-bottom: 0.6rem;
      }

      .ng-value {
        background-color: $gray-100;
        font-size: $font-size-sm;
        border-radius: 1rem;
        color: $black;
        font-weight: $font-weight-medium;
        padding: 0.25rem 0.5rem;
        margin: 0 0.25rem 0.25rem 0;
        display: flex;

        .ng-value-icon {
          content: "close";
          font-family: 'Material Icons';
          font-size: 1rem;
          height: 1.25rem;
          width: 1.25rem;
          margin: -0.15rem 0.25rem -0.125rem -0.3rem;
          border-radius: 50%;
          border: none;
          font-weight: bold;
          font-style: normal;
          line-height: 0.95;
          letter-spacing: normal;
          text-transform: none;
          white-space: nowrap;
          word-wrap: normal;
          direction: ltr;
          display: flex;
          align-self: center;
          justify-content: center;
          -webkit-font-feature-settings: 'liga';
          -webkit-font-smoothing: antialiased;
          background-color: rgba($black, 0.16);
          color: rgba($black, 0.7);
        }
      }
    }
  }
}

.pmd-textfield-outline,
.pmd-custom-file-outline {

  .form-text,
  .valid-feedback,
  .invalid-feedback {
    padding-left: 0;
  }

  .form-text {

    .material-icons-round,
    svg,
    path {
      color: $gray-700;
      opacity: 0.74;
    }
  }
}

.pmd-form-card {
  .card-title {
    color: $blue;
  }

  .pmd-input-group-outline {
    margin-bottom: 16px;

    .pmd-textfield {
      margin-bottom: 0;
    }
  }
}

.input-group:not(.pmd-input-group-icon)> {
  .pmd-select-auto {
    max-width: inherit;
    flex: 0 0 auto;
    width: auto;
  }
}


.pmd-textfield-is-invalid {
  &.pmd-textfield {
    &.pmd-textfield-outline {
      .invalid-feedback {
        padding-left: 0.25rem;
      }

      &.pmd-textfield-floating-label {
        &.pmd-textfield-floating-label-completed {
          label {
            color: $danger;
          }
        }
      }
    }
  }
}

.pmd-input-group-outline {
  &.pmd-invalid-field {
    .input-group-text {
      background-color: rgba($danger, 0.12);
      border-color: $danger;
      color: $danger;

      &+.pmd-textfield {
        .pmd-textfield-outline-right {
          border-top-right-radius: $border-radius !important;
          border-bottom-right-radius: $border-radius !important;
        }
      }
    }

    .pmd-textfield {
      &.pmd-textfield-outline {
        &.pmd-textfield-floating-label {
          &.pmd-textfield-floating-label-completed {
            label {
              color: $danger;
            }
          }
        }
      }

      &+.pmd-select {
        background-color: rgba($danger, 0.12);
        border-color: $danger;
        border-top-right-radius: $border-radius !important;
        border-bottom-right-radius: $border-radius !important;

        .ng-placeholder {
          color: $danger;
        }
      }

      &.pmd-textfield-is-invalid~.invalid-feedback {
        padding-left: 0.25rem;
      }
    }
  }

  .invalid-feedback {
    padding-left: 0;
  }
}

//Auto Fill 
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: $textfield-border-color;
  -webkit-text-fill-color: $gray-900;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

.pmd-custom-file {
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}