button {
  @include hover-focus-active {
    outline: 0;
  }
}

@font-face {
  font-family: NotoSans-Thin;
  font-style: normal;
  font-weight: 100;
  src: url(../../fonts/NotoSans-Thin.ttf) format("truetype");
}

.kh-light {
  font-family: NotoSans-Light;
  font-style: normal;
  font-weight: 300 !important;
}

@font-face {
  font-family: NotoSans-Light;
  font-style: normal;
  font-weight: 300;
  src: url(../../fonts/NotoSans-Light.ttf) format("truetype");
}

.kh-regular {
  font-family: NotoSans-Regular;
  font-style: normal;
  font-weight: 400 !important;
}

@font-face {
  font-family: NotoSans-Regular;
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/NotoSans-Regular.ttf) format("truetype");
}

.kh-medium {
  font-family: NotoSans-Medium;
  font-style: normal;
  font-weight: 500 !important;
}

@font-face {
  font-family: NotoSans-Medium;
  font-style: normal;
  font-weight: 500;
  src: url(../../fonts/NotoSans-Medium.ttf) format("truetype");
}

.kh-bold {
  font-family: NotoSans-Bold;
  font-style: normal;
  font-weight: 700 !important;
}

@font-face {
  font-family: NotoSans-Bold;
  font-style: normal;
  font-weight: 700;
  src: url(../../fonts/NotoSans-Bold.ttf) format("truetype");
}

.kh-black {
  font-family: NotoSans-Black;
  font-style: normal;
  font-weight: 900 !important;
}

@font-face {
  font-family: NotoSans-Black;
  font-style: normal;
  font-weight: 900;
  src: url(../../fonts/NotoSans-Black.ttf) format("truetype");
}

// @font-face {
//     font-family: 'Nexa';
//     src: url('../../fonts/Nexa-Bold.woff2') format('woff2'),
//         url('../../fonts/Nexa-Bold.woff') format('woff');
//     font-weight: 800;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Nexa';
//     src: url('../../fonts/Nexa-BoldItalic.woff2') format('woff2'),
//         url('../../fonts/Nexa-BoldItalic.woff') format('woff');
//     font-weight: 800;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Nexa';
//     src: url('../../fonts/Nexa-Book.woff2') format('woff2'),
//         url('../../fonts/Nexa-Book.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Nexa';
//     src: url('../../fonts/Nexa-Light.woff2') format('woff2'),
//         url('../../fonts/Nexa-Light.woff') format('woff');
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }