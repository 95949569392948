/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): tooltip.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */
 
.pmd-tooltip {
	&.tooltip {
		padding: 0;
		&.pmd-arrow{
			.arrow {
				display: block;
			}
			.tooltip-arrow { 
				display:block;
			}
			&.show{
				.tooltip-arrow { 
					opacity: 1;
					transition-delay: 0.15s;
				}
				&.bs-tooltip-start {
					.tooltip-inner{
						left: -6px;
					}
				}
				&.bs-tooltip-bottom {
					.tooltip-inner {
						top: 6px;
					}	
				}
				&.show.bs-tooltip-end {
					.tooltip-inner {
						left:6px;
					}
				}
			}
		}
		.arrow {
			display: none;
		}
		.tooltip-arrow { 
			display:none;
			opacity: 0;
			@include transition($transition-base);
		}
		.tooltip-inner {
			background-color:transparent; 
			padding:$tooltip-padding-y $tooltip-padding-x;
			left: 0;
			overflow: hidden;
			opacity: 0;
			position: relative;
			font-size: $tooltip-font-size;
			font-weight: $tooltip-font-weight;
			line-height: $tooltip-line-height;
			&:before { 
				background-color: $tooltip-bg; 
				width:100%; 
				height:100%; 
				opacity: 1; 
				position: absolute;  
				z-index: -1; 
				content:""; 	
				left:50%;
				@include transform-scale(0);
				@include transition($transition-base);
				color: $tooltip-color; 
				text-align: center;
				text-decoration: none;
				@include border-radius(4px);
				top: 0;
				bottom: 0;
			}
		}
		
		// tooltip Show
		&.show {
			filter: alpha(opacity=100); 
			.tooltip-inner {
				opacity:100;
				@include transition($transition-base);
				&:before { 
					width:100%; 
					height:100%; 
					left:0; 
					opacity: 1; 
					@include transform-scale(1);
				}
			}
		}
		// tooltip top
		&.bs-tooltip-top {
			.tooltip-inner {
				&:before {
					top:100%;
					left: 0;	
				}
			}
		}
		&.show.bs-tooltip-top {
			.tooltip-inner {
				top: -7px;
				position: relative;
				&:before {
					top:0;
					@include transform-origin(50% 100% 0)
				}	
			}
		}
		// tooltip bottom
		&.bs-tooltip-bottom {
			.tooltip-inner {
				&:before {
					top:0;
					left: 0;
					@include transform-origin(50% 0 0)
				}
			}
		}
		&.show.bs-tooltip-bottom {
			&:not(.pmd-arrow){
				.tooltip-inner {
					top: 10px;
				}	
			}
		}
		// tooltip right
		&.bs-tooltip-end {
			.tooltip-inner {
				&:before {
					left:0;
					@include transform-origin(0% 50% 0)
				}
			}
			.arrow{
				left: 0;
			}
		}
		&.show.bs-tooltip-end {
			&:not(.pmd-arrow){
				.tooltip-inner {
					left:10px;
				}
			}
			.tooltip-inner {
				&:before {
					top:0;
				}
			}
		}
		// tooltip left
		&.bs-tooltip-start {
			.tooltip-inner {
				&:before {
					top:50%; 
					left:100%;
					border-radius: 100% 0 0 100% / 50% 0 0 50%;
				}
			}
			.arrow{
				right: 0;
			}
		}
		&.show.bs-tooltip-start {
			&:not(.pmd-arrow){
				.tooltip-inner {
					left: -10px;
				}
			}
			.arrow{
				right:0;
			}
			.tooltip-inner {
				&:before {
					left:0; 
					top:0;
					@include transform-origin(100% 50% 0);
					border-radius: 2px;
				}
			}
		}
	}
}