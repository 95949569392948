.pmd-drop-container {
  border: dashed 1px rgba(28, 36, 58, 0.2);
  height: 214px;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  &.khr-data-uploaded {
    border: transparent;
    justify-content: start;
    align-items: center;
  }

  &.is-drop-over {
    position: relative;
    border: solid 1px $secondary;
    background-color: rgba(235, 185, 1, 0.03);
    border-radius: 8px;

    &.khr-data-uploaded {
      padding: 10px;
      margin: -12px;

      .khr-upload-container {
        opacity: 0.8;
      }
    }
  }

  &.pmd-singal-drop-container {
    &.is-drop-over {
      &.khr-data-uploaded {
        margin-right: -36px;
      }
    }
  }
  .btn-outline-dark {
    border-color: #bdbdbd;
  }
  .small_or-line {
    position: relative;
    max-width: 500px;
    margin: 100px auto;
    text-align: center;
    &::after {
      content: " ";
      height: 1px;
      width: 60px;
      background: #bdbdbd;
      display: block;
      position: absolute;
      top: 50%;
      right: 30px;
    }
    &::before {
      content: " ";
      display: block;
      height: 1px;
      width: 60px;
      position: absolute;
      top: 50%;
      left: 30px;
      background: #bdbdbd;
    }
  }
}

.pmd-upload-button {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}

.pmd-upload-list-item {
  height: 100%;
  width: 100%;

  .card-body {
    padding: 0 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  position: relative;

  .btn.pmd-btn-fab.btn-xs {
    &.pmd-btn-close {
      height: 18px;
      min-width: 18px;
      right: -7px;
      top: -7px;
      width: 18px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #a5a5a5;
      border: none;

      i {
        font-size: 13px;
        color: #fff;
        line-height: 18x;
        font-variation-settings: "FILL" 0, "wght" 900, "GRAD" 200, "opsz" 48;
      }
    }

    &.pmd-btn-edit {
      color: $gray-700;

      i {
        font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
      }
    }
  }
}

.pmd-upload-thumb {
  border: solid $border-color $border-width;
  border-radius: $border-radius-sm;
}

.pmd-upload-card-thumb {
  border: solid $border-color $border-width;
  border-radius: $border-radius-sm;
  height: 110px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .pmd-upload-card-imd {
    width: 100%;
  }
}


.pmd-upload-list-item-error {
  border-color: theme-color(danger);

  a {
    color: theme-color(danger);
  }
}
