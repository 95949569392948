$pmd-datepicker-bg:  $white !default;
$pmd-datepicker-box-shadow: pmd-box-shadow(z-depth-1, dark) !default;
$pmd-datepicker-border-color:$border-color !default;
$pmd-datepicker-border-width:$border-width !default;
$pmd-datepicker-color:pmd-theme-color(primary, dark) !default;
$pmd-datepicker-head-font-size:14px !default;
$pmd-datepicker-head-font-weight:500  !default;
$pmd-datepicker-weekday-font-size:12px !default;
$pmd-datepicker-weekday-color:pmd-theme-color(secondary, dark) !default;
$pmd-datepicker-weekday-font-weight:400 !default;
$pmd-datepicker-day-color:pmd-theme-color(primary, dark) !default;
$pmd-datepicker-day-font-size:13px !default;
$pmd-datepicker-day-selected-color: theme-color("secondary") !default;

$pmd-datepicker-head-button-hover-bg: pmd-theme-color(hover, dark) !default;
$pmd-datepicker-head-button-active-bg: pmd-theme-color(hover, dark) !default;
$pmd-datepicker-other-month-day-color: pmd-theme-color(hint, dark) !default;

$pmd-datepicker-day-dark-color:pmd-theme-color(primary, light) !default;
$pmd-datepicker-dark-color:pmd-theme-color(primary, light) !default;
$pmd-datepicker-weekday-dark-color:pmd-theme-color(secondary, light) !default;

$pmd-datepicker-head-button-hover-dark-bg: pmd-theme-color(hover, light) !default;
$pmd-datepicker-head-button-active-dark-bg: pmd-theme-color(hover, light) !default;
$pmd-datepicker-other-month-day-dark-color: pmd-theme-color(hint, light) !default;
$highlighted-dark:darken(theme-color("dark"), 7.5%);

$custom-range-bg: #eee !default;

$font-color-01:   #fff !default;
$font-color-02:   #9aaec1 !default;
$font-color-03:   #54708b !default;

$border-color:    #e9edf0 !default;
$highlighted:     #e9edf0 !default;

$btn-bg:          #e9edf0 !default;
$btn-bg-hover:    #d5dadd !default;

$btn-bg2:         #9aaec1 !default;
$btn-bg2-hover:   #54708b !default;

$theme-gray:      #777 !default;
$theme-green:     #5cb85c !default;
$theme-blue:      #5bc0de !default;
$theme-dark-blue: #337ab7 !default;
$theme-red:       #d9534f !default;
$theme-orange:    #f0ad4e !default;

$button-disabled-bg:  pmd-theme-color(disabled, dark) !default;
$button-disabled-color: #f5f5f5 !default;


$pmd-datepicker-theme: map-remove($theme-colors, "dark", "light", "success", "info", "warning", "danger");