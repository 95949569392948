.login-signup-card {
    .pmd-loader-card{
        min-height: auto;
        .loader{
            min-height: 100%;
        }
    }
}
.before-login-layout{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    flex-direction: column;
    background-image: none;
    overflow: hidden;
    .container-small{
        position: relative;
        z-index: 2;
    }
    &::after{
        background-image: url('../../images/fils-bg-pattern-full.svg');
        content: "";
        display: block;
        height: 450px;
        width: 450px;
        position: absolute;
        right: 5%;
        top: 5%;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 1;
    }
    &::before{
        background-image: url('../../images/fils-bg-pattern-full.svg');
        content: "";
        display: block;
        height: 450px;
        width: 450px;
        position: absolute;
        left: 5%;
        top: 50%;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 1;
    }
    footer{
        width: 100%;
        .login-app-footer{
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
            .pmd-footer{
                padding: 0;
                .pmd-site-info, strong{
                    color: rgba($white, 0.54);
                }
                .pmd-footer-nav{
                    @include media-breakpoint-down(md) {
                        justify-content: center;
                    }
                    &.pmd-footer-nav-divider{
                        li{
                            border-color: rgba($white, 0.24);
                            line-height: 1;
                            a{
                                color: rgba($white, 0.54);
                                &:hover{
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.or-separator{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5rem 0.5rem;
    position: relative;
    @include media-breakpoint-down(md) {
        margin: 1rem 0.5rem;
    }
    &::after{
        content: "";
        display: inline-flex;
        height: 1px;
        width: 100%;
        background-color: $border-color;
        position: absolute;
        z-index: 0;
    }
    span{
        background-color: $white;
        padding: 0 .5rem;        
        position: relative;
        z-index: 1;
        font-size: $font-size-sm;
        color: $text-muted;
    }
}

.login-signup-card{
    .pmd-card-media{
        background-color: rgba($purple,.16);
        border-right: 1px solid $border-color;
        height: 100%;
        @include media-breakpoint-down(sm) {
            height: auto;
            border-top: 1px solid $border-color;
            border-right: none;
        }
        .login-content-left{
            padding: 2rem;
            @include media-breakpoint-down(md) {
                padding-bottom: 0;
            }
        }
        .owl-stage-outer{
            height: calc(100% - 3rem);
            @include media-breakpoint-down(sm) {
                height: auto;
            }
        }
    }
    .login-signup-frm{
        padding: 0 2.5rem;
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        @include media-breakpoint-down(md) {
            padding: 0 1rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }
}