@mixin pmd-scrollbar($scrollbar-bg, $scrollbar-thumb-bg) {
	&::-webkit-scrollbar-track {
		border-radius: $sidebar-scrollbar-border-radius;
	}
	&::-webkit-scrollbar-track {
		border-radius: $sidebar-scrollbar-border-radius;
	}
	&::-webkit-scrollbar {
		width: $sidebar-scrollbar-width;
		background-color:$scrollbar-bg;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: $sidebar-scrollbar-thumb-border-radius;
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color:$scrollbar-thumb-bg;
	}
}