// Button variants
@mixin button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {
	color: color-yiq($background);
	background-color: $background;
	border-color: $border;
	@include hover {
		color: color-yiq($hover-background);
		background-color: $hover-background;
		border-color: $hover-border;
	}
	&:focus,
	&.focus {
		box-shadow: 0 0 0 $btn-focus-width rgba($border, .5);
	}
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle{
		color: color-yiq($active-background);
		background-color: $active-background;
		border-color: $active-border;
		&:focus {
			box-shadow: 0 0 0 $btn-focus-width rgba($border, .5);
		}
	}
	.ink {
		background-color: $active-background;
	}
	&.pmd-btn-flat{
		color: $background;
		background-color: transparent;
		border-color: transparent;
		@include hover {
			color: $background;
			background-color: $btn-hover-background;
		}
		&:focus,
		&.focus {
			background-color: $btn-hover-background;
		}
		.ink{ 
			background-color: #808080;
		}
		&:not(:disabled):not(.disabled):active,
		&:not(:disabled):not(.disabled).active {
			color: $background;
			background-color: #e5e5e5;
			border-color: transparent;
		}
	}
}

@mixin button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
	color: $color;
	border-color: $color;
	&:hover {
		color: $color-hover;
		background-color: $active-background;
		border-color: $active-border;
	}
	.ink {
		background-color: $active-background;
	}
	&.disabled,
	&:disabled {
		color: $color;
	}
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		color: color-yiq($active-background);
		background-color: $active-background;
		border-color: $active-border;
	}
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}

// Fab button sizes
@mixin fab-button-size($size) {
  width:$size; 
  height:$size; 
  min-width: $size;
  span, 
  i { 
	line-height: $size - $btn-border-width*2;
  }
}

// Gradient button sizes
@mixin linear-gradient-button($linear-gradient...) {
	border: none; 
	color: $gradient-color;
	background: $btn-gradient-primary;
	background: -moz-linear-gradient($linear-gradient);
	background: -webkit-linear-gradient($linear-gradient);
	background: linear-gradient($linear-gradient);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$btn-gradient-primary",endColorstr="$btn-gradient-secondary",GradientType=1);
	@include hover {
		color: $linear-gradient;
		background-color: $btn-gradient-hover;
	}
	&:focus,
	&.focus {
		background-color: $btn-gradient-hover;
	}
	.ink{ 
		background-color:darken($btn-gradient-primary, 10%);
	}
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		color: $linear-gradient;
		background-color:$btn-gradient-hover;
		border-color: transparent;
	}
}

@mixin radial-gradient-button($radial-gradient...) {
	border: none;
	color: $gradient-color;
	background: $btn-gradient-primary;
	background: -moz-radial-gradient($radial-gradient);
	background: -webkit-radial-gradient($radial-gradient);
	background: radial-gradient($radial-gradient);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$btn-gradient-primary",endColorstr="$btn-gradient-secondary",GradientType=1);
	@include hover {
		color: $radial-gradient;
		background-color: $btn-gradient-hover;
	}
	&:focus,
	&.focus {
		background-color: $btn-gradient-hover;
	}
	.ink{ 
		background-color:darken($btn-gradient-primary, 10%);
	}
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		color: $radial-gradient;
		background-color:$btn-gradient-hover;
		border-color: transparent;
	}
}
