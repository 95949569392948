/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): toggle-switch.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

// Propeller css for toggle button
.pmd-switch {
	vertical-align: middle;

	.form-check-input {
		height: 1.15rem;
		width: 2.25rem;
		border-width: 2px;

		&:checked {
			background-color: $toggle-switch-check-bg;
			border-color: $toggle-switch-check-bg;
		}

		@media(min-width:1560px) {
			height: 1.5rem;
			width: 3.5em;
		}
	}

	// -moz-user-select: none;
	// label,
	// input,
	// .pmd-switch-label {
	// 	-moz-user-select: none;
	// }
	// label { 
	// 	cursor: pointer;
	// 	font-weight: 400;
	// 	input[type="checkbox"] {
	// 		height: 0; 
	// 		opacity: 0; 
	// 		width: 0; 
	// 		position:absolute;
	// 	}
	// 	.pmd-switch-label,
	// 	input[type="checkbox][disabled"] + .pmd-switch-label {
	// 		background-color: $toggle-switch-bar-bg; 
	// 		border-radius:15px; 
	// 		content:""; 
	// 		display:block; 
	// 		height:15px;
	// 		@include transition($transition-base);
	// 		vertical-align:middle;
	// 		width:30px;
	// 		position:relative;
	// 	}
	// 	.pmd-switch-label::after {
	// 		background-color: $toggle-switch-bg; 
	// 		border-radius: 20px; 
	// 		@include pmd-box-shadow($switch-label-box-shadow);
	// 		content: ""; 
	// 		display: inline-block; 
	// 		height: 20px; 
	// 		left: -6px; 
	// 		position: absolute; 
	// 		top: -2px; 
	// 		@include transition($transition-base);
	// 		width: 20px;
	// 	}
	// 	input[type="checkbox][disabled"] {
	// 		+ .pmd-switch-label::after,
	// 		&:checked + .pmd-switch-label::after {
	// 			background-color: $toggle-switch-disabled-bg;
	// 		}
	// 	}
	// 	input[type="checkbox"], 
	// 	input[type="checkbox][disabled"] {
	// 		+ .pmd-switch-label:active::after {
	// 			@include pmd-box-shadow($switch-label-box-shadow);
	// 		}
	// 	}
	// 	input[type="checkbox"]:checked {
	// 		+ .pmd-switch-label::after {
	// 			left: 15px;
	// 			background-color: $toggle-switch-check-bg;
	// 		}
	// 		+ .pmd-switch-label {
	// 			background-color: $toggle-switch-bar-check-bg;
	// 		}
	// 		+ .pmd-switch-label:active::after {
	// 			@include pmd-box-shadow($switch-label-box-shadow);
	// 		}
	// 	}
	// }
}