@mixin badge-variant($bg) {
  	color: color-yiq($bg);
  	background-color: $bg;
  	border-color: $bg;
  	@include hover-focus {
    	color: color-yiq($bg);
  		background-color: darken($bg, 10%);
  		border-color: darken($bg, 10%);
  	}
}

@mixin notification-badge($bg) {
  	color: color-yiq($bg);
  	background-color: $bg;
}

@mixin badge-outline-variant($border) {
  	color: $border;
  	border-color: $border;
  	.pmd-chip-action {
  		i{
  			background-color: rgba($border, $chip-outline-action-opacity);
  		}
  	}
  	@include hover-focus {
    	color: color-yiq($border);
  		background-color: $border;
  		border-color: $border;
  		.pmd-chip-action {
	  		i{
	  			background-color: $chip-action-bg;
	  		}
	  	}
  	}
}

@mixin pmd-badge-variant($background, $border-color, $color) {
	color: $color;
	background-color:$background;
	border-color:$border-color;
}