.pmd-tooltip{
  transition: none;
  &.tooltip {
    .tooltip-inner{
      max-width: 210px;
      &:before{
        transition: none;
      }
    }
  }
}

.pf-popover-right{
  .popover-content{
    
  }
}
.bs-popover-right{
  padding: 0 10px;
  box-shadow: 0 8px 24px rgb(26 41 70 / 24%);
  .popover-arrow{
    display: none;
  }
}