/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): modal.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */
.pmd-modal {
	.modal-content {
		border-radius: $modal-border-radius;
		display: block;
	}

	.modal-header {
		border-bottom: $modal-header-border-width solid rgba(0, 0, 0, 0);
		border-top-left-radius: $modal-border-radius;
		border-top-right-radius: $modal-border-radius;
		padding: 0.875rem;
		align-items: $modal-header-align-top;
		@include border-top-radius($card-border-radius);

		@media (min-width:1560px) {
			padding: $modal-header-padding-y $modal-header-padding-x;
		}

		.dropdown {
			.dropdown-toggle {
				color: inherit;
			}
		}

		&.pmd-modal-border {
			border-bottom: $modal-header-border-width solid $modal-header-border-color;
			padding-bottom: $modal-header-padding-y;
			margin-bottom: 0;
		}
	}

	.modal-footer {
		padding: 0.875;

		@media (min-width:1560px) {
			padding: $modal-spacer;
		}

		&.pmd-modal-border {
			border-top: $modal-action-border-width solid $modal-action-border-color;
			padding-top: $modal-header-padding-y;
			margin-top: 0;
		}

		.btn {
			margin-right: $card-actions-spacer-x;
			margin-left: $card-actions-spacer-x;
			min-width: inherit;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}

			&.pmd-btn-flat {
				margin-top: -$btn-padding-y;
				margin-bottom: -$btn-padding-y;

				&:first-child {
					margin-left: -$card-actions-padding-y;
				}
			}

			&.pmd-btn-fab {
				padding: 0;
			}
		}
	}

	.modal-body {
		margin-bottom: $modal-spacer-y;
		margin-top: $modal-spacer-y;
		padding: 0 $modal-spacer-x;

		>p:last-child {
			margin-bottom: 0;
		}

		.custom-control {
			padding: .75rem $modal-spacer-y;
			margin-left: -$modal-spacer-y;
			margin-right: -$modal-spacer-y;
		}
	}

	.pmd-modal-list {
		margin-bottom: $modal-spacer-y;
		margin-top: $modal-spacer-y;
	}

	.modal {

		.radio,
		.checkbox {
			margin: $modal-radio-checkbox-spacer-y 0;
		}

		.radio-options {
			>label {
				padding-left: 32px;
			}
		}

		.list-group.pmd-list-avatar {
			margin-bottom: $modal-list-avatar-margin-bottom;
			padding: 0
		}

		&.list-group {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	/* Form css */
	.form-horizontal {
		.form-group {
			margin-left: 0;
			margin-right: 0;
		}
	}

	// Card list border
	.pmd-list {
		margin-bottom: 0.5rem;
		margin-top: 0.5rem;

		&.pmd-list-border {
			.list-group-item {
				border-right: 0;
				border-left: 0;
				border-radius: 0;
			}

			&:first-child {
				.list-group-item {
					&:first-child {
						border-top: 0;
					}
				}
			}

			&:last-child {
				.list-group-item {
					&:last-child {
						border-bottom: 0;
					}
				}
			}
		}
	}

	// Member Modal
	&.pmd-profile-modal {
		.modal-body {
			padding: 0;
			margin: 0;
		}

		.pmd-profile-modal-header>* {
			position: relative;
			z-index: 10;
		}

		.pmd-profile-modal-header {
			position: relative;
			background-color: $profile-modal-header-bg;
			text-align: left;
			padding: $profile-modal-header-padding-y $profile-modal-header-padding-x;

			.pmd-profile-modal-header-bg-img {
				position: absolute;
				left: 0;
				width: 100%;
				height: 100%;
				top: 0;
				z-index: 1;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				-webkit-filter: $profile-modal-header-bg-blur;
				filter: $profile-modal-header-bg-blur;
				opacity: $profile-modal-header-bg-opacity;
			}

			.pmd-avatar-circle {
				border-radius: 50%;
				border: $profile-modal-header-avatar-border-size solid $profile-modal-header-avatar-border-color;
				display: inline-block;
				width: $profile-modal-header-avatar-size;
				height: $profile-modal-header-avatar-size;
				overflow: hidden;
				float: left;
				margin: 10px $profile-modal-header-padding-x 0 0;
				background: $profile-modal-header-avatar-bg;
				box-shadow: $profile-modal-header-avatar-box-shadow;

				img {
					display: block;
					max-width: 100%;
					height: auto;
				}
			}

			.pmd-profile-modal-info {
				.card-title {
					margin-top: $space24;
				}
			}
		}

		.pmd-profile-modal-bio {
			padding: 15px $profile-modal-header-padding-x $profile-modal-header-padding-x;
			clear: both;
		}
	}

	// Close icon
	.btn-close {
		background-size: $modal-close-background-size;
		outline: none;
		box-shadow: $modal-close-box-shadow;
		float: right;
	}
}

.pmd-body-open {
	&.modal-open {
		overflow: hidden;
	}
}

.modal {
	&[aria-labelledby="dialog-nested-name1"] {
		z-index: 1056;
	}

	&[aria-labelledby="dialog-nested-name2"] {
		z-index: 1057;
	}

	&[aria-labelledby="dialog-nested-name3"] {
		z-index: 1058;
	}
}

.modal-backdrop {
	background-color: $modal-overlay-bg;

	&.show {
		opacity: $modal-overlay-opacity;
	}

	&:nth-of-type(2) {
		z-index: 1056;
	}

	&:nth-of-type(3) {
		z-index: 1057;
	}
}