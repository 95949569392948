// Invoice Form
.select-invoice-type .pmd-radio {
  @include media-breakpoint-down(md) {
    margin-top: 0.5rem;
  }

  .form-check-input {
    ~.form-check-label {
      strong {
        @include media-breakpoint-down(md) {
          font-size: $font-size-sm;
        }
      }

      .text-muted {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }
}

// Detailed Invoice
.ng-select.ng-select-opened .ng-select-container {
  width: 100%;
}

.preview-invoice-thumb {
  margin-top: -45px;
}

// Preview Invoice
.preview-invoice-card {
  max-width: 700px;
  width: 100%;

  @include media-breakpoint-down(sm) {
    font-size: $font-size-sm;

    h4,
    .h4 {
      font-size: $font-size-base;
    }
  }

  .card-body {
    padding-left: $table-cell-padding-x;
    padding-right: $table-cell-padding-x;
  }

  // Invoice Header
  .card-header {
    padding-left: $table-cell-padding-x;
    padding-right: $table-cell-padding-x;

    .pmd-avatar-list-img {
      height: 5rem;
      width: 5rem;
      border-radius: $border-radius;
      padding: 0.5rem;

      @include media-breakpoint-down(sm) {
        height: 3rem;
        vertical-align: top;
        width: 3rem;
        padding: 0.125rem;
      }
    }

    .invoice-type-no {
      @include media-breakpoint-down(sm) {
        padding-left: 3.75rem;
      }
    }

    .card-subtitle {
      max-width: 250px;
    }

    .pmd-display4 {
      font-size: 1.75rem;
      font-weight: $font-weight-light;
    }

    .invoice-title {
      font-weight: $font-weight-semibold;
      margin-top: -0.25rem;
    }

    // Dark Header
    &.bg-dark {
      .pmd-avatar-list-img {
        background-color: $white;
      }

      .card-title,
      .invoice-title {
        color: $white;
      }

      .card-subtitle {
        color: rgba($white, 0.74);
      }

      .pmd-display4 {
        color: rgba($white, 0.54);
      }
    }

    // Light Header
    &.bg-light {
      .pmd-avatar-list-img {
        background-color: $dark;
      }
    }
  }

  // Customer Details
  .customer-details {
    .customer-name {
      &+p {
        font-size: $font-size-sm;
      }
    }

    .invoice-title {
      font-size: $font-size-sm;
      font-weight: $font-weight-semibold;
    }

    .invoice-dates {
      margin-right: -2rem;

      .pmd-list-item {
        padding-right: 1rem;
        margin-right: 1rem;
      }
    }

    .due-amount {
      .pmd-list-title {
        font-size: 1.5rem;
        font-weight: $font-weight-light;

        @include media-breakpoint-down(sm) {
          font-size: 1.25rem;
        }

        b {
          font-weight: $font-weight-bold;
        }
      }
    }
  }

  // Invoice Items
  .invoice-item-details {
    .pmd-card {
      border-radius: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
      box-shadow: none;
      padding: 0;

      .pmd-table {
        &.pmd-table-card {
          border: none;
          box-shadow: none;
          border-radius: 0;

          tr {
            @include media-breakpoint-down(sm) {
              margin-bottom: 0;
              box-shadow: none;
              border-radius: 0;

              &.invoice-amount-breakup {
                td {
                  border-left: 0;
                  border-right: 0;
                }
              }
            }

            &.invoice-item {
              td {
                @include media-breakpoint-down(sm) {
                  border: 0;

                  &.item-title {
                    border-top: 1px solid $border-color;
                  }
                }
              }
            }

            td {
              &.item-title {
                @include media-breakpoint-down(sm) {
                  font-size: $font-size-base;
                  font-weight: $font-weight-semibold;
                  text-align: left;
                }
              }

              &[data-title]:before {
                @include media-breakpoint-down(sm) {
                  line-height: 2;
                }
              }
            }
          }

          tfoot {
            @include media-breakpoint-down(sm) {
              display: block;

              .footer-label {
                display: none;
              }

              .narratives {
                padding: 0;
              }
            }
          }

          td,
          th {
            &:first-child {
              border-left: 0;
            }

            &:last-child {
              border-right: 0;
            }
          }
        }

        &.table-bordered> :not(caption)>* {
          border-width: 0;
        }

        th {
          font-size: 10px;
          white-space: nowrap;
          font-weight: $font-weight-semibold;
          background-color: rgba($primary, 0.1);

          &.item-name {
            @include media-breakpoint-down(sm) {
              min-width: 300px;
            }
          }
        }

        .invoice-amount-breakup {
          &:first-child td {
            padding-top: 12px;
          }

          &:nth-last-child(2) td {
            padding-bottom: 12px;
          }

          td {
            padding-bottom: 0;
            padding-top: 6px;
          }

          .amount {
            span {
              font-weight: $font-weight-medium;
              color: $dark;
            }
          }

          span {
            display: flex;
            justify-content: end;
            margin: 0.25rem 0;
            color: $body-color;
          }
        }

        .invoice-grand-total {
          td.grand-total-amount {
            background-color: rgba($secondary, 0.12);

            .pmd-list-subtitle {
              font-size: $font-size-base;
              color: $body-color;
            }

            .pmd-list-title {
              font-size: 1.5rem;
              font-weight: $font-weight-light;

              @include media-breakpoint-down(sm) {
                font-size: 1.25rem;
              }

              b {
                font-weight: $font-weight-bold;
              }
            }
          }
        }
      }
    }
  }

  // Invoice Footer
  .card-footer {
    margin-top: 1rem;
    padding-bottom: 1rem;
    padding-left: $table-cell-padding-x;
    padding-right: $table-cell-padding-x;

    &.pmd-card-border {
      padding-top: $table-cell-padding-x;
    }
  }
}

// Invoice List
.htmlpopover {
  .info-tp-holder {
    top: 16px;
    right: 43px;
    z-index: 11111;
    cursor: pointer;
  }
}

.pmd-modal {
  .modal-sm {
    max-width: 450px;
  }
}

// View Invoice
.dark-head-view-invoice {
  margin-top: -23px;

  @media (max-width: 767px) {
    margin-top: 16px;
  }
}

.ng-orange-light {
  background-color: #fdf1d3;
}

@media (max-width: 991px) {
  .payemnt-received-table .pmd-card {
    max-width: 700px;
    width: 100%;
  }

  .topbar-card {
    border-radius: 0.5rem;
  }
}

.pf-column-name {
  .pmd-avatar {
    i {
      display: none;
    }
  }
}

.draft-listrow {

  .invoice-number,
  .amount-due {
    .card-subtitle {
      display: none;
    }
  }

  .pf-column-name {
    .pmd-avatar {
      i {
        display: block;
        opacity: 0.34;
      }
    }

    .card-title {
      font-weight: normal;
      color: #445474;
      opacity: 0.54;
    }
  }
}

.pf-total-received {
  background-color: rgba(26, 212, 134, 0.12);
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  flex-grow: 1;
}

.invoice-list-tabs {
  &.reviewer-details {
    @include media-breakpoint-down(sm) {
      .pmd-tabs {
        padding-top: 0;
      }
    }
  }

  .tab-upload {
    .pmd-filter-row.multi-filter-row .pmd-input-group-outline .pmd-select .ng-select-container {
      width: 42%;
    }
  }

  .tab-reviewed {
    .pmd-filter-row.multi-filter-row .pmd-input-group-outline .pmd-select .ng-select-container {
      width: 42%;
    }
  }

  .tab-invalid {
    .pmd-filter-row.multi-filter-row .pmd-input-group-outline .pmd-select .ng-select-container {
      width: 64%;
    }
  }

  @include media-breakpoint-down(md) {

    // td{
    //     flex-basis: 33.33%;
    // }
    // td.pf-column-name{
    //     width: 100%;
    //     flex-basis: 100%;
    // }
    .columnAction {
      position: absolute;
      right: 40px;
      /* top: 0; */
      padding-top: 12px !important;

      .dropdown-toggle {
        margin-top: 0 !important;
      }
    }

    td.pf-column-name {
      width: 100%;

      h6 {
        max-width: 290px;
        flex-basis: calc(100% - 100px) !important;
        font-size: 14px;
        word-break: break-all;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    td {

      // flex-basis: 100%;
      &.amount {
        text-align: left !important;
      }
    }

    td.pf-column-name {
      h6 {
        width: calc(100% - 100px) !important;
        flex-basis: calc(100% - 100px) !important;
        font-size: 13px;
        word-break: break-all;

        @include media-breakpoint-down(sm) {
          width: 140px !important;
        }
      }
    }
  }
}

.tab-content {
  position: relative;
}

.search-upload {
  position: absolute;
  top: -70px;
  right: 25px;
  width: auto;
  z-index: 9;

  &.mobile-search-area {
    top: unset;
    right: unset;
    margin-bottom: 15px;
    justify-content: flex-end;

    @include media-breakpoint-down(xs) {
      justify-content: flex-start !important;
      flex-direction: column;
      align-items: flex-start;

      >.d-flex {
        width: 100% !important;
        flex-direction: column;
        margin-bottom: $space8;

        label {
          margin-bottom: 5px;
        }

        .selection-dropdown-group {
          width: 100%;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    top: -115px;
  }

  .pmd-filter-row {
    width: 100%;
    max-width: 300px;
    margin-right: 15px;

    @include media-breakpoint-down(sm) {
      width: calc(100% - 102px);
      max-width: none;
    }
  }

  .pmd-select.pmd-select-outline .ng-select-container {
    padding: 6px 0;
  }

  .pmd-filter-row.multi-filter-row .pmd-input-group-outline .input-group-text {
    height: 36px;
  }

  .pmd-filter-row.multi-filter-row .pmd-input-group-outline .pmd-select .ng-select-container {
    width: auto;
  }
}

.btn-upload-sm {
  padding: 7px 12px;
  font-size: 12px;
  white-space: nowrap;
  margin-right: 15px;

  svg {
    display: inline-flex;

    path {
      fill: #ffffff;
    }
  }

  @include media-breakpoint-up(xl) {
    svg {
      display: none;
    }
  }

  @include media-breakpoint-down(lg) {
    padding: 6px 8px;
    border-radius: 50%;

    span {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 4px 6px;

    svg {
      width: 15px;
      height: 18px;
    }
  }
}

.pmd-input-group-outline.pmd-input-group-icon .input-group-text~.pmd-select {
  padding-left: 38px;
}

.shorting-btn {
  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
  }
}

.bottom-fixed-bar-shown {
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.row-filter-search {
  @include media-breakpoint-down(lg) {
    .pmd-filter-col.pe-lg-0 {
      display: none !important;
    }
  }

  @include media-breakpoint-down(sm) {
    .col-2 {
      margin-bottom: 0 !important;
    }
  }

  @media only screen and (max-width: 577px) {
    .pmd-filter-col.pe-lg-0 {
      display: none !important;
    }
  }

  .search-by-block {
    background-color: #fff;
    padding-left: 1rem;
    margin-right: 1rem;
  }
}

.form-control:focus {
  outline: none !important;
}

.duration-dropdown {
  .clear-input {
    right: 33px;
    left: auto;
    opacity: 0.6;
    width: 15px;
    background: transparent;
  }

  .pmd-input-group-outline {
    background-color: transparent !important;
    // width: 240px;

    .form-control {
      cursor: pointer;
    }
  }

  .icon-right-absolute {
    position: absolute;
    right: -40px;
    top: 2px;
    border: none;
  }
}

.form-control {
  font-size: 0.75rem;

  @media(min-width: 1560px) {
    font-size: 0.875rem;
  }
}

.pmd-input-group-outline .pmd-select .ng-placeholder {
  font-weight: normal;
  color: $gray-700 !important;
  // font-size: inherit;
}

.invoice-details-wrap {
  padding: 20px;
  border-left: 1px solid #e1e1e1;
  position: relative;
  // background-color: #ffffff;

  // &:before {
  //     content: '';
  //     position: absolute;
  //     left: 15px;
  //     height: calc(100% + 4px);
  //     width: 1px;
  //     background: #e4e4e4;
  //     display: block;
  //     top: -2px;
  // }

  @include media-breakpoint-down(sm) {
    border: none;

    &:before {
      display: none;
    }
  }
}

.invoice-gallery {
  position: relative;

  .product-gallery__featured {
    border-radius: 8px;
    // border: solid 1px #F0F3F4;
    // background-color: #fff;
    padding: 0 20px;
    // background: #f7f7f7;

    @include media-breakpoint-down(md) {
      // padding: 0;
      border: none;
      background-color: transparent;
    }

    img {
      border: 1px solid #ccc;
      border-radius: 8px;
    }

    .ngxImageZoomContainer {
      // min-height: 450px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      // overflow: unset;
    }
  }

  .ngxImageZoomFullContainer {
    overflow: visible !important;
    height: 100% !important;
  }

  .product-gallery__carousel {
    margin-top: 9px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // overflow: hidden;
    margin-right: -2px !important;
    // max-width: 285px;
    margin: 20px auto;

    @media only screen and (max-width: 577px) {
      // width: 200px !important;
    }

    owl-carousel-o img {
      border-radius: 8px;
      border: solid 1px #f0f3f4;
      object-fit: cover;
      max-height: 80px;
    }

    .owl-carousel {
      // margin-right: -15px;
      // width: 260px;

      // @media screen and (max-width: 1280px) {
      //   width: 190px;
      // }

      .owl-item {
        width: 110px;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;

        @media screen and (max-width: 1280px) {
          height: 65px;
        }

        a {
          padding: 5px;
          background: #f7f7f7;
          border-radius: 5px;
          align-items: center;
          display: flex;
          height: 100%;
          width: 100%;
          justify-content: center;
          border: 1px solid #ccc;
        }

        .product-gallery__carousel-item--active {
          border: 2px solid $yellow;
          box-shadow: 0px 0px 9px 0px #e3e3e3;
        }
      }
    }

    .custom-slider-btn-container {
      position: absolute;
      width: 100%;
      display: flex;
      justify-content: space-between;
      z-index: 0;
      bottom: 20px;

      @include media-breakpoint-down(lg) {
        bottom: -50px;
        width: 90px;
        z-index: 9;
      }

      @media only screen and (max-width: 577px) {
        width: 100px;
      }
    }

    .owl_pagination {
      .pmd-select.pmd-select-outline {
        width: 66px;
        padding: 0 8px !important;

        .ng-select-container {
          padding: 6px 0 !important;
        }
      }

      .pmd-select.pmd-select-outline.ng-select-focused,
      .pmd-select.pmd-select-outline.ng-select-opened {
        border-width: 1px !important;
      }

      .ng-dropdown-panel {
        width: 80px;
        top: 2.5rem;
        overflow: unset;
        height: max-content;
        z-index: 2024 !important;
      }

      .pmd-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        padding: 0.75rem 1rem;
        text-align: left;
      }

      .ng-dropdown-panel-items::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5;
        border-radius: 10px;
      }

      .ng-dropdown-panel-items::-webkit-scrollbar {
        width: 4px;
        background-color: #f5f5f5;
      }

      .ng-dropdown-panel-items::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #f1f1f1;
        border: 2px solid #8e8e8e;
      }
    }
  }

  @media screen and (max-width: 1280px) {
    button.pmd-btn-fab {
      width: 24px !important;
      height: 24px !important;
      min-width: 24px !important;

      i {
        font-size: 16px;
        line-height: 20px !important;

        @include media-breakpoint-up(lg) {
          line-height: 27px !important;
        }
      }
    }
  }

  .product-gallery__carousel-item--active {
    border: solid 2px $yellow !important;
  }

  .owl-carousel {
    .owl-item {
      >img {
        border-radius: 10px;
        border: 1px solid $blue;
      }
    }
  }

  owl-carousel-o::ng-deep {
    img {
      border-radius: 8px;

      @media only screen and (max-width: 577px) {
        // width: 50px !important;
      }
    }
  }
}

.invoice-details {
  @include media-breakpoint-up(md) {
    margin-right: 12px;
    // margin-top: 60px;
  }
}

.cta-invoice {
  button {
    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }
  }

  @include media-breakpoint-down(lg) {
    margin-left: $space6 !important;
    margin-right: $space6 !important;
    justify-content: center !important;
  }
}

.pmd-sidebar-with-brand~.pmd-content {
  @media only screen and (max-width: 992px) and (min-width: 768px) {
    // padding-left: 0;
    // padding-right: 0;
    padding-top: 85px;
  }
}

.extra-label {
  font-size: $space14;
}

.delete-details {
  color: inherit !important;
  font-weight: inherit !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(lg) {
    top: 6px;
    right: 50px;
    z-index: 9;
    position: absolute;
  }

  &:focus {
    border-color: $light !important;
  }

  .pmd-btn-outline-secondary {
    background-color: #faf0cc;
    padding: 6px 14px;
    font-size: 12px;
  }

  .khr-extras-column-list {
    min-width: 314px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: $dropdown-box-shadow;
    position: absolute;
    cursor: grab;
    height: fit-content;
    z-index: 2025;
    top: 2.25rem;
    bottom: 0;
    right: 0;

    .extras_header {
      background-color: #faf0cc;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .pmd-checkbox .form-check-label {
      font-size: 12px;
    }

    .draggable-list-fix {
      max-height: 350px;
      overflow-x: auto;
    }
  }

  .ng-resizable-diagonal {
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.27577 10.7194C9.13295 10.7194 8.99335 10.677 8.87461 10.5977C8.75587 10.5183 8.66332 10.4055 8.60867 10.2736C8.55402 10.1416 8.53972 9.99643 8.56757 9.85635C8.59543 9.71627 8.66419 9.5876 8.76516 9.4866L9.48738 8.76437C9.62281 8.62876 9.80655 8.5525 9.99821 8.55236C10.1899 8.55223 10.3737 8.62823 10.5093 8.76365C10.6449 8.89907 10.7212 9.08282 10.7213 9.27447C10.7215 9.46613 10.6455 9.64998 10.5101 9.7856L9.78783 10.5078C9.65242 10.6433 9.46875 10.7194 9.27722 10.7194H9.27577ZM5.20388 10.7194C5.06106 10.7194 4.92146 10.677 4.80272 10.5977C4.68398 10.5183 4.59143 10.4055 4.53678 10.2736C4.48213 10.1416 4.46783 9.99643 4.49568 9.85635C4.52354 9.71627 4.5923 9.5876 4.69327 9.4866L9.48738 4.69248C9.62281 4.55687 9.80655 4.48061 9.99821 4.48047C10.1899 4.48034 10.3737 4.55634 10.5093 4.69176C10.6449 4.82719 10.7212 5.01093 10.7213 5.20259C10.7215 5.39424 10.6455 5.57809 10.5101 5.71371L5.71594 10.5078C5.58053 10.6433 5.39686 10.7194 5.20533 10.7194H5.20388ZM0.986106 10.7194C0.843287 10.7194 0.703682 10.677 0.584942 10.5977C0.466201 10.5183 0.373656 10.4055 0.319006 10.2736C0.264355 10.1416 0.250053 9.99643 0.277907 9.85635C0.305762 9.71627 0.374522 9.5876 0.475495 9.4866L9.48666 0.475429C9.55372 0.408327 9.63333 0.35509 9.72096 0.318757C9.80859 0.282423 9.90252 0.263705 9.99738 0.263672C10.0922 0.263638 10.1862 0.28229 10.2738 0.318561C10.3615 0.354833 10.4411 0.408014 10.5082 0.475068C10.5753 0.542123 10.6286 0.621737 10.6649 0.709366C10.7013 0.796995 10.72 0.890922 10.72 0.985785C10.72 1.08065 10.7014 1.17459 10.6651 1.26224C10.6288 1.3499 10.5757 1.42955 10.5086 1.49665L1.49672 10.5078C1.36131 10.6433 1.17764 10.7194 0.986106 10.7194Z' fill='%23494C4E'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: cover;
    border: 0;
  }

  .ng-resizable-handle.ng-resizable-se {
    width: 12px;
    height: 12px;
    right: 6px;
    bottom: 6px;
  }
}

.khar_custom_checkbox {
  display: flex;
  cursor: pointer;

  .form-check-input {
    width: 18px;
    height: 18px;
    margin-top: 0;
    vertical-align: middle;

    &:checked {
      background-color: $secondary;
      border-color: $secondary;

      &:focus {
        border-color: $secondary;
      }
    }

    &:focus {
      box-shadow: none;
      border-color: $border-color;
    }
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.verticle-align-middle {
  vertical-align: middle;
}

.highlighted {
  background-color: rgb($danger, 10%) !important;
}

.highlightable:hover {
  cursor: pointer;
}

.highlight {
  background-color: rgba($yellow, 0.1) !important;
  cursor: pointer;
}

.to-be-delete {
  th {
    padding-top: 5px !important;
    padding-bottom: 5px;
    text-align: center;
  }
}

.w-30 {
  width: 30px !important;
  min-width: 30px !important;

  @include media-breakpoint-down(md) {
    >.d-lg-flex {
      margin-bottom: 10px;
    }

    i {
      top: 7px !important;
    }
  }
}

.icon-delete-check {
  cursor: pointer;
}

table {
  tr {
    &:hover {
      background-color: rgb(44 62 80 / 3%) !important;
    }
  }

  td {
    position: relative;

    .icon-delete-check {
      @include media-breakpoint-down(md) {
        position: absolute;
        top: 6px;
        right: 10px;
      }
    }

    @include media-breakpoint-up(lg) {

      input,
      textarea {
        border: 1px solid transparent !important;
        padding: 5px 8px !important;
        line-height: normal !important;

        &:focus {
          border: 1px solid #b0b0b0 !important;
          padding: inherit;
        }
      }
    }
  }

  .ng-select .ng-select-container .ng-value-container {
    flex: initial;
  }
}

@include media-breakpoint-down(md) {
  .accordion-item {
    .pmd-table-card-md.pmd-table.table tr td {
      flex-basis: 100%;
    }
  }
}

// .invalid-feedback {
//     white-space: nowrap;
// }

.business-desc {
  .material-icons {
    font-size: $space14;
    font-weight: bold;
  }
}

.business-list-wrap {
  .title-section {
    @include media-breakpoint-down(sm) {
      justify-content: flex-start !important;
      flex-direction: column !important;
      align-items: flex-start !important;
    }
  }

  .duration-dropdown {
    @include media-breakpoint-down(sm) {
      justify-content: flex-start !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      margin-top: $space10;
    }
  }

  .columnAction {
    padding-top: 20px !important;
  }
}

.business-stastic {
  .card-title {
    line-height: normal;

    @include media-breakpoint-down(xs) {
      margin-bottom: 5px !important;
    }
  }

  @include media-breakpoint-down(sm) {
    .pmd-avatar {
      height: 36px;
      width: 36px;

      span {
        font-size: 20px;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    >.d-flex {
      flex-direction: column;
      align-items: flex-start !important;
    }

    .pmd-avatar {
      margin-bottom: 5px;
    }
  }
}

.pmd-tabs {
  border-bottom: 0.5px solid #e4e4e4;

  .nav-tabs {
    width: auto !important;

    .nav-link {
      font-size: 12px;
      font-weight: 600;
      color: $gray-500;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
      padding: 1.15rem 1rem;

      @media (min-width:1560px) {
        font-size: 13px;
      }

      @include media-breakpoint-down(md) {
        padding: 1.5rem $space12 1.3rem;
      }

      @media (min-width:1560px) {
        font-size: 13px;
      }

      text-transform: none;

      &.active {
        color: $secondary;
        position: relative;

        &:after {
          content: "";
          width: 100%;
          height: 2px;
          position: absolute;
          display: block;
          background: $yellow;
          border-radius: 10px 10px 0 0;
          bottom: 0;
        }
      }

      &:hover {
        color: $secondary;
      }
    }
  }

  .pmd-tab-active-bar {
    display: none;
  }
}

.tab-pane {
  &.px-4 {
    padding: 0 15px !important;
  }
}

.bi-pencil-square {
  cursor: pointer;
}

.btn.disabled i,
.btn:disabled i,
fieldset:disabled .btn i {
  opacity: 0.3;
}

.icon-edit {
  cursor: pointer;
}

.clear-selection {
  padding: inherit !important;
}

.invoice-img-wrap {
  padding-bottom: 15px !important;
  border-radius: 8px 0 0 8px;
}

.chart-empty {
  min-height: 365px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 20px;

  @include media-breakpoint-up(lg) {
    border: 1px solid rgb(44 62 80 / 6%);
  }
}

.ng-select.ng-select-multiple {
  .ng-value-container {
    align-items: center;
  }

  .ng-arrow-wrapper {
    top: 0;
    display: flex !important;
    align-items: center;
  }
}

.product-table {
  .pmd-input-group-outline>.pmd-textfield {
    text-wrap: nowrap;
  }

  .pmd-select .ng-arrow-wrapper .ng-arrow {
    top: 5px;
  }

  .pmd-icon-xs {
    line-height: 17px;
  }
}

.pmd-select.khr-select-in-table {
  font-size: 13px !important;

  .ng-value-container {
    width: 100%;
  }

  .ng-input input {
    padding: 0 !important;
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      border: none !important;
    }
  }

  .ng-arrow-wrapper {
    left: -2px;
  }

  &.ng-select-opened .ng-arrow-wrapper .ng-arrow {
    top: 2px;
  }

  &.ng-select {
    max-width: 300px;
    // border-color: transparent;
    background-color: rgba(41, 128, 185, 0);
  }

  &.ng-select-opened.ng-select {
    border-color: $yellow;
    background-color: rgba(41, 128, 185, 0.06);
  }

  &.pmd-select:not(.pmd-select-dark) .ng-value-label {
    color: #2c3e50;
  }
}

/* New CSS */
#extraTotalSection .pmd-input-group-outline.input-group-sm .form-control:not(textarea) {
  // height: calc(1.5rem + 1.1rem);
  height: 100%;
  min-width: 90px;
}

.khr-auto-field {

  //     .pmd-input-group-outline .pmd-select .ng-placeholder,
  //   .ng-select.ng-select-single .ng-select-container .ng-value-container,
  .ng-select .ng-select-container,
  .input-group:not(.pmd-input-group-icon)>.pmd-select {
    width: auto !important;
    max-width: 135px !important;
    height: calc(1.2em + 1.1rem);
    min-width: 90px;
  }

  .pmd-input-group-outline .pmd-select .ng-placeholder,
  .pmd-input-group-outline .pmd-select .ng-value {
    font-size: 12px !important;
    text-transform: capitalize !important;
    color: $pmd-theme-primary !important;
  }

  &.pmd-select.pmd-select-outline.ng-select-multiple .ng-value {
    margin: 0 0.25rem 0.1rem 0;
  }

  .input-group-append .input-group-text {
    padding: 3px 3px 3px 4px;
    height: 33.2px;
  }

  @include media-breakpoint-down(xs) {
    width: 100% !important;

    .pmd-select,
    .input-group:not(.pmd-input-group-icon)>.pmd-select {
      max-width: 108px !important;
    }

    .pmd-textfield {
      width: 100%;
      margin-left: 0.2px !important;
      flex: 4;
    }

    .input-group-text {
      margin-left: 0.2px !important;
      height: 33.2px !important;
    }

    .ng-select-container {
      max-width: 100% !important;
    }
  }
}

.khr-extra-field {
  .col-form-label-sm {
    padding: 0;
  }

  .pmd-select {
    color: #2980b9;
    border-right: 0;
    border-radius: 8px 0 0 8px;
    font-size: 12px !important;
    max-width: 130px;
    height: 33.2px;
  }
}

.khr-extra-field {
  .col-form-label-sm {
    padding: 0;
  }

  .pmd-select {
    color: #2980b9;
    border-right: 0;
    border-radius: 8px 0 0 8px;
    font-size: 12px !important;
    max-width: 130px;
    height: 33.2px;
  }

  .khr-extra-label {
    font-size: 12px;
    padding-left: calc(0.75rem - 1px);
    padding-right: calc(0.75rem - 1px);
    max-width: 130px;
    width: 107px;
    background-color: rgba(41, 128, 185, 0.06);
    height: 33.2px;
    display: block;
    text-transform: capitalize !important;
    border: 1px solid #b0b0b0;
    border-radius: 8px 0 0 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #2980b9;
    line-height: 31px;
    font-weight: 500;
  }
}

.product-table {
  .pmd-input-group-outline>.pmd-textfield {
    text-wrap: nowrap;
  }

  .pmd-select .ng-arrow-wrapper .ng-arrow {
    top: 7px;
  }

  .pmd-icon-xs {
    line-height: 17px;
  }
}

.extra-custom.ng-select-opened .ng-arrow-wrapper .ng-arrow {
  top: 3px;
}

.tag-select input[type="text"] {
  padding: 0 !important;
  border: none;
}

.khr-invoice-dd {
  &.pmd-select.pmd-select-outline.ng-select-multiple .ng-has-value {
    padding-bottom: calc(0.5rem - 1px);
    margin-bottom: -3px;
  }

  &.pmd-select.pmd-select-outline.pmd-select-sm .ng-select-container {
    padding-top: calc(0.25rem - 1px);
    padding-bottom: calc(0.2rem - 1px);
    display: flex;
    align-items: center;
    min-height: 33px;
  }

  &.pmd-select.pmd-select-outline.pmd-select-sm.ng-select-focused .ng-select-container,
  &.pmd-select.pmd-select-outline.pmd-select-sm.ng-select-opened .ng-select-container {
    padding-top: calc(0.25rem - 2px);
    padding-bottom: calc(0.2rem - 2px);
  }

  &.ng-select.ng-select-multiple .ng-arrow-wrapper {
    top: -2px;
  }
}

.accordion .btn.khr-duplicate-btn {
  padding: 0.5rem 0.5rem;
  font-size: 0.75rem !important;
  line-height: 1.8;
  text-transform: uppercase !important;
}

.active-tds {
  background: rgba($secondary, 0.1);
}

@media only screen and (max-width: 1326px) {
  .invoice-header {
    display: flex;
    flex-wrap: wrap;
  }

  .khr-add-more {
    display: flex !important;

    i {
      position: relative !important;
      margin-top: -7px;
      margin-left: 3px;
    }

    span {
      margin-left: -5px;
    }
  }

  .delete-details .pmd-icon-xs.pmd-accordion-arrow {
    right: -4px;
    width: 0;
  }

  .status-badge {
    margin-top: 10px !important;
  }

  .khr-import-btn .btn {
    padding: 5px 10px;
  }

  .invoice-review-accordion .pmd-accordion-header .accordion-button {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  .invoice-review-accordion .khr-missign-data {
    border: none;
    margin-top: 5px;
    padding-left: 0;
    z-index: 0;
  }

  .invoice-review-accordion .pmd-accordion-arrow {
    position: absolute;
    right: 7px;
    top: 4px;
  }

  .invoice-review-accordion.accordion .btn.khr-duplicate-btn {
    right: 30px;
  }

  .invoice-thumb-img {
    max-width: 30px;
  }
}

@media only screen and (max-width: 820px) {
  .status-badge {
    flex-wrap: wrap;

    .khr-duplicate-btn {
      margin-top: 10px;
      padding: 5px;
    }
  }

  .w-max-content {
    min-width: 270px !important;
  }

  .khr-add-more {
    display: flex !important;

    i {
      position: relative !important;
      margin-top: -7px;
      margin-left: 3px;
    }

    span {
      margin-left: -5px;
    }
  }

  .invoice-review-accordion {
    .pmd-accordion-header .accordion-header .accordion-button {
      display: block;
    }

    .pmd-accordion-arrow {
      position: absolute;
      right: 7px;
      top: 4px;
    }

    &.accordion .btn.khr-duplicate-btn {
      position: relative;
      right: 0px;

      @include media-breakpoint-up(lg) {
        right: -10px;
        top: 5px;
      }
    }

    pmd-alert.khr-missign-data {
      margin-left: 0;
      margin-bottom: 10px;
      line-height: 15px;
      margin-top: 10px;
    }
  }
}

@media only screen and (max-width: 767px) {

  .khr-import-btn {
    position: relative;
    right: 0;
    top: -9px;
  }

  .status-badge .khr-duplicate-btn {
    margin-top: 0;
  }

  .invoice-header {
    display: block;
  }

  .pmd-filter-row .status-dropdown-custom .pmd-select .ng-select-container {
    padding-right: 0;
  }

  .switch-toggle .button {
    @include media-breakpoint-up(md) {
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 450px) {
  .status-badge .khr-duplicate-btn {
    margin-top: 10px;
    margin-left: 0 !important;
  }
}

.admin-user.khr-tds-btn-group {
  position: relative;
  top: 0;
  right: 0;
}

.khr-tds-btn-group {
  .ng-select {
    min-width: 130px;
    cursor: pointer;

    .ng-select-container .ng-value-container {
      align-items: center;
      min-height: unset;
    }
  }
}

.tds-tcs-select {
  .ng-select {
    min-width: 110px;
  }
}

.khr-calc-grp {
  .khr-add-icon {
    position: absolute;
    right: -4px;
    top: 7px;
    margin: 0;
    z-index: 9;
    background: #fff;
    width: 10px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.khr-equal-icon {
  position: absolute;
  left: -10px;
  top: 6px;
}

.mx-w50 {
  max-width: 50% !important;
}

.pmd-input-group-outline .pmd-select .ng-placeholder {
  font-size: 12px;
}

.khr-calc-grp .ng-select {
  height: 33px;
}

@media only screen and (max-width: 1100px) {
  .khr-tds-btn-group.admin-user {
    margin-left: 0;

    .btn-sm {
      padding: 2px 5px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .khr-gross-acc {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 10px;
  }

  .khr-calc-grp.khr-gross-acc .khr-equal-icon {
    position: relative;
    left: 0;
    top: 6px;
    right: 0;
    margin: 0 auto;
    width: 30px;
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }

  .khr-payble {
    margin-top: 15px;
  }

  .khr-tds-btn-group .btn-sm {
    padding: 5px 10px;
  }
}

@media only screen and (max-width: 1023px) {
  .khr-calc-grp .col-md-4 {
    width: 100%;
  }

  .khr-calc-grp .khr-equal-icon,
  .khr-calc-grp .khr-add-icon {
    position: relative;
    left: 0;
    top: 6px;
    right: 0;
    margin: 0 auto;
    width: 30px;
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .khr-calc-grp .ng-select {
    height: 33.5px;
  }

  .khr-gross-acc {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .mt-xs-10 {
    margin-top: 10px;
  }

  .khr-tds-btn-group {
    right: 25px;
    top: 10px;
  }

  .pe-xs-0 {
    padding-right: 0 !important;
  }

  .pt-xs-2 {
    padding-top: 15px;
  }
}

.column-sorting {
  .active {
    opacity: 0.7;
    color: $yellow;
  }
}

.kh-invoice-page-info {
  padding: 0;
  font-size: 8px;
  line-height: 1;
  color: $white;
  background-color: rgba($dark, 0.7);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  strong {
    font-size: 16px;
  }
}

.khr-full-scree-rcarousel {
  .khr-full-scree-thumb {
    width: 100px;
    height: 118px;
    overflow: hidden;
    border: solid 2px #fff;
    border-radius: 12px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .owl-carousel {
    transform: rotate(90deg);

    .owl-item {
      transform: rotate(-90deg);

      a {
        display: block;
        width: 100px;
        height: 140px;
      }

      img {
        width: 100px;
      }
    }
  }
}

.khr-full-screen-max {
  max-width: 1226px;
}

.khr-fullscreen-thumb-container {
  background-color: #e5e8ea;
  padding: 0 21px;
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100% - 72px);
  overflow: auto;
  margin-top: 72px;
  padding-top: 30px;
  @include pmd-scrollbar($sidebar-scrollbar-bg, $sidebar-scrollbar-thumb-bg);
}

.khr-modal-fullscreen-thumb-container {
  background-color: #e5e8ea;
  padding: 0 12px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  overflow: auto;
  margin-top: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  @include pmd-scrollbar($sidebar-scrollbar-bg, $sidebar-scrollbar-thumb-bg);

  .khr-full-scree-rcarousel {
    .khr-full-scree-thumb {
      width: 64px;
      height: 84px;
      cursor: pointer;
    }

    .selected_image {
      border: 2px solid $secondary;
    }
  }

  .ng2-pdf-viewer-container {
    overflow: unset !important;
    display: flex;
    justify-content: center;
    align-items: center;

    .pdfViewer.removePageBorders {
      .page {
        margin: 0;
      }
    }
  }
}

.khr-modal-fullscreen-prev-container {
  padding-left: 100px;
}

.khr-fullscreen-prev-container {
  max-width: 1300px;
  margin: 0 auto;
  padding-left: 100px;
  margin-bottom: 30px;
}

.khr-select-entity {
  width: 100%;

  @media (min-width: 1100px) {
    position: absolute;
    top: 20px;
    right: 36px;
    width: 290px;
    z-index: 1025;
  }
}

app-upload-invoice {
  display: flex;
}

.khr-upload-list-right {
  position: relative;
  background-color: #fafafa;
  padding: 20px;
  border: $border-color $border-width solid;

  @media (min-width: 768px) {
    z-index: 1024;
    border: 0;
    border-left: $border-color $border-width solid;
    right: -24px;
    margin-bottom: -24px;
    margin-top: -24px;
  }
}

.khr-upload-no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(40vh);
  justify-content: center;
  padding: 0 20px;
  text-align: center;

  .pmd-icon-secondary {
    background-color: #FFF9E3;
    border-color: #FFF9E3;
  }

  @media (max-width: 768px) {
    .text-desc {
      font-size: 12px;
    }
  }

}

.pmd-singal-drop-container {
  margin-right: -24px;
  width: 100%;
}

.pmd-upload-files {
  background-color: #f1f1f1;
  min-height: 60px;
}

.upload_File_h {
  min-height: 177px;
}

.pmd-upload-list-group {
  overflow: auto;
  padding: 8px;
  margin: -8px;
  padding-right: 12px;

  @include media-breakpoint-up(md) {
    min-height: 510px;
  }
}

.pmd-floating-action {
  bottom: 2rem;
}

.pk-right-sidebar-header {
  position: relative;
}

.btn.pmd-btn-fab.khr-close {
  position: absolute;
  right: -8px;
  top: -8px;
  width: 26px;
  height: 26px;
  min-width: 26px;

  i {
    line-height: 22px !important;
  }
}

.khr-full-scree-thumb.img-active {
  border: 2px solid $yellow;
}

.file-input-control {
  .input-group-text {
    background-color: #fff;
    width: 110px;
    position: relative;
    justify-content: center;

    label {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .icon-delete {
    position: absolute;
    right: 1rem;
    top: 10px;
    z-index: 1024;
  }

  .form-control[type=file] {
    border-left: 1px solid #D2D6DB;
    padding: .55rem 1rem;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    z-index: 1024;
  }
}

.h2.card-title {
  @media(max-width:1559px) {
    font-size: 1.125rem
  }
}