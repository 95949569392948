app-customer-layout {
    app-customer-navbar {
        @include media-breakpoint-down(sm) {
            display: flex;
        }

        pmd-navbar-brand {
            @include media-breakpoint-down(sm) {
                flex: 1 0 0%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .pmd-navbar {
        .container-fluid {
            @include media-breakpoint-down(sm) {
                padding: 0.25rem 0;
            }
        }

        &.navbar {
            .navbar-brand {
                line-height: 36px;

                .pmd-avatar {
                    font-size: $body-font-size;
                }

                @include media-breakpoint-down(sm) {
                    display: initial;
                }

                img {
                    border: $border-width solid $border-color;
                    max-height: 36px;
                    border-radius: $border-radius;
                    padding: 0.25rem;
                }
            }

            .secondary-brand {
                svg {
                    height: 32px;
                    opacity: 0.54;

                    path {
                        fill: $dark;
                    }
                }
            }

            .btn {
                &.pmd-btn-fab {
                    margin: 0 1rem 0 0;
                }
            }

            &.navbar-sm {
                .btn {
                    &.pmd-btn-fab {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .pmd-card {
        &.preview-invoice-card {
            padding-top: 0;

            .payment-options {
                .payment-btn-group {
                    @include media-breakpoint-down(sm) {
                        flex-direction: column;

                        .btn {
                            margin-bottom: 1rem;
                        }
                    }
                }

                .qr-wrapper {
                    min-width: 200px;
                    padding-left: 1rem;

                    p {
                        font-size: $font-size-sm;
                    }

                    img {
                        border: $border-width solid $border-color;
                        padding: 0.25rem;
                        border-radius: $border-radius-sm;
                    }
                }

                .card-details-form {
                    .accordion-item {
                        box-shadow: none;

                        .accordion-body {
                            padding-left: 0;
                            padding-bottom: 0;

                            @include media-breakpoint-down(sm) {
                                padding-right: 0;
                                padding-top: 0;
                                padding-bottom: 1.5rem;
                            }

                            .form-wrapper {
                                padding: $card-spacer;
                                background-color: rgba($primary-lighter, 0.16);
                                border: $border-width solid rgba($primary-lighter, 0.54);
                                border-radius: $border-radius;
                            }
                        }
                    }
                }
            }
        }
    }

    // Payment Message
    .title-with-negative-section {
        padding: 0.5rem 1rem 7.925rem;
        margin: -3rem 0 -7.925rem;
        min-height: 200px;
        background-image: url('../../images/fils-icon-bg.svg');
        background-repeat: no-repeat;
        background-size: 140px;
        background-position: 104% 40px;

        @include media-breakpoint-down(sm) {
            margin-left: -1rem;
            margin-right: -1rem;
            min-height: 180px;
            padding-top: 2rem;
            background-size: 100px;
            background-position: 104% 20px;
        }

        .alert {
            max-width: 700px;
            margin: 1rem auto;
        }
    }

    .transaction-message-card {
        max-width: 500px;
        margin: 0 auto $card-gutter-width;

        .card-title {
            font-size: $h2-font-size;
        }

        .card-subtitle {
            @include media-breakpoint-down(sm) {
                br {
                    display: none;
                }
            }
        }
    }

    &.failed-payment {}


    // Payment Receipt
    .payment-receipt {
        .card-header {
            padding-top: $table-cell-padding-x;

            &.pmd-card-border {
                padding-bottom: $table-cell-padding-x;
            }

            .pmd-avatar-list-img {
                height: 64px;
                width: 64px;

                @include media-breakpoint-down(sm) {
                    height: 44px;
                    width: 44px;
                }
            }

            .pmd-display4 {
                opacity: 0.54;
                line-height: 1.35;

                @include media-breakpoint-down(sm) {
                    font-size: $font-size-sm;
                    font-weight: $font-weight-bold;
                }
            }
        }

        .more-details {
            p {
                a {
                    text-decoration: underline;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

.aTagDisabled {
    pointer-events: none;
    opacity: 0.3;
}

.pmd-card-sticky-tab .sticky-tab {
    z-index: 1019;
}