/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): floating-action-button.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

.pmd-floating-action {
	bottom: $floating-action-spacer-bottom;
	position: fixed;
	right: $floating-action-spacer-right;
	z-index: 1025;
}

.pmd-floating-action:hover,
.menu--floating--open {
	.pmd-floating-action-btn {
		opacity: 1;
		-ms-transform: none;
		transform: none;
		position: relative;
		bottom: auto;
		overflow: hidden;

		&:before {
			opacity: 1;
		}
	}
}

.pmd-floating-action-btn {
	display: block;
	position: relative;
	@include transition($transition-base);

	&:before {
		bottom: 10%;
		content: attr(data-title);
		opacity: 0;
		position: absolute;
		right: 100%;
		@include transition($transition-base);
		white-space: nowrap;
		background-color: $floating-action-btn-label-bg;
		padding: $floating-action-btn-label-padding-y $floating-action-btn-label-padding-x;
		@include border-radius($border-radius);
		color: $floating-action-btn-label-color;
		font-size: $floating-action-btn-font-size-sm;
		margin-right: $floating-action-btn-label-spacer-right;
		display: inline-block;
		@include pmd-box-shadow($floating-action-btn-box-shadow);
	}

	&:last-child:before {
		font-size: 14px;
		bottom: 25%;
	}

	@include transition($sidebar-transition);

	@include hover-focus-active {
		@include pmd-box-shadow($floating-action-btn-focus-box-shadow);
	}

	&:not(:last-child) {
		opacity: 0;
		transform: translateY(20px) scale(0.3);
		-ms-transform: translateY(20px) scale(0.3);
		margin-bottom: $floating-action-btn-spacer-bottom;
		margin-left: $floating-action-btn-spacer-left;
		position: absolute;
		bottom: 0;

		&:nth-last-child(1) {
			transition-delay: 50ms;
		}

		;

		&:nth-last-child(2) {
			transition-delay: 100ms;
		}

		;

		&:nth-last-child(3) {
			transition-delay: 150ms;
		}

		;

		&:nth-last-child(4) {
			transition-delay: 200ms;
		}

		;

		&:nth-last-child(5) {
			transition-delay: 250ms;
		}

		;

		&:nth-last-child(6) {
			transition-delay: 300ms;
		}

		;
	}

	&.btn:hover {
		overflow: visible;
	}

	.ink {
		width: 50px;
		height: 50px;
	}
}

.pmd-floating-hidden {
	display: none;
}