.lead,
p.lead {
  font-size: 16px;
  font-weight: $font-weight-base;

  @include media-breakpoint-down(sm) {
    font-size: $font-size-base;
    line-height: 1.4;
  }
}

h1,
.h1 {
  @media(min-width: 1560px) {
    font-size: 2.25rem;
  }
}

@include media-breakpoint-down(md) {
  h1 {
    font-size: $h4-font-size;
  }
}

h4,
.h4 {
  @include media-breakpoint-down(sm) {
    font-size: $h4-font-size-sm;
  }
}

h3,
.h3 {
  @include media-breakpoint-down(sm) {
    font-size: $h4-font-size;
  }
}

.pmd-body1 {
  border-radius: $border-radius-sm;
}

h6,
.h6 {
  font-size: 0.875rem;

  @media (min-width:1560px) {
    font-size: $h6-font-size;
  }
}