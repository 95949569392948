// Breadcrumbs
.breadcrumb-item+.breadcrumb-item {
    padding-left: 0px;
    display: flex;
    align-items: center;

    &::before {
        font-size: 20px;
        line-height: 1.2;
        padding-right: 0px;
        font-variation-settings:
            'FILL' 0,
            'wght' 400,
            'GRAD' 0,
            'opsz' 48;
        font-family: 'Material Symbols Outlined';
        font-weight: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        margin: 0 4px;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
    }
}