.dashboard-tabs {
  .invoice-count {
    >div {
      background: #ffffff;
      padding: 5px 10px;
      border-radius: 8px;
      margin-bottom: 15px;
      border: 1px solid #f1f1f1;
      position: relative;
      transition: all linear 0.3s;

      &:before {
        content: '';
        border-bottom: 2px solid $yellow;
        height: calc(100% + 2px);
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 8px;
      }

      &.count-pending {
        &:before {
          border-bottom-color: #EBB901;
        }

        background: rgba($color: $secondary, $alpha: 0.1)
      }

      &.count-reviewed {
        &:before {
          border-bottom-color: #339DE1;
        }

        background: rgb(126 176 213 / 6%);
      }

      &.count-rejected {
        &:before {
          border-bottom-color: #D97983;
        }

        background: rgb(253 127 111 / 6%);
      }

      &:hover {
        box-shadow: 0px 0px 9px -3px rgb(0 0 0 / 38%);
        transition: all linear 0.3s;
      }
    }
  }

  .pmd-tabs {
    .pmd-card {
      @include media-breakpoint-down(sm) {
        padding-right: 10px !important;
        padding-left: 10px !important;
      }
    }
  }

  .total-invoice {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: normal;

    span {
      display: flex;
      justify-content: center;
      font-size: 12px;
      line-height: normal;
    }

    .count-number {
      font-size: 34px;
      font-weight: bold;
      width: 100%;
      line-height: normal;
    }
  }

  .chart-overview {
    .apexcharts-canvas {
      svg {
        path {
          filter: none !important;
        }
      }

      text {
        font-family: inherit !important;
        font-size: 12px !important;
        font-weight: 500 !important;
      }
    }
  }

  .apexcharts-text tspan {
    transform: rotate(-45deg);
    transform-origin: center center;
    font-weight: 600;
  }

  .apexcharts-legend-text {
    font-family: inherit !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: $gray-900 !important;
  }

  .apexcharts-toolbar {
    top: -23px !important;

    @include media-breakpoint-down(sm) {
      top: 0 !important;
    }

    svg {
      fill: $gray-400;
    }

    .apexcharts-pan-icon {
      svg {
        fill: none;
        stroke: $gray-400;
      }
    }
  }

  .apexcharts-tooltip.apexcharts-theme-dark {
    color: #fff;
    background: rgba(30, 30, 30, 0.8);
    border: 1px solid #ffffff;
  }

  .statistic-total {
    .total-card {
      padding: 10px 20px;
      border-radius: 8px;
      margin-bottom: 15px;
      border-bottom: 2px solid $yellow;
      background: rgba($color: $secondary, $alpha: 0.05);
      transition: all linear 0.3s;
      position: relative;
      overflow: hidden;
      box-shadow: 0px -1px 3px -1px rgb(0 0 0 / 12%);

      .card-subtitle {
        p {
          font-size: 12px;
          font-weight: 500;
        }
      }

      .growth-rate {
        font-weight: bold;
      }

      &:hover {
        box-shadow: 0px 0px 9px -3px rgb(0 0 0 / 38%);
        transition: all linear 0.3s;
      }
    }

  }

  .chart-filter {
    .badge {
      background-color: #9c9c9c;
      font-weight: normal;
    }
  }

  .title-search-dashboard {
    top: unset;
    right: unset;
  }

  .chart-treemap {
    margin-top: 10px;

    .apexcharts-toolbar {
      // top:-40px !important
      display: none;
    }

    .apexcharts-datalabel {
      //class invoiceSource
      max-width: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
  }
}

.bg-success-light {
  background-color: rgba($color: #0CAA31, $alpha: 0.1) !important;
}

.bg-danger-light {
  background-color: rgba($color: #C52031, $alpha: 0.1) !important;
}

.bg-info-light {
  background-color: rgba($color: #0089DE, $alpha: 0.1) !important;
}

.selection-item {
  @include media-breakpoint-down(sm) {
    margin-left: 0 !important;
    margin-bottom: 15px !important;
  }

  &.khr-supplier .ng-arrow-wrapper {
    top: -1px !important;
  }

  position: relative;

  &:before {
    content: '';
    height: 100%;
    width: 1px;
    background-color: $secondary;
    display: block;
    position: absolute;
    left: 88px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .ng-select-container {
    padding: 6px 0 !important;
    margin-bottom: -4px;

    .ng-arrow-wrapper {
      top: -2px;
    }

    @include media-breakpoint-down(sm) {
      padding: 8px 0 5px !important;
    }

    .ng-clear-wrapper .ng-clear {
      display: flex;
      height: 100%;
      align-items: center;
      padding-right: 10px;
      justify-content: center;
    }
  }

  label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    font-size: 13px;

    @include media-breakpoint-down(sm) {
      position: relative;
      left: unset;
      transform: translateY(-25%);
    }
  }

  .status-dropdown-outer {
    padding-left: 100px !important;
    min-height: 40px;

    @include media-breakpoint-down(sm) {
      padding-left: 10px !important;
    }
  }
}

.supplierName {
  max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@include media-breakpoint-up(md) {
  .card-supplier-overview {
    min-height: 445px;
  }
}

.no-data-statistic {
  padding: 10px 20px;
  border: 1px solid rgba(44, 62, 80, 0.06) !important;
  border-radius: 8px;
  margin-bottom: 15px;
  background: rgb(41 128 201 / 6%) !important;
  transition: all linear 0.3s;
  // cursor: pointer;
  position: relative;

  &:before {
    display: none !important;
  }

  &:hover {
    cursor: reset !important;
    box-shadow: none !important;
  }
}

.apexcharts-svg {
  .apexcharts-yaxis:first-child {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}

.arrow_box {
  .apexcharts-tooltip-title {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 15px;
    display: flex;
    align-items: center;
  }

  .custom-tooltip-data {
    padding: 5px 10px;
    background: #ffffff;
    font-size: 12px;

    &:last-child {
      margin-bottom: 5px;
    }
  }

  .color-item {
    display: inline-flex;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: purple;
    margin-right: 10px;
  }
}

.clear-input {
  position: absolute;
  top: 3px;
  left: -47px;
  font-size: 15px;
  height: calc(100% - 6px);
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 5px;
}

.title-count {
  font-size: 12px;
  font-weight: 500;
}

.apexcharts-xaxis-title,
.apexcharts-yaxis-title {
  text {
    fill: #000 !important;
    font-weight: 400 !important;
  }
}

.apexcharts-legend-text {
  margin: 0 5px;
}

.growth-rate .text-success {
  color: #88a457 !important;
  font-weight: 600;
}

.growth-rate .text-danger {
  color: #df8377 !important;
  font-weight: 600;
}

.apexcharts-treemap-series {
  rect {
    stroke-width: 0 !important;
    /* Set the stroke width to 0 to remove the white stroke */
  }
}

.line-chart {
  .apexcharts-legend {
    margin-right: -50px !important;
  }
}

.supplier-list {

  th,
  td {
    width: 15%;
  }
}

.percentage-value {
  color: $gray-400;
  font-weight: 500;
}

.apexcharts-marker {
  opacity: 1;
}

.position-absolute-right {
  @include media-breakpoint-up(lg) {
    position: absolute;
    right: 24px;
    top: -66px !important;
    z-index: 1024;
  }
}

.tab-header-filter-fixed {
  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 80px !important;
    margin-left: auto;
  }
}

.postion-right-btn {
  position: absolute;
  right: 30px;
}

@media only screen and (max-width: 1200px) {
  .invoice-list-tabs .row-filter-search .clear-input {
    right: 50px !important;
  }
}

@media only screen and (max-width: 640px) {
  .postion-right-btn {
    position: relative;
    right: 0;
    top: 12px;
    z-index: 9;
    left: auto;
  }
}

.khr-taxes-group {
  &.apexcharts-tooltip-series-group {
    align-items: flex-start;

    .apexcharts-tooltip-marker {
      top: 8px;
    }
  }
}

.khr-taxes-list {
  border: solid $border-color $border-width;
  border-radius: 4px;
  padding: 8px;

  >div {
    line-height: 1;
    padding-right: 15px;

    .apexcharts-tooltip-text-y-label {
      font-size: 8px;
      text-transform: uppercase;
      margin-bottom: 2px !important;
      color: #8c8a8a;
    }

    .apexcharts-tooltip-text-y-value {
      margin-left: 0;
      font-weight: 500;
      font-size: 12px;
    }
  }
}