/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): alert.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or latert
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

// alert container for positions
pmd-alert{
	display: block;
}
@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant-default(theme-color-level($color, $alert-bg-level), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
  }
}
.alert{
	.alert-link{
		&:hover{
			text-decoration: underline;
		}
	}
	.close{
		float: right;
		font-size: $alert-close-font-size;
		font-weight: $alert-close-font-weight;
		line-height: $alert-close-lineheight;
		color: $alert-close-color;
		text-shadow: $alert-close-text-shadow ;
		opacity: $alert-close-opacity;
		padding: $alert-close-padding;
		background-color: $alert-close-background;
		border: $alert-close-border;
	}
}
.pmd-alert-container {
	position: fixed;
	z-index: 100000000;
	
	// alert container for top, bottom, left, right and center positions
	&.top {
		top: $pmd-alert-container-spacing;
	}
	&.bottom {
		bottom: $pmd-alert-container-spacing;
	}
	&.left {
		left: $pmd-alert-container-spacing;
	}
	&.right {
		right: $pmd-alert-container-spacing;
	}
	&.center {
		left: 50%;
	}
	
	// Media query start
	@include media-breakpoint-down(sm) {
		&.left {
			left: 50%;
		}
		&.right {
			right: 50%;
		}
	}
	
	// alert container for alert varients
	.pmd-alert {
		position: relative;
		z-index: 1000;
		margin-bottom: 5px;
		clear: both;
		text-align: $pmd-alert-text-align;
		vertical-align: middle;
		width: $pmd-alert-width;  
		padding: $pmd-alert-padding-y $pmd-alert-padding-x;
		color: $pmd-alert-color;
		background: $pmd-alert-bg;
		@include border-radius($pmd-alert-border-radius);
		animation-duration: 1s;
		animation-fill-mode: both;
		font-size: $pmd-alert-font-size;
		a {
			position: absolute;
			right: 18px;
			float: right;
			color: $pmd-alert-link-color;
			// Alert Action css
			&:before {
				position: absolute;
				top: 0;
				right: -10px;
				bottom: 0;
				left: -10px;
				margin: auto;
				content: "";
			}
		}
		// Alternate styles
		@each $color, $value in $theme-colors {
			&.#{$color} {
				@include alert-variant($value, $value);
			}
		}
		&.pmd-alert-hide{ 
			height: 0; 
			padding: 0;
			overflow: hidden;
			opacity: 0;
		}
	}
}
.notification a {
  position: absolute;
  right: 18px;
  float: right;
  color: $pmd-alert-link-color;
}

// Fade in keyframes
@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}
.fadeIn { animation-name: fadeIn; }

// Fade out keyframes
@keyframes fadeOut {
	from { opacity: 1; }
	to { opacity: 0; }
}
.fadeOut { animation-name: fadeOut; }

// Fade out down keyframes
@keyframes fadeOutDown {
	from { opacity: 1; }
	to { opacity: 0; transform: translate3d(0, 100%, 0); }
}
.fadeOutDown { animation-name: fadeOutDown; }

// Fade in down keyframes
@keyframes fadeInDown {
	from { opacity: 0; transform: translate3d(0, -100%, 0); }
	to { opacity: 1; transform: none; }
}
.fadeInDown { animation-name: fadeInDown; }

// Fade in up keyframes
@keyframes fadeInUp {
	from { opacity: 0; transform: translate3d(0, 100%, 0); }
	to { opacity: 1; transform: none; }
}
.fadeInUp {animation-name: fadeInUp;}