.pmd-list-check {
    .list-group-item {
        // align-items: center;
        color: $pmd-text-secondary;
    }

    .material-icons-round {
        color: $pmd-theme-secondary;
    }
}

.pmd-list-transparent {
    .list-group-item {
        padding-left: 0;
        padding-right: 0;
    }
}


.pf-pass-valid-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0;

    .pf-pass-valid-item {
        position: relative;
        padding: 0 0 0 $space24;
        list-style: none;
        margin-bottom: $space8;
        width: 50%;
        font-size: $font-size-sm;
        color: pmd-theme-color(primary, dark);

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        &:nth-child(odd) {
            padding-right: $space16;

            @include media-breakpoint-down(md) {
                padding-right: 0;
            }
        }

        &:before {
            content: "";
            width: 6px;
            height: 6px;
            background-color: theme-color(light);
            border-radius: 8px;
            position: absolute;
            left: 4px;
            top: 6px;
            text-decoration: none;
        }

        &.valid-point {
            text-decoration: line-through;
            color: pmd-theme-color(secondsecondary, dark);

            &:before {
                left: 0;
                top: 2px;
                width: 18px;
                background-color: transparent;
                background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxOCAxOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTggMTgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0ibm9uZSIgZD0iTTAsMGgxOHYxOEgwVjB6Ii8+DQo8cGF0aCBmaWxsPSIjMjU5QjI0IiBkPSJNNi44LDEyLjJMMy42LDlsLTEsMWw0LjIsNC4ybDktOWwtMS0xLjFMNi44LDEyLjJ6Ii8+DQo8L3N2Zz4NCg==');
                background-repeat: no-repeat;
                background-size: 18px;
                height: 18px;
                text-decoration: none;
                display: inline-block;
            }
        }
    }
}

.pmd-checklist {
    .list-group-item {
        padding-left: 26px;

        &::before {
            position: absolute;
            left: 0;
            font-size: 18px;
            line-height: 1.2;
            padding-right: $space4;
            top: 15px;
            color: theme-color(dark);
            font-variation-settings:
                'FILL' 0,
                'wght' 400,
                'GRAD' 0,
                'opsz' 48;
            font-family: 'Material Symbols Outlined';
            font-weight: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            content: 'task_alt';
        }
    }
}

.pmd-upload-list-group {
    .pmd-upload-list-item {
        margin-bottom: 15px;
        height: auto;
        width: 100%;
    }
}