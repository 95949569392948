/*!
 * Propeller Pro v1.5.0 (http://pro.propeller.in/): list.css
 * Copyright 2016-2019 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

.pmd-card-list{
	padding-bottom: $card-list-padding-y; 
	padding-top: $card-list-padding-y; 
	margin-bottom:0;
	background-color: $card-list-bg;
	@include pmd-box-shadow($card-shadow);
	&.pmd-list-collapse{
		.pmd-collapse-list-content{
			min-width: $pmd-collapse-list-content-min-width;
		}
	}
}

// Textlist
.pmd-list{
	.list-group-item{ 
		padding: $list-group-item-padding-y $list-group-item-padding-x;
		// Inset List Item
		&.pmd-list-item-inset{
			padding-left: calc(#{$list-group-item-padding-x} + #{$list-group-avatar-img-size} + #{$list-group-item-media-left});
		}
		// Child List
		.pmd-child-list{
			margin: 0 (-$list-group-item-padding-x);
			.list-group-item{
				padding: $pmd-child-list-item-padding-top $pmd-child-list-item-padding-right $pmd-child-list-item-padding-bottom $pmd-child-list-item-padding-left;
			}
		}
		.pmd-switch{
			label{
				margin-bottom: 0;
			}
		}
	}
	.list-group-item {
		margin-bottom: -1px; 
		border: inherit; 
		line-height:$list-group-item-line-height;
		&:first-child {
			border-top-left-radius: 0; 
			border-top-right-radius: 0;
		}
		&:last-child {
			margin-bottom: 0; 
			border-bottom-right-radius: 0; 
			border-bottom-left-radius: 0;
		}
	}
	&.pmd-list-border {
		.list-group-item {
			border:$list-group-border-width solid $list-group-border-color
		}
	}
	
	// Small Listing
	&.pmd-list-sm{
		.list-group-item{
			padding-top: $list-group-item-padding-sm-y;
			padding-bottom: $list-group-item-padding-sm-y;
		}
		&.pmd-list-bullet{
			.list-group-item{
				&:before{
					top: 0.5rem;
				}
			}
		}
	}
	
	// Listing with bullets (Arrow)
	&.pmd-list-bullet{
		.list-group-item{
			padding-left: $pmd-list-bullet-icon-padding-left;
			position: relative;
			&:before{
				content: $pmd-list-bullet-icon;
				font-family: 'Material Icons';
				position: absolute;
				left: 0;
				top: 0.625rem;
				opacity: $pmd-list-bullet-icon-opacity;
			}
		}
	}

	// Media list
	.material-icons{
		&.media-left{
			padding-right:$list-icon-right;
			vertical-align:top;
			display:table-cell;
		}
		&.media-right{
			padding-left:$list-icon-left;
			vertical-align:top; 
			display:table-cell;
		}
		&.media-middle{ 
			vertical-align:middle; 
			display:table-cell;
		}
	}

	// List Inset Divider
	&.pmd-inset-divider{
		.list-group-item{
			position: relative;
			margin-bottom: 0;
			&:after{
				content: "";
				position: absolute;
				right: 0;
				left: calc(#{$list-group-item-padding-x} + #{$list-group-avatar-img-size} + #{$list-group-item-media-left});
				bottom: 0;
				height: 1px;
				background-color: $border-color;
			}
			&:last-child{
				&:after{
					display: none;
				}
			}
			&.pmd-inser-divider-0,
			&.border-0{
				&:after{
					display: none;
				}
			}
		}
	}
}

.pmd-list-title {
	color:$pmd-list-title-color;
	font-size: $pmd-list-font-size;
	&:last-child {
		margin-bottom: 0;
	}
	&:first-child{
		margin-bottom: 0.5rem;
	}
}
.pmd-list-subtitle {
	color:$pmd-list-subtitle-color;
	font-size: $pmd-list-subtitle-font-size;
	margin-top:-0.25rem;
	&:first-child {
		margin-bottom: 0;
		margin-top: 0;
	}
}
.pmd-list-text {
	color:$pmd-list-text-color; 
	font-size:$pmd-list-text-font-size;
}
.pmd-list-text:last-child,
.pmd-list-subtitle:last-child {
	margin-bottom: 0; 
}
.pmd-list-subtitle + .pmd-list-subtitle {
	margin-top:0;
}
.list-group-item {
	&.active {
		.pmd-list-title {
			color:$pmd-list-dark-title-color;
		}
		.pmd-list-subtitle {
			color:$pmd-list-dark-subtitle-color;
		}
		.pmd-list-text {
			color:$pmd-list-dark-text-color; 
		}
	}
}

// Icon list
.pmd-list-avatar {
	padding:$list-group-avatar-item-padding-y 0;
	.list-group-item {
		padding-top:$list-group-avatar-item-padding-y;
		padding-bottom:$list-group-avatar-item-padding-y;
	}
}
.pmd-list-icon { 
	width:$list-group-avatar-img-size;
	margin-right: $list-group-item-media-left;
}
.pmd-avatar-list-img { 
	border-radius:50%;
	width:$list-group-avatar-img-size;
	height:$list-group-avatar-img-size;
	margin-right: $list-group-item-media-left;
	overflow:hidden;
	display: inline-block;
}

.media-body{
	&.pmd-word-break{ 
		word-break: break-all; 
		word-wrap: break-word;
	}
	& + .pmd-avatar-list-img,
	& + .pmd-list-icon{
		margin-right: 0;
		margin-left: $list-group-item-media-left;
	}
	& + .pmd-list-icon{
		text-align: right;
		justify-content: flex-end;
	}
	& + .dropdown{
		margin-left: 0.5rem;
		margin-right: -0.5rem;
		margin-top: -0.5rem;
	}
}

// List Inline
.pmd-list-inline{
	margin: 0 -0.375rem;
	.list-inline-item{
		padding: 0 0.375rem;
		margin: 0;
	}
	// Border Right
	&.pmd-list-inline-border{
		margin-left: -1rem;
		margin-right: -1rem;
		.list-inline-item{
			padding-left: 1rem;
			padding-right: 1rem;
			border-right: 1px solid $border-color;
			&:last-child{
				border-right: 0;
			}
		}
	}
}

// 
.list-group-item-action{
	a{
		color: inherit;
		&:hover,
		&:focus{
			text-decoration: none;
		}
	}
}

// Dropdown NO Caret
.dropdown{
	.dropdown-toggle{
		&.no-caret,
		&.pmd-btn-fab{
			&:after{
				display: none;
			}
		}
	}
}

[aria-expanded*="true"] {
	.pmd-accordion-arrow{
		transform: rotate(180deg);
		justify-content: end;
	}
}

// List Item Divider
.pmd-list-divider{
	height: 0;
	margin: $pmd-list-item-divider-margin-y $pmd-list-item-divider-margin-x;
	overflow: hidden;
	border-top: $pmd-list-item-divider-border-width solid $border-color;
	&.pmd-list-item-inset{
		margin-left: calc(#{$list-group-item-padding-x} + #{$list-group-avatar-img-size} + #{$list-group-item-media-left});
	}
}
