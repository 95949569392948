.pmd-avatar {
    &.pmd-avatar-xs {
        width: $pmd-avatar-size-xs;
        height: $pmd-avatar-size-xs;
        font-size: 12px;
    }
    // &.pmd-avatar-sm {
    //     width: $pmd-avatar-size-sm;
    //     height: $pmd-avatar-size-sm;
    //     font-size: $pmd-avatar-font-size-sm;
    // }
    // &.pmd-avatar-md {
    //     width: $pmd-avatar-size-md;
    //     height: $pmd-avatar-size-md;
    //     font-size: 16px;
    // }
    // &.pmd-avatar-xl {
    //     width: $pmd-avatar-size-xl;
    //     height: $pmd-avatar-size-xl;
    //     .material-icons-round {
    //         font-size: 40px;
    //     }
    //     svg {
    //         height: 60%;
    //     }
    // }
    // &.pmd-avatar-sm {
    //     width: $pmd-avatar-size-sm;
    //     height: $pmd-avatar-size-sm;
    //     font-size: 14px;
    // }
}