// Background Color
$blue: #00426d;
$teal: #33c6cb;
$yellow: #e6b401;
// $navy: #2B3641;

// Text Color
$black: #212121;
$gray-950: #212121;
$gray-900: #3d3d3d;
$gray-800: #454545;
$gray-700: #4f4f4f;
$gray-600: #5d5d5d;
$gray-500: #6d6d6d;
$gray-400: #888888;
$gray-300: #b0b0b0;
$gray-200: #d1d1d1;
$gray-100: #e7e7e7;
$gray-50: #f6f6f6;

$yellow-950: #442204;
$yellow-900: #74420f;
$yellow-800: #89510a;
$yellow-700: #a66802;
$yellow-600: #d09200;
$yellow-500: #e6b401;
$yellow-400: #ffd70e;
$yellow-300: #ffd70e;
$yellow-200: #FFF9E3;
$yellow-100: #FAF0CC;
$yellow-50: #fffee7;

$white: #fff;
$grayLight: #F2F4F6;
$grayLightSecond: #e4e4e4;

$blue-lighter: #c3d4ee;
$indigo: #6610f2;
$purple: #78acff;
$pink: #c62828;
$red: #e82e00;
$orange: #cb4006;
$green: #0aa05c;
$cyan: #03a9f4;
$brown: #843e06;
$green-cyan: #02a499;

$primary: $blue;
$primary-lighter: $blue-lighter;
$secondary: $yellow;
$secondary-lighter: #fffae6;
$secondary-100: #FFFBF0;
$info-100: #ECF4FF;
$success: $green;
$info: #3A84EC;
$warning: $yellow;
$danger: $pink;
$danger-lighter: #f9e9e9;

$light: #d1d1d1;
$gray: $gray-600;
$dark: $gray-950;
$gray7: #777777;
$theme-colors: (
);
$theme-colors: map-merge(("primary": $primary,
      "primarylight": $purple,
      "secondary": $secondary,
      "success": $success,
      "info": $info,
      "warning": $warning,
      "danger": $red,
      "light": $light,
      "white": $white,
      "dark": $dark,
    ),
    $theme-colors);

// Text colors according to light vs dark and text type.
$pmd-theme-colors: (
  dark: (primary: $gray-950,
    secondary: $gray-800,
    secondsecondary: $gray-700,
    hint: $gray-600,
    disabled: rgba($black, 0.05),
    icon: $gray-800,
    hover: #f2fef9,
    dividers: rgba($black, 0.1),
  ),
  light: (primary: $white,
    secondary: rgba($white, 0.87),
    hint: rgba($white, 0.4),
    disabled: rgba($white, 0.5),
    icon: rgba($white, 0.5),
    hover: rgba($white, 0.24),
    dividers: rgba($primary, 0.12),
  ),
);
$pmd-theme-selection: pmd-theme-color(secondary, dark);
$pmd-theme-hover: pmd-theme-color(secondary, dark);

$pmd-box-shadow: (
);
$pmd-box-shadow: map-merge((dark: (z-depth-1: (0 4px 8px rgba($secondary, 8%),
        ),
        z-depth-2: (10px 0 16px rgb($black, 6%),
        ),
        z-depth-3: (0 -2px 6px rgb($secondary, 8%),
        ),
        z-depth-4: (0 6px 6px rgb($teal, 12%),
        ),
        z-depth-5: (0 8px 24px rgb($dark, 24%),
        ),
      ),
      light: (z-depth-1: (0 3px 1px -2px rgba($black, 0.12),
          0 2px 2px 0 rgba($black, 0.08),
        ),
        z-depth-2: (0 3px 5px -1px rgba($black, 0.12),
          0 6px 10px 0 rgba($black, 0.08),
        ),
        z-depth-3: (0 5px 5px -3px rgba($black, 0.12),
          0 8px 10px 1px rgba($black, 0.08),
        ),
        z-depth-4: (0 12px 24px -4px rgba($black, 0.12),
          0 8px 20px 1px rgba($black, 0.08),
        ),
        z-depth-5: (0 16px 28px -4px rgba($black, 0.12),
          0 12px 36px 1px rgba($black, 0.08),
        ),
      ),
    ),
    $pmd-box-shadow);

$yiq-text-dark: theme-color("dark");
$ng-option-child-left-padding: 30px;
$yiq-contrasted-threshold: 200;

// Links
$link-color: theme-color("secondary") !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
$link-outline: none !default;

// Paragraphs
$paragraph-margin-bottom: 1rem !default;

$font-size-base: 0.875rem;
$content-padding-top: 96px;

// Spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * 0.25,
  ),
  2: ($spacer * 0.5,
  ),
  3: $spacer,
  4: ($spacer * 1.5,
  ),
  5: ($spacer * 3,
  ),
);

$space2: 0.125rem;
$space4: 0.25rem;
$space6: 0.25rem;
$space8: 0.5rem;
$space10: 0.625rem;
$space12: 0.75rem;
$space14: 0.875rem;
$space16: 1rem;
$space18: 1.125rem;
$space20: 1.25rem;
$space24: 1.5rem;
$space28: 1.75rem;
$space30: 1.875rem;
$space40: 2.5rem;
$space60: 3.75rem;

// Body
$body-bg: $gray-100;
$body-color: $gray-700;
$text-muted: pmd-theme-color(secondary, dark);
$body-font-size: $font-size-base;

// Fonts
$font-family-rubik: "Poppins", sans-serif;
$font-family-sans: "Poppins", sans-serif;
$font-family-base: $font-family-sans !default;

$font-size-lg: (
  $font-size-base * 1.25
);
$font-size-sm: (
  $font-size-base * 0.857
);
$font-size-xs: (
  $font-size-base * 0.714295
);

$font-weight: (
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 800,
  black: 900,
  ) !default;

$font-weight-light: font-weight("light"
);
$font-weight-normal: font-weight("regular");
$font-weight-medium: font-weight("medium");
$font-weight-semibold: font-weight("semibold");
$font-weight-bold: font-weight("bold");

$font-weight-base: $font-weight-normal !default;

$border-width: 1px;
$border-color: #D2D6DB;
$border-width-lg: 2px;
$border-radius: 0.5rem;

$border-radius-left: 0.5rem 0 0 0.5rem;
$border-radius-right: 0 0.5rem 0.5rem 0;

$border-radius-lg: 2.5rem;
$border-width: 1px;
$border-width-lg: 2px;
$border-radius: 0.5rem;
$border-radius-lg: 2.5rem;
$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.714;
$h3-font-size: $font-size-base * 1.429;
$h4-font-size: $font-size-base * 1.286;
$h5-font-size: $font-size-base * 1.143;
$h6-font-size: 1rem;

$h1-font-size-sm: 1.25rem;
$h4-font-size-sm: $h6-font-size;

$transition-base: all 0.2s ease-in-out;

// $headings-margin-bottom:       				($spacer / 2) !default;
$headings-font-family: $font-family-rubik;
$headings-font-weight: font-weight("semibold");
$headings-line-height: 1.2;
$headings-color: pmd-theme-color(secondary, dark);

$lead-font-size: (
  $font-size-base * 1.25) !default;
$lead-font-weight: font-weight("light") !default;
$small-font-size: 80% !default;
$pmd-caption-font-size: $font-size-base * 0.75 !default;
$pmd-caption-letter-spacing: 0.08em !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px) !default;


// Material Typo
$pmd-body: $font-size-base * 0.875;
$pmd-headline: $font-size-base * 1.5 !default;
$pmd-title: $font-size-base * 1.25 !default;
$pmd-subheading2: $font-size-base * 1 !default;
$pmd-subheading1: $font-size-base * 0.938 !default;

$display4-size: 2.125rem !default;
$display4-line-height: 2.5rem !default;
$display4-font-weight: $font-weight-normal !default;
$display3-size: 2.8125rem !default;
$display3-line-height: 3rem !default;
$display3-font-weight: $font-weight-normal !default;
$display2-size: 3.5rem !default;
$display2-line-height: 3.5rem !default;
$display2-font-weight: $font-weight-normal !default;
$display2-letter-spacing: -0.02em !default;
$display1-size: 6rem !default;
$display1-line-height: 7rem !default;
$display1-font-weight: $font-weight-light !default;
$display1-letter-spacing: -0.04em !default;
$display-color: pmd-theme-color(secondary, dark) !default;
$display-line-height: $headings-line-height !default;

$pmd-headline-font-weight: $font-weight-normal !default;
$pmd-headline-line-height: inherit !default;
$pmd-title-font-weight: $font-weight-normal !default;
$pmd-title-line-height: inherit !default;
$pmd-subheading2-font-weight: $font-weight-normal !default;
$pmd-subheading2-line-height: inherit !default;
$pmd-subheading1-font-weight: $font-weight-normal !default;
$pmd-subheading1-line-height: inherit !default;

.material-symbols-rounded {
  font-variation-settings: "FILL" 1,
    "wght" 400,
    "GRAD" 0,
    "opsz" 48;
}

// Breadcrumb
$breadcrumb-divider: "keyboard_arrow_right";
$breadcrumb-divider-color: theme-color("dark"
);
$breadcrumb-font-size: $font-size-sm;
$breadcrumb-font-weight: $font-weight-normal;
$breadcrumb-font-color: theme-color("dark");
$breadcrumb-hover-color: darken($breadcrumb-font-color, 7.5%);
$breadcrumb-active-color: rgba(theme-color("dark"), 0.74);
$breadcrumb-active-font-weight: $breadcrumb-font-weight;
$breadcrumb-dark-font-color: pmd-theme-color(hint, light);
$breadcrumb-dark-hover-color: pmd-theme-color(secondary, light);
$breadcrumb-dark-active-color: pmd-theme-color(secondary, light);
$breadcrumb-dark-font-color: pmd-theme-color(hint, light);
// Navbar
$navbar-dark-active-color: $black;
$navbar-toggler-mobile-margin-right: 10px;

// Sidebar
$sidebar-width: 240px;
$sidebar-nav-link-border-radius: 8px;
$sidebar-nav-link-hover-bg: theme-color("secondary");
$sidebar-nav-link-hover-color: theme-color("white");
$sidebar-nav-icon-color: rgba(theme-color("secondary"), 0.74);
$sidebar-dark-nav-icon-hover-color: theme-color("white");
$sidebar-nav-item-padding-x: 2px;
$pmd-nav-item-icon-margin-left: 0;
$pmd-nav-item-icon-size: auto;
$sidebar-nav-link-padding-y: 12px;
$sidebar-nav-link-font-weight: $font-weight-semibold;
$sidebar-nav-icon-opacity: 1;
$sidebar-open-nav-icon-opacity: 0.87;
$sidebar-box-shadow: pmd-box-shadow(z-depth-2, dark);
$sidebar-overlay-bg: rgba(2, 2, 2, 0.74);
$sidebar-overlay-opacity: 1;

// Footer
$pmd-site-info-font-size: $font-size-sm;
$pmd-site-info-strong: pmd-theme-color(secondary, dark);
$pmd-site-info-strong-weight: $font-weight-bold;
$pmd-footer-nav-link-weight: $font-weight-semibold;
$pmd-footer-nav-link-font-size: $font-size-xs;
$pmd-footer-nav-link-dark: $gray-700 !default;

// Button
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius-lg;
$btn-border-width: $border-width-lg;
$btn-font-size: 14px;
$btn-font-weight: $font-weight-semibold;
$btn-padding-y: 0.5rem;
$btn-padding-x: 1rem;
$btn-hover-shadow: pmd-box-shadow(z-depth-5, dark);
$btn-link-disabled-color: $gray-400;

// Radio
$custom-control-border-color: #D2D6DB;
$custom-control-checked-border-bg: theme-color("secondary");
$radio-check-color: theme-color("secondary");
$checkbox-border-radius: 0.25rem;
$checkbox-checked-bg: theme-color("secondary");
$checkbox-check-color: $white !default;

// Textfield
$textfield-border-color: #D2D6DB;
$textfield-focused-bg: theme-color("secondary");
$textfield-outline-focused-box-shadow: none;
$textfield-label-color: $gray-600;
$textfield-outline-padding-x: 1rem;
$input-group-text-bg: rgba(theme-color("dark"), 0.06);
$textfield-outline-active-box-shadow: pmd-box-shadow(z-depth-3, dark);
$textfield-color: pmd-theme-color(secondary, dark);

$content-padding-x: 0;

// Dropdowns
$dropdown-box-shadow: pmd-box-shadow(z-depth-5, dark);

$dropdown-link-color: pmd-theme-color(secondary, dark);
$dropdown-link-hover-color: pmd-theme-color(secondary, dark);
$dropdown-link-hover-bg: rgba($secondary, 0.16);
$dropdown-link-hover-icon-color: $secondary;

// Custom Variables
$bottom-bar-box-shadow: pmd-box-shadow(z-depth-3, dark);
$dropdown-border-radius: $border-radius;

// Tables
$table-cell-padding-x: 1rem;
$table-cell-padding-y: 0.75rem;
$table-cell-vertical-align: top;

// Nav
$pmd-nav-divider-list-color: pmd-theme-color(secondsecondary, dark);
$pmd-nav-divider-link-color: pmd-theme-color(secondsecondary, dark);
$pmd-nav-divider-font-weight: $font-weight-semibold;
$pmd-nav-divider-color: rgba(pmd-theme-color(secondsecondary, dark), 0.54);
$pmd-nav-divider-font-size: $font-size-xs;

$avatar-color-level: 8;
$pmd-avatar-size-xs: 20px;
$pmd-avatar-border-radius: 50px;

// Badge
$badge-bg-level: -10;
$badge-border-level: -9;
$badge-color-level: 6;
$pmd-badge-padding-y: 0.5rem;
$pmd-badge-padding-x: 0.75rem;
$pmd-badge-font-size: 12px;
$pmd-badge-font-weight: $font-weight-semibold;

// Tables
$table-cell-line-height: 1.4;
$table-hover-bg: pmd-theme-color(hover, dark);
$table-head-bg: $secondary-lighter;
$table-head-color: $black;
$table-head-font-size: $font-size-sm;
$table-head-font-weight: $font-weight-semibold;

// List
$pmd-nav-divider-text-transform: "inherit";
$pmd-datepicker-head-button-hover-bg: rgba($secondary, 0.34);

//Pagination
$pagination-link-border-radius: 4px;
$pagination-font-size: 14px;
$pagination-font-weight: $font-weight-bold;
$pagination-link-color: $secondary;
$pagination-active-link-color: $white;
$pagination-link-line-height: 1;
$pagination-outline-border-color: #D2D6DB;
$pagination-link-padding-y: 0.4rem;
$pagination-outline-active-border-color: theme-color("secondary");
$pagination-active-item-bg: theme-color("secondary");
$pagination-item-hover-bg: transparent;
$pagination-item-hover-color: theme-color("secondary");
$pagination-height: 34px;
$pagination-width: 34px;
$pagination-outline-hover-border-color: theme-color("secondary");
$pagination-link-margin-x: 2px;
$pagination-arrow-bg: transparent;
$pagination-arrow-hover-bg: theme-color("secondary");
$pagination-arrow-hover-color: $white;

// tooltip
$tooltip-color: $white !default;
$tooltip-bg: #373737;
$tooltip-opacity: 1;
$tooltip-font-size: 10px;
$tooltip-padding-y: 12px;
$tooltip-padding-x: 12px;

// Chip
$chip-line-height: 40px;
$chip-bg: #d7e0f1;
$chip-color: pmd-theme-color(secondary, dark);
$chip-action-color: theme-color(secondary);
$chip-action-bg: #bbc9e4;
$chip-action-font-size: 20px;
$chip-contact-size: 2.25rem !default;
$chip-border-width: $border-width !default;
$chip-outline-action-opacity: 0.38 !default;

// Datepicker
$pmd-datepicker-day-selected-color: theme-color("primary") !default;
$pmd-datepicker-disabled-color: rgba($body-color, 0.7);
$pmd-datepicker-other-month-color: rgba($black, 0.7);

$sidebar-dark-nav-icon-color: #ffffff;
$sidebar-dark-nav-icon-hover-color: $yellow;
$sidebar-nav-link-color: pmd-theme-color(primary, dark) !default;
$sidebar-dark-nav-link-color: pmd-theme-color(primary, light) !default;
$sidebar-nav-link-hover-color: pmd-theme-color(primary, dark) !default;
$material-icons-dark-color: $gray-600;
$font-color-02: $gray-300 !default;