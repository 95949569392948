/*!
 * Propeller Pro v1.6.0 (http://pro.propeller.in/): badge.css
 * Copyright 2016-2020 Digicorp Information Systems Pvt. Ltd.
 * License: GNU General Public License v2.0 or later
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

.badge {
	display: inline-block;
	vertical-align: baseline;
}

.badge:hover {
	text-decoration: none;
	cursor: pointer;
}

// Notification Badge css
// .pmd-badge {
// 	display:inline-block; 
// 	position:relative;
// 	font-size: $notification-icon-size;
// 	background-color: transparent;
// 	&[data-badge]::after {
// 		background: $notification-badge-bg; 
// 		color: $notification-badge-color; 
// 		font-family: $notification-badge-font; 
// 		font-size: $notification-badge-font-size; 
// 		font-weight: $notification-badge-font-weight; 
// 		height: $notification-badge-size;
// 		width: $notification-badge-size;
// 		line-height: calc(#{$notification-badge-size - $notification-badge-border-weight});
// 		border-radius: 50%; 
// 		right: -24px; 
// 		top: -10px; 
// 		content: attr(data-badge); 
// 		position: absolute; 
// 		text-align: center;
// 		border:solid $notification-badge-border-weight $notification-badge-color;
// 	}
// 	&.pmd-badge-overlap::after {
// 		right: -10px;
// 	}
// }

// Colors
@each $color, $value in $theme-colors {
	.badge-#{$color} {
		@include badge-variant($value);
	}

	.pmd-badge-#{$color}[data-badge]::after {
		@include notification-badge($value);
	}
}

.pmd-badge {

	font-size: 11px;
	font-weight: $pmd-badge-font-weight;
	line-height: 1;
	text-align: center;
	padding: .35rem $pmd-badge-padding-x;
	white-space: nowrap;

	@each $color, $value in $theme-colors {
		&.pmd-badge-#{$color} {
			@include avatar-variant(theme-color-level($color, $badge-bg-level), theme-color-level($color, $badge-border-level), theme-color-level($color, $badge-color-level));
			box-shadow: none;
		}
	}

	@media (min-width:1560px) {
		padding: $pmd-badge-padding-y $pmd-badge-padding-x;
		font-size: $pmd-badge-font-size;
	}
}