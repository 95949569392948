@mixin alert-variant($background, $color) {
  color: color-yiq($color);
  background-color:$background;
}
@mixin alert-variant-default($background, $border, $color) {
  color: $color;
  border-color: $border;
  background-color: $background;
  hr {
    border-top-color: darken($border, 5%);
  }
  .alert-link {
    color: darken($color, 10%);
  }
}